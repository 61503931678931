/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback, useEffect } from 'react';
import { pickBy, identity } from 'lodash-es';
import useScript from 'react-script-hook';
import { SiteData } from '../utils/consts';
import { useUserChatBoxDataQuery, AuthIdentityProvider } from '../apollo';
import { useIsContactsPage } from './useIsContactsPage';

export const useChatBox = () => {
  const contactsPage = useIsContactsPage();
  const { data: { getUser: user } = {} } = useUserChatBoxDataQuery();

  useScript({
    src: SiteData.CHATBOX_LINK,
    async: true,
  });

  const onChatboxReady = useCallback(() => {
    if (!window.tidioChatApi) return;
    window.tidioChatApi[contactsPage ? 'show' : 'hide']();
  }, [contactsPage]);

  useEffect(() => {
    document.addEventListener('tidioChat-ready', onChatboxReady);

    return () => {
      document.removeEventListener('tidioChat-ready', onChatboxReady);
    };
  }, [onChatboxReady]);

  useEffect(() => {
    if (!window.tidioChatApi) return;
    window.tidioChatApi[contactsPage ? 'show' : 'hide']();
  }, [contactsPage]);

  useEffect(() => {
    if (!(window.tidioChatApi && user)) return;
    const email =
      user.authIdentities.find(
        ({ provider }) => provider === AuthIdentityProvider.Email
      )?.uid ?? null;
    const phone = user.phone && user.phone.startsWith('+') ? user.phone : null;

    window.tidioChatApi.setVisitorData(
      // @ts-ignore
      pickBy(
        {
          distinct_id: user.id,
          name: `${user.firstName} ${user.lastName}`,
          email,
          phone,
        },
        identity
      )
    );
  }, [user]);
};
