/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  HTMLAttributes,
} from 'react';
import styled from 'styled-components';
import { isEmpty, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'react-use';
import { setLocalStorage } from 'utils/setLocalStorage';
import {
  Table as DefTable,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { tablesData } from '../../utils/consts';
import { semiBoldText } from '../../utils/fonts';
import { sendSentryError } from '../../utils/sentry';
import { useAdminSavedPageAndRow, useAdminSignInAs } from 'hooks';
import {
  CustomUserStatus,
  useAdminAllUsersQuery,
  AdminAllUsersQueryVariables,
  Maybe,
} from '../../apollo';
import {
  AdminUsersTableFilter,
  AdminUsersTableTopBar,
  AdminTableHeadRow,
  AdminTableUserRow,
  adminUsersTableInitialFilters,
  adminUsersTableInitialSortings,
  AdminUsersFiltersState,
  AdminUsersSortings,
  AdminTableUser,
} from '../table-elements';

export type AdminUsersTableProps = HTMLAttributes<HTMLTableElement>;

const AdminUsersTable = (props: AdminUsersTableProps) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<AdminUsersFiltersState>(
    adminUsersTableInitialFilters
  );

  const [sortings, setSortings] = useState<AdminUsersSortings>(
    adminUsersTableInitialSortings
  );
  const savedPage = useAdminSavedPageAndRow('admin_users_pagination');
  const savedRow = useAdminSavedPageAndRow('admin_users_row');
  const selectedAllRef = useRef(false);
  const [adminSendEmailsFormDisabled, setAdminSendEmailsFormDisabled] =
    useState(true);

  const [customUserStatus, setCustomUserStatus] =
    useState<Maybe<CustomUserStatus>>(null);

  const [page, setPage] = useState(savedPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(
    savedRow ?? tablesData.adminUsers.initialRows
  );

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const queryFilters = useMemo<AdminAllUsersQueryVariables>(() => {
    const countries = filters.country ? [filters.country] : undefined;
    const atiFilter = parseInt(filters.amountToInvest) || undefined;
    const customStatusFilter = customUserStatus ?? undefined;
    const username = filters.name || undefined;
    const email = filters.email || undefined;
    const userIDs = filters.id ? [toNumber(filters.id)] : undefined;
    const exchangeId = filters.exchangeId
      ? toNumber(filters.exchangeId)
      : undefined;

    return {
      countries,
      atiFilter,
      customStatusFilter,
      username,
      email,
      userIDs,
      exchangeId,
    };
  }, [customUserStatus, filters]);

  const usersQueryVariables = useMemo<AdminAllUsersQueryVariables>(
    () => ({
      ...(!isEmpty(sortings)
        ? {
            orderBy: sortings,
          }
        : {}),
      ...queryFilters,
      limit: rowsPerPage,
      offset: rowsPerPage * page,
    }),
    [sortings, queryFilters, rowsPerPage, page]
  );

  const { error, data: { adminGetUsers: response, adminGetInvoices } = {} } =
    useAdminAllUsersQuery({
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables: usersQueryVariables,
    });

  const topBarData = useMemo(() => {
    if (!response) {
      return {
        usersAmount: 0,
        totalAum: 0,
        totalCosts: 0,
        totalInvoices: 0,
        customUserStatus: null,
        setCustomUserStatus,
      };
    }

    const { total: usersAmount, totalAum, totalPaid: totalCosts } = response;

    return {
      usersAmount,
      totalAum,
      totalCosts,
      totalInvoices: adminGetInvoices?.activeTotal ?? 0,
      customUserStatus,
      setCustomUserStatus,
    };
  }, [response, adminGetInvoices, customUserStatus]);

  const users = useMemo(() => response?.users ?? [], [response]);
  const prevUsers = usePrevious(users);

  useEffect(() => {
    if (!isEmpty(selectedUsers)) {
      setAdminSendEmailsFormDisabled(false);
    } else {
      setAdminSendEmailsFormDisabled(isEmpty(selectedUsers));
    }
  }, [selectedUsers]);

  // Reset selects if users changed
  useEffect(() => {
    if (!prevUsers) return;
    const prevUsersIds = prevUsers.map(({ id }) => id);
    const newUsersIds = users.map(({ id }) => id);
    const same =
      prevUsersIds.length === newUsersIds.length &&
      newUsersIds.every((newUserId) => prevUsersIds.includes(newUserId));
    if (!same) {
      setSelectedUsers([]);
    }
  }, [users, prevUsers]);

  const [signInAs] = useAdminSignInAs();

  if (error) {
    sendSentryError(new Error(`Couldn't get users: ${error}`));
    return <span>{t('ADMIN_ALL_USERS_PAGE__loadError')}</span>;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = users.map(({ id }) => id);
      setSelectedUsers(newSelecteds);
      selectedAllRef.current = true;
    } else {
      setSelectedUsers([]);
    }
  };

  return (
    <>
      <AdminUsersTableFilter
        selectedUserIDs={selectedAllRef.current ? [] : selectedUsers}
        customStatus={customUserStatus}
        queryFilters={queryFilters}
        onCustomStatusChange={setCustomUserStatus}
        onFiltersChange={setFilters}
        resetOnSuccess={() => {
          setSelectedUsers([]);
          selectedAllRef.current = false;
        }}
        adminSendEmailsFormDisabled={adminSendEmailsFormDisabled}
      />
      <AdminUsersTableTopBar {...topBarData} />
      <TableWrapper>
        <Table {...props}>
          <TableHead>
            <AdminTableHeadRow
              columns={columns}
              selectAllCheckboxProps={{
                indeterminate:
                  selectedUsers.length > 0 &&
                  selectedUsers.length < users.length,
                checked:
                  users.length > 0 && selectedUsers.length === users.length,
                onChange: handleSelectAllClick,
              }}
              sortableColumns={sortableColumns}
              onSortingsChange={setSortings}
            />
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <AdminTableUserRow
                key={index}
                columns={columns}
                user={user}
                onUserClick={() => {
                  setLocalStorage('admin_users_pagination', page);
                  setLocalStorage('admin_users_row', rowsPerPage);
                  signInAs({
                    variables: {
                      userId: +user.id,
                    },
                  });
                }}
                selected={selectedUsers.includes(user.id)}
                onSelect={() => {
                  selectedAllRef.current = false;
                  setSelectedUsers((users) => {
                    selectedAllRef.current = false;
                    const exists = users.includes(user.id);
                    return exists
                      ? users.filter((userID) => userID !== user.id)
                      : [...users, user.id];
                  });
                }}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={tablesData.adminUsers.rowsPerPageOptions}
                colSpan={15}
                count={response?.total ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                }}
                labelRowsPerPage={`${t('INVOICES_PAGE__tableRowsPerPage')}:`}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableWrapper>
    </>
  );
};

const Table = styled(DefTable)`
  .MuiTableCell-root {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 10px;
    font-size: 1rem;

    &:nth-child(2),
    &:nth-child(3) {
      position: sticky;
      left: 0;
      background-color: #fff;
    }

    &:nth-child(3) {
      left: 42px;
    }

    &:last-child {
      padding-right: 0;
      min-width: 80px;
    }
  }

  .MuiTableCell-head {
    ${semiBoldText};
    line-height: 1.2;
  }

  .MuiTableCell-footer {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .MuiButton-contained {
    padding: 8px 10px;
  }
`;

const TableWrapper = styled.div`
  overflow-x: scroll;
`;

export type AdminUserTableColumn =
  | keyof AdminTableUser
  | 'exchangesStatuses'
  | 'tfa'
  | 'deactivateSubscription'
  | 'rebalance'
  | 'keysRemovement';

const columns: AdminUserTableColumn[] = [
  'id',
  'name',
  'amountToInvest',
  'customStatus',
  'exchangesStatuses',
  'amount',
  'email',
  'phone',
  'dateOfSignup',
  'paid',
  'tfa',
  'rebalance',
  'keysRemovement',
  'deactivateSubscription',
];

const sortableColumns: AdminUserTableColumn[] = [
  'amount',
  'dateOfSignup',
  'paid',
];

export { AdminUsersTable };
