import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
import {
  FieldPolicy,
  FieldReadFunction,
  TypePolicies,
  TypePolicy,
} from '@apollo/client/cache';

export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Some JSON with unpredictable structure */
  JSON: any;
  /** The `Time` scalar type represents a Time. The Time is serialized as an RFC 3339 quoted string */
  Time: string;
};

export enum AddKeyStatus {
  KeyNotSetup = 'KEY_NOT_SETUP',
  KeyOk = 'KEY_OK',
}

export type AddKeysResponse = {
  __typename?: 'AddKeysResponse';
  balance: Scalars['Float'];
  keyStatus: AddKeyStatus;
  user: UserResponse;
};

export type AdminPerformanceResponse = {
  __typename?: 'AdminPerformanceResponse';
  rate24: Scalars['Float'];
  start: Scalars['Float'];
  year: Scalars['Float'];
  ytd: Scalars['Float'];
};

export type AdminUserResponse = {
  __typename?: 'AdminUserResponse';
  /**    validErrors: [UserValidErrors!]! */
  address?: Maybe<Scalars['String']>;
  amountToInvest?: Maybe<Scalars['Int']>;
  authIdentities: Array<AuthIdentityResponse>;
  authProvider: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  controlledByUser?: Maybe<UserResponse>;
  country?: Maybe<Scalars['String']>;
  currenciesLocked: Array<Scalars['Int']>;
  customStatus: CustomUserStatus;
  dateOfBirth?: Maybe<Scalars['Time']>;
  dateOfSignup: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  exchanges: Array<UserExchangeResponse>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hadCreditCard: Scalars['Boolean'];
  hasActiveSubscription: Scalars['Boolean'];
  hasUnpaidInvoices: Scalars['Boolean'];
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  mollieStatus: MollieStatus;
  moneyPaid: Scalars['Float'];
  nextRebalancingOn?: Maybe<Scalars['Time']>;
  paymentSources: Array<PaymentSource>;
  pendingDiscount: Scalars['Float'];
  personalReferalCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profileValid: Scalars['Boolean'];
  referralCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status: UserStatus;
  stripeStatus: StripeStatus;
  totpEnabled: Scalars['Boolean'];
  trialEndDate?: Maybe<Scalars['Time']>;
  vatNumber?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AdminUserResponseAuthIdentitiesArgs = {
  providers?: InputMaybe<Array<AuthIdentityProvider>>;
};

export type AdminUsersResponse = {
  __typename?: 'AdminUsersResponse';
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
  totalAum: Scalars['Float'];
  totalPaid: Scalars['Float'];
  users: Array<AdminUserResponse>;
};

export type AggregatedDataRow = {
  __typename?: 'AggregatedDataRow';
  date: Scalars['Time'];
  value: Scalars['Float'];
};

export enum AuthIdentityProvider {
  Email = 'EMAIL',
  Google = 'GOOGLE',
}

export type AuthIdentityResponse = {
  __typename?: 'AuthIdentityResponse';
  confirmedAt?: Maybe<Scalars['Time']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  provider: AuthIdentityProvider;
  uid: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type AvailableAuthentication = {
  __typename?: 'AvailableAuthentication';
  link: Scalars['String'];
  title: Scalars['String'];
};

export type ChangePasswordResponse = {
  __typename?: 'ChangePasswordResponse';
  success: Scalars['Boolean'];
};

export type Currency = {
  __typename?: 'Currency';
  btc24RateValue: Scalars['Float'];
  btcValue: Scalars['Float'];
  eur24RateValue: Scalars['Float'];
  eurValue: Scalars['Float'];
  icon: Scalars['String'];
  id: Scalars['ID'];
  isFiat: Scalars['Boolean'];
  messari: Scalars['String'];
  piechartColor: Scalars['String'];
  symbol: Scalars['String'];
  title: Scalars['String'];
  usd24RateValue: Scalars['Float'];
  usdValue: Scalars['Float'];
};

export enum CustomUserStatus {
  /** Activate */
  Activate = 'ACTIVATE',
  /** Active */
  Active = 'ACTIVE',
  /** Funds below limit */
  BelowLimit = 'BELOW_LIMIT',
  /** Cancelled */
  Cancelled = 'CANCELLED',
  /** Free */
  Free = 'FREE',
  /** Email not verified */
  NotVerified = 'NOT_VERIFIED',
  /** Not enough funds */
  NoFunds = 'NO_FUNDS',
  /** No API Key */
  NoKey = 'NO_KEY',
  /** No payment details */
  NoPaymentDetails = 'NO_PAYMENT_DETAILS',
  /** Open Invoice */
  OpenInvoice = 'OPEN_INVOICE',
  /** Removed */
  Removed = 'REMOVED',
  /** Signed Up */
  SignedUp = 'SIGNED_UP',
  /** Trial */
  Trial = 'TRIAL',
}

export type DeleteUserArgs = {
  code: Scalars['Int'];
};

export type DirectInvoicePaymentResponse = {
  __typename?: 'DirectInvoicePaymentResponse';
  checkoutURL: Scalars['String'];
};

export enum ExchangeCode {
  Bitvavo = 'BITVAVO',
  Kraken = 'KRAKEN',
}

export type ExchangeResponse = {
  __typename?: 'ExchangeResponse';
  exchangeCode: ExchangeCode;
  exchangeName: Scalars['String'];
  id: Scalars['Int'];
};

export type GetCurrencyGraphDataResponse = {
  __typename?: 'GetCurrencyGraphDataResponse';
  currency: Currency;
  data: Array<AggregatedDataRow>;
};

export type GoogleSignInResponse = {
  __typename?: 'GoogleSignInResponse';
  authIdentityID: Scalars['ID'];
  profileValid: Scalars['Boolean'];
  token: Scalars['String'];
  totpEnabled: Scalars['Boolean'];
};

export type GoogleSignUpResponse = {
  __typename?: 'GoogleSignUpResponse';
  authIdentityID: Scalars['ID'];
  profileValid: Scalars['Boolean'];
  token: Scalars['String'];
  totpEnabled: Scalars['Boolean'];
};

export enum InvoiceActiveFilter {
  Active = 'ACTIVE',
  All = 'ALL',
  NotActive = 'NOT_ACTIVE',
}

export type InvoiceListResponse = {
  __typename?: 'InvoiceListResponse';
  activeTotal: Scalars['Float'];
  invoices: Array<InvoiceResponse>;
};

export enum InvoicePaymentStatus {
  InvoiceStatusCanceled = 'INVOICE_STATUS_CANCELED',
  InvoiceStatusCharged = 'INVOICE_STATUS_CHARGED',
  InvoiceStatusComplete = 'INVOICE_STATUS_COMPLETE',
  InvoiceStatusFailedOnce = 'INVOICE_STATUS_FAILED_ONCE',
  InvoiceStatusFailedTwice = 'INVOICE_STATUS_FAILED_TWICE',
  InvoiceStatusSubmitted = 'INVOICE_STATUS_SUBMITTED',
  InvoiceStatusUnpaid = 'INVOICE_STATUS_UNPAID',
}

export type InvoiceResponse = {
  __typename?: 'InvoiceResponse';
  amount: Scalars['Int'];
  cost: Scalars['Float'];
  costExVat: Scalars['Float'];
  dueDate: Scalars['Time'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  price: Scalars['Float'];
  status: InvoicePaymentStatus;
  strategy: Scalars['String'];
  total: Scalars['Float'];
  url: Scalars['String'];
  user: UserResponse;
  vat: Scalars['Float'];
  vatAmount: Scalars['Float'];
};

export enum InvoicesFilterByStatus {
  Paid = 'PAID',
  Pay = 'PAY',
  Submitted = 'SUBMITTED',
}

export type InvoicesOrderBy = {
  Direction?: InputMaybe<OrderByDirection>;
  Field: InvoicesOrderByField;
};

export enum InvoicesOrderByField {
  Amount = 'AMOUNT',
  Cost = 'COST',
  CreatedAt = 'CREATED_AT',
  DueDate = 'DUE_DATE',
  UpdatedAt = 'UPDATED_AT',
  UsersFirstName = 'USERS__FIRST_NAME',
  UsersLastName = 'USERS__LAST_NAME',
}

export type KeyInput = {
  key: Scalars['String'];
  secret: Scalars['String'];
};

export enum KeysStatus {
  InvalidKeys = 'INVALID_KEYS',
  NoEnoughFunds = 'NO_ENOUGH_FUNDS',
  NoKeys = 'NO_KEYS',
  Ok = 'OK',
}

export type MolliePaymentResponse = {
  __typename?: 'MolliePaymentResponse';
  createdAt: Scalars['Time'];
  details: Scalars['String'];
  method: Scalars['String'];
  status: Scalars['String'];
};

export enum MollieStatus {
  NoCc = 'NO_CC',
  Ok = 'OK',
}

export type MollieTransitPaymentLinkResponse = {
  __typename?: 'MollieTransitPaymentLinkResponse';
  checkoutURL: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  AdminCancelUserSubscription: UserResponse;
  /** Adds given Kraken key to current user. */
  addKeys: UserExchangeResponse;
  adminManageUsersTotp: Array<UserResponse>;
  adminRebalanceUser: UserResponse;
  adminRemoveKeys: UserResponse;
  adminSendEmail: Scalars['Boolean'];
  adminSignInAs: SignInResponse;
  adminSignOutAs: SignInResponse;
  /** withdraw(euroAmount: Float!): UserResponse! */
  cancelSubscription?: Maybe<UserExchangeResponse>;
  /** Changes password for user. */
  changeCredentials: UserResponse;
  /** Direct Invoice payment */
  directMolliePayment: DirectInvoicePaymentResponse;
  /** Updates user profile */
  editProfile: UserResponse;
  /**
   * Verifies given user credentials.
   * Returns TOTP token if 2FA is enabled for user, auth tokenGoogleSignInResponse otherwise.
   * Returns if 2FA is enabled for user.
   * Returns error if user with this email is not found.
   */
  googleSignIn: GoogleSignInResponse;
  googleSignUp: GoogleSignUpResponse;
  /** New Mollie client */
  newMollieClient: NewMollieClientResponse;
  /** Trades all fiat for crypto */
  portfolioDeposit: UserResponse;
  /** Trades crypto for given amount of money and rebalances portfolio */
  portfolioLiquidation: UserResponse;
  removeKeys: UserResponse;
  /** Removes user and all belonging data. Requires TOTP code. */
  removeUser: RemoveUserResponse;
  resendVerificationEmail: Scalars['Boolean'];
  resetPassword: ResetPasswordResponse;
  sendResetPasswordEmail: SendResetPasswordEmailResponse;
  signIn: SignInResponse;
  signOut: SignOutResponse;
  /**
   * Creates new account with given details.
   * Returns auth token and identity ID.
   * Returns error if email is already used.
   */
  signUp: SignUpResponse;
  startSubscription: StartTrialStatus;
  /** Takes Stripe Payment Method ID ("pm_..." string) as parameter. */
  stripeSetPaymentMethod: StripeSetPaymentMethodResponse;
  stripeSetupIntent: Scalars['String'];
  /**
   * Disables user TOTP.
   * Returns an error if user has no TOTP enabled.
   */
  totpDisable: TotpDisableResponse;
  /**
   * Generates TOTP QR-code
   * Returns an error if user has TOTP enabled, otherwise returns QR-code as base64 string.
   */
  totpGenerate: Scalars['String'];
  /**
   * Validates user TOTP setup by checking it against user one-time code (when user signups or reinstalls 2FA).
   * Returns list of backup codes.
   */
  totpSetup?: Maybe<TotpValidateResponse>;
  /**
   * Verifies user one-time code when user logs in.
   * Returns auth token.
   */
  totpVerify?: Maybe<TotpVerifyResponse>;
  /** Set locked/unlocked status for currency for user */
  userLockCurrency: Scalars['Boolean'];
};

export type MutationAdminCancelUserSubscriptionArgs = {
  exchangeId: Scalars['Int'];
  userID: Scalars['Int'];
};

export type MutationAddKeysArgs = {
  exchangeId: Scalars['Int'];
  keys: Array<KeyInput>;
};

export type MutationAdminManageUsersTotpArgs = {
  enable: Scalars['Boolean'];
  usersIDs: Array<Scalars['Int']>;
};

export type MutationAdminRebalanceUserArgs = {
  exchangeId: Scalars['Int'];
  userID: Scalars['Int'];
};

export type MutationAdminRemoveKeysArgs = {
  exchangeId: Scalars['Int'];
  userID: Scalars['Int'];
};

export type MutationAdminSendEmailArgs = {
  atiFilterLess?: InputMaybe<Scalars['Int']>;
  atiFilterMore?: InputMaybe<Scalars['Int']>;
  aumFilterLess?: InputMaybe<Scalars['Float']>;
  aumFilterMore?: InputMaybe<Scalars['Float']>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  customStatusFilter?: InputMaybe<Array<CustomUserStatus>>;
  dateOfSignUpLess?: InputMaybe<Scalars['Time']>;
  dateOfSignUpMore?: InputMaybe<Scalars['Time']>;
  email?: InputMaybe<Scalars['String']>;
  exchangeId?: InputMaybe<Scalars['Int']>;
  statusFilter?: InputMaybe<Array<UserStatus>>;
  templateId: Scalars['String'];
  username?: InputMaybe<Scalars['String']>;
  usersIDs?: InputMaybe<Array<Scalars['Int']>>;
};

export type MutationAdminSignInAsArgs = {
  userID: Scalars['Int'];
};

export type MutationCancelSubscriptionArgs = {
  exchangeId: Scalars['Int'];
};

export type MutationChangeCredentialsArgs = {
  currentPassword: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type MutationDirectMolliePaymentArgs = {
  invoiceId: Scalars['Int'];
  paymentMode?: InputMaybe<Scalars['String']>;
};

export type MutationEditProfileArgs = {
  address?: InputMaybe<Scalars['String']>;
  amountToInvest?: InputMaybe<Scalars['Int']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  dateOfBirth?: InputMaybe<Scalars['Time']>;
  exchangeId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notUsPerson?: InputMaybe<Scalars['Boolean']>;
  olderThan18AndTermsAccepted?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  sendMeNews?: InputMaybe<Scalars['Boolean']>;
  sharedReferralCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type MutationGoogleSignInArgs = {
  token: Scalars['String'];
};

export type MutationGoogleSignUpArgs = {
  token: Scalars['String'];
};

export type MutationNewMollieClientArgs = {
  locale?: InputMaybe<Scalars['String']>;
};

export type MutationPortfolioDepositArgs = {
  exchangeId: Scalars['Int'];
};

export type MutationPortfolioLiquidationArgs = {
  currencyId: Scalars['Int'];
  exchangeId: Scalars['Int'];
  percents: Scalars['Float'];
};

export type MutationRemoveKeysArgs = {
  exchangeId: Scalars['Int'];
};

export type MutationRemoveUserArgs = {
  password?: InputMaybe<Scalars['String']>;
};

export type MutationResendVerificationEmailArgs = {
  authIdentityId?: InputMaybe<Scalars['Int']>;
};

export type MutationResetPasswordArgs = {
  password: Scalars['String'];
};

export type MutationSendResetPasswordEmailArgs = {
  captchaToken?: InputMaybe<Scalars['String']>;
  captchaV2Token?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

export type MutationSignInArgs = {
  captchaToken?: InputMaybe<Scalars['String']>;
  captchaV2Token?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  remember: Scalars['Boolean'];
};

export type MutationSignUpArgs = {
  address?: InputMaybe<Scalars['String']>;
  amountToInvest?: InputMaybe<Scalars['Int']>;
  captchaToken?: InputMaybe<Scalars['String']>;
  captchaV2Token?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  houseNumber?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  notUsPerson?: InputMaybe<Scalars['Boolean']>;
  olderThan18AndTermsAccepted?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  passwordRepeat: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  referralCode?: InputMaybe<Scalars['String']>;
  sendMeNews?: InputMaybe<Scalars['Boolean']>;
  state?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type MutationStartSubscriptionArgs = {
  exchangeId: Scalars['Int'];
};

export type MutationStripeSetPaymentMethodArgs = {
  paymentMethodID: Scalars['String'];
};

export type MutationTotpDisableArgs = {
  code: Scalars['String'];
};

export type MutationTotpSetupArgs = {
  code: Scalars['String'];
};

export type MutationTotpVerifyArgs = {
  code: Scalars['String'];
};

export type MutationUserLockCurrencyArgs = {
  currencyId: Scalars['Int'];
  status: Scalars['Boolean'];
};

export type NewMollieClientResponse = {
  __typename?: 'NewMollieClientResponse';
  checkoutURL: Scalars['String'];
};

export type OrderBy = {
  Direction?: InputMaybe<OrderByDirection>;
  Field: Scalars['String'];
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type PaymentSource = {
  __typename?: 'PaymentSource';
  brand: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  lastFour: Scalars['String'];
};

export enum PortfolioCurrency {
  Eur = 'EUR',
  Usd = 'USD',
}

export type PortfolioRowResponse = {
  __typename?: 'PortfolioRowResponse';
  amountCoins: Scalars['Float'];
  /**
   * Alias to change24Percentage
   * @deprecated change24Percentage should be used
   */
  change24: Scalars['Float'];
  /** Change24 natural value */
  change24Amount: Scalars['Float'];
  /** Change24 percentage of current cost */
  change24Percentage: Scalars['Float'];
  cost: Scalars['Float'];
  currency: Currency;
  effectiveDate: Scalars['Time'];
  /**    nativeValue  : Float */
  percentage: Scalars['Float'];
  usdChange24Amount: Scalars['Float'];
  usdChange24Percentage: Scalars['Float'];
  usdCost: Scalars['Float'];
};

export type PortfolioTotalsResponse = {
  __typename?: 'PortfolioTotalsResponse';
  eurCostChange24Percentage: Scalars['Float'];
  eurCostChange24Total: Scalars['Float'];
  eurCostTotal: Scalars['Float'];
  nextRebalancingOn: Scalars['Time'];
  usdCostChange24Percentage: Scalars['Float'];
  usdCostChange24total: Scalars['Float'];
  usdCostTotal: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  adminGetInvoices: InvoiceListResponse;
  adminGetPerformance: AdminPerformanceResponse;
  adminGetUser?: Maybe<UserResponse>;
  adminGetUsers: AdminUsersResponse;
  availableAuthentications: Array<AvailableAuthentication>;
  getCurrencyGraphData: GetCurrencyGraphDataResponse;
  getDemoCurrencyGraphData: GetCurrencyGraphDataResponse;
  getDemoPortfolio: Array<PortfolioRowResponse>;
  getDemoPortfolioGraphData: Array<AggregatedDataRow>;
  getDemoUser: UserResponse;
  getExchanges: Array<ExchangeResponse>;
  getInvoice: InvoiceResponse;
  getInvoices: Array<InvoiceResponse>;
  getMenu: Scalars['JSON'];
  getMolliePaymentInfo: MolliePaymentResponse;
  getMollieTransitPaymentLink: MollieTransitPaymentLinkResponse;
  /** Returns portfolio data for current user */
  getPortfolio: Array<PortfolioRowResponse>;
  getPortfolioGraphData: Array<AggregatedDataRow>;
  getPortfolioTotals: PortfolioTotalsResponse;
  /** Returns current user profile */
  getUser: UserResponse;
  getUserExchanges: Array<UserExchangeResponse>;
  portfolioCurrency?: Maybe<PortfolioCurrency>;
  selectedExchange?: Maybe<ExchangeCode>;
};

export type QueryAdminGetInvoicesArgs = {
  active?: InputMaybe<InvoiceActiveFilter>;
  assetsUnderManagement?: InputMaybe<Scalars['Float']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoicesOrderBy>>;
  statusFilter?: InputMaybe<InvoicesFilterByStatus>;
  usersIDs?: InputMaybe<Array<Scalars['Int']>>;
};

export type QueryAdminGetUserArgs = {
  userId: Scalars['Int'];
};

export type QueryAdminGetUsersArgs = {
  atiFilterLess?: InputMaybe<Scalars['Int']>;
  atiFilterMore?: InputMaybe<Scalars['Int']>;
  aumFilterLess?: InputMaybe<Scalars['Float']>;
  aumFilterMore?: InputMaybe<Scalars['Float']>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  customStatusFilter?: InputMaybe<Array<CustomUserStatus>>;
  dateOfSignUpLess?: InputMaybe<Scalars['Time']>;
  dateOfSignUpMore?: InputMaybe<Scalars['Time']>;
  email?: InputMaybe<Scalars['String']>;
  exchangeId?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
  statusFilter?: InputMaybe<Array<UserStatus>>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
  username?: InputMaybe<Scalars['String']>;
};

export type QueryAvailableAuthenticationsArgs = {
  next: Scalars['String'];
};

export type QueryGetCurrencyGraphDataArgs = {
  currencyID: Scalars['Int'];
  exchangeId: Scalars['Int'];
  from?: InputMaybe<Scalars['Time']>;
  till?: InputMaybe<Scalars['Time']>;
};

export type QueryGetDemoCurrencyGraphDataArgs = {
  currencyID: Scalars['Int'];
  exchangeId: Scalars['Int'];
  from?: InputMaybe<Scalars['Time']>;
  till?: InputMaybe<Scalars['Time']>;
};

export type QueryGetDemoPortfolioArgs = {
  exchangeId: Scalars['Int'];
  ignoreAuth?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetDemoPortfolioGraphDataArgs = {
  exchangeId: Scalars['Int'];
  from?: InputMaybe<Scalars['Time']>;
  till?: InputMaybe<Scalars['Time']>;
};

export type QueryGetInvoiceArgs = {
  invoiceID: Scalars['Int'];
};

export type QueryGetMollieTransitPaymentLinkArgs = {
  locale?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type QueryGetPortfolioArgs = {
  exchangeId: Scalars['Int'];
};

export type QueryGetPortfolioGraphDataArgs = {
  exchangeId: Scalars['Int'];
  from?: InputMaybe<Scalars['Time']>;
  till?: InputMaybe<Scalars['Time']>;
};

export type QueryGetPortfolioTotalsArgs = {
  exchangeId: Scalars['Int'];
};

export type RemoveUserResponse = {
  __typename?: 'RemoveUserResponse';
  invoiceToPayId: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  success: Scalars['Boolean'];
};

export type SendResetPasswordEmailResponse = {
  __typename?: 'SendResetPasswordEmailResponse';
  success: Scalars['Boolean'];
};

export type SignInResponse = {
  __typename?: 'SignInResponse';
  authIdentityID: Scalars['ID'];
  token: Scalars['String'];
  totpEnabled: Scalars['Boolean'];
};

export type SignOutResponse = {
  __typename?: 'SignOutResponse';
  success: Scalars['Boolean'];
};

export type SignUpResponse = {
  __typename?: 'SignUpResponse';
  authIdentityId: Scalars['ID'];
  token: Scalars['String'];
};

export enum StartTrialStatus {
  ActiveUser = 'ACTIVE_USER',
  ErrInvalidKeys = 'ERR_INVALID_KEYS',
  ErrNotEnoughFunds = 'ERR_NOT_ENOUGH_FUNDS',
  ErrNoKeys = 'ERR_NO_KEYS',
  ErrNoPaymentData = 'ERR_NO_PAYMENT_DATA',
  ErrStatus = 'ERR_STATUS',
  ErrTrialIsOver = 'ERR_TRIAL_IS_OVER',
  Ok = 'OK',
}

export type StripeSetPaymentMethodResponse = {
  __typename?: 'StripeSetPaymentMethodResponse';
  success: Scalars['Boolean'];
};

export enum StripeStatus {
  NoCc = 'NO_CC',
  Ok = 'OK',
}

export type TotpDisableResponse = {
  __typename?: 'TotpDisableResponse';
  success: Scalars['Boolean'];
};

export type TotpValidateResponse = {
  __typename?: 'TotpValidateResponse';
  backupCodes: Array<Scalars['String']>;
  token: Scalars['String'];
};

export type TotpVerifyResponse = {
  __typename?: 'TotpVerifyResponse';
  token: Scalars['String'];
};

export type UserExchangeResponse = {
  __typename?: 'UserExchangeResponse';
  aumBelowMinLastShowTime?: Maybe<Scalars['String']>;
  aumBelowMinimum: Scalars['Boolean'];
  exchangeCode: ExchangeCode;
  exchangeID: Scalars['ID'];
  fiatCurrencies: Array<Currency>;
  keysCreatedAt?: Maybe<Scalars['Time']>;
  keysStatus: KeysStatus;
  moneyUnderManagement: Scalars['Float'];
  nextRebalanceDate?: Maybe<Scalars['Time']>;
  onlyFiatInPortfolio: Scalars['Boolean'];
  subscriptionInitiatedDate?: Maybe<Scalars['Time']>;
  subscriptionStartDate?: Maybe<Scalars['Time']>;
  targetForSaleCurrencies: Array<Currency>;
  wizardStatus: WizardStatusType;
};

export type UserResponse = {
  __typename?: 'UserResponse';
  /**    validErrors: [UserValidErrors!]! */
  address?: Maybe<Scalars['String']>;
  amountToInvest?: Maybe<Scalars['Int']>;
  authIdentities: Array<AuthIdentityResponse>;
  authProvider: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  controlledByUser?: Maybe<UserResponse>;
  country?: Maybe<Scalars['String']>;
  currenciesLocked: Array<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['Time']>;
  dateOfSignup: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  exchanges: Array<UserExchangeResponse>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  hadCreditCard: Scalars['Boolean'];
  hasActiveSubscription: Scalars['Boolean'];
  hasUnpaidInvoices: Scalars['Boolean'];
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  mollieStatus: MollieStatus;
  moneyPaid: Scalars['Float'];
  nextRebalancingOn?: Maybe<Scalars['Time']>;
  paymentSources: Array<PaymentSource>;
  pendingDiscount: Scalars['Float'];
  personalReferalCode: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  profileValid: Scalars['Boolean'];
  referralCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  status: UserStatus;
  stripeStatus: StripeStatus;
  totpEnabled: Scalars['Boolean'];
  trialEndDate?: Maybe<Scalars['Time']>;
  vatNumber?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type UserResponseAuthIdentitiesArgs = {
  providers?: InputMaybe<Array<AuthIdentityProvider>>;
};

export enum UserStatus {
  /** Current account is fully functioning */
  Active = 'ACTIVE',
  /** Current account has been blocked by MR administration for suspicious activity/spam/etc. */
  Deactivated = 'DEACTIVATED',
  /** Current account has no verified emails */
  EmailNotVerified = 'EMAIL_NOT_VERIFIED',
  /** Stripe error: app has no credit card for current user */
  NoCc = 'NO_CC',
}

export enum UserStatusInput {
  Active = 'ACTIVE',
  SubscriptionCanceled = 'SUBSCRIPTION_CANCELED',
}

export type UsersOrderBy = {
  Direction?: InputMaybe<OrderByDirection>;
  Field: UsersOrderByField;
};

export enum UsersOrderByField {
  AmountToInvest = 'AMOUNT_TO_INVEST',
  AssetsUnderManagement = 'ASSETS_UNDER_MANAGEMENT',
  Country = 'COUNTRY',
  DateOfSignup = 'DATE_OF_SIGNUP',
  EmailAddress = 'EMAIL_ADDRESS',
  FirstName = 'FIRST_NAME',
  Kosten = 'KOSTEN',
  LastName = 'LAST_NAME',
}

export enum WizardStatusType {
  Disabled = 'DISABLED',
  NoKeys = 'NO_KEYS',
  NoMoney = 'NO_MONEY',
  StartSubscription = 'START_SUBSCRIPTION',
  StartTrial = 'START_TRIAL',
}

export type AdminUsersFiltersDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminUsersFiltersDataQuery = {
  __typename?: 'Query';
  getExchanges: Array<{
    __typename?: 'ExchangeResponse';
    id: number;
    exchangeName: string;
  }>;
  adminGetUsers: {
    __typename?: 'AdminUsersResponse';
    users: Array<{
      __typename?: 'AdminUserResponse';
      id: string;
      country?: string | null | undefined;
      amountToInvest?: number | null | undefined;
    }>;
  };
};

export type AdminAllUsersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<UsersOrderBy> | UsersOrderBy>;
  atiFilter?: InputMaybe<Scalars['Int']>;
  countries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  customStatusFilter?: InputMaybe<Array<CustomUserStatus> | CustomUserStatus>;
  exchangeId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  userIDs?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type AdminAllUsersQuery = {
  __typename?: 'Query';
  adminGetUsers: {
    __typename?: 'AdminUsersResponse';
    total: number;
    totalAum: number;
    totalPaid: number;
    users: Array<{
      __typename?: 'AdminUserResponse';
      id: string;
      dateOfSignup: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
      phone?: string | null | undefined;
      customStatus: CustomUserStatus;
      amountToInvest?: number | null | undefined;
      moneyPaid: number;
      totpEnabled: boolean;
      hasUnpaidInvoices: boolean;
      hasActiveSubscription: boolean;
      mollieStatus: MollieStatus;
      trialEndDate?: string | null | undefined;
      authIdentities: Array<{
        __typename?: 'AuthIdentityResponse';
        provider: AuthIdentityProvider;
        uid: string;
      }>;
      exchanges: Array<{
        __typename?: 'UserExchangeResponse';
        exchangeID: string;
        exchangeCode: ExchangeCode;
        moneyUnderManagement: number;
        keysStatus: KeysStatus;
      }>;
    }>;
  };
  adminGetInvoices: { __typename?: 'InvoiceListResponse'; activeTotal: number };
};

export type AdminUserStatusAndActiveSubscriptionQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type AdminUserStatusAndActiveSubscriptionQuery = {
  __typename?: 'Query';
  adminGetUser?:
    | {
        __typename?: 'UserResponse';
        id: string;
        status: UserStatus;
        hasActiveSubscription: boolean;
      }
    | null
    | undefined;
};

export type AdminUsersFirstAndLastNamesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminUsersFirstAndLastNamesQuery = {
  __typename?: 'Query';
  adminGetUsers: {
    __typename?: 'AdminUsersResponse';
    users: Array<{
      __typename?: 'AdminUserResponse';
      id: string;
      firstName?: string | null | undefined;
      lastName?: string | null | undefined;
    }>;
  };
};

export type AdminInvoicesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  status?: InputMaybe<InvoicesFilterByStatus>;
  orderBy?: InputMaybe<Array<InvoicesOrderBy> | InvoicesOrderBy>;
}>;

export type AdminInvoicesQuery = {
  __typename?: 'Query';
  adminGetInvoices: {
    __typename?: 'InvoiceListResponse';
    invoices: Array<{
      __typename?: 'InvoiceResponse';
      id: string;
      dueDate: string;
      cost: number;
      costExVat: number;
      url: string;
      user: {
        __typename?: 'UserResponse';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
      };
    }>;
  };
};

export type AdminIndexPerformanceQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdminIndexPerformanceQuery = {
  __typename?: 'Query';
  adminGetPerformance: {
    __typename?: 'AdminPerformanceResponse';
    rate24: number;
    ytd: number;
    year: number;
    start: number;
  };
};

export type AdminDisableUsersTotpMutationVariables = Exact<{
  userIDs: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type AdminDisableUsersTotpMutation = {
  __typename?: 'Mutation';
  adminManageUsersTotp: Array<{
    __typename?: 'UserResponse';
    id: string;
    totpEnabled: boolean;
  }>;
};

export type AdminCancelUserSubscriptionMutationVariables = Exact<{
  userId: Scalars['Int'];
  exchangeId: Scalars['Int'];
}>;

export type AdminCancelUserSubscriptionMutation = {
  __typename?: 'Mutation';
  AdminCancelUserSubscription: {
    __typename?: 'UserResponse';
    id: string;
    exchanges: Array<{
      __typename?: 'UserExchangeResponse';
      exchangeID: string;
      subscriptionStartDate?: string | null | undefined;
      wizardStatus: WizardStatusType;
      moneyUnderManagement: number;
      nextRebalanceDate?: string | null | undefined;
    }>;
  };
};

export type AdminSignInAsMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type AdminSignInAsMutation = {
  __typename?: 'Mutation';
  adminSignInAs: {
    __typename?: 'SignInResponse';
    token: string;
    authIdentityID: string;
  };
};

export type AdminSignOutAsMutationVariables = Exact<{ [key: string]: never }>;

export type AdminSignOutAsMutation = {
  __typename?: 'Mutation';
  adminSignOutAs: {
    __typename?: 'SignInResponse';
    token: string;
    authIdentityID: string;
  };
};

export type SendAdminEmailsMutationVariables = Exact<{
  templateId: Scalars['String'];
  userIDs?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  atiFilter?: InputMaybe<Scalars['Int']>;
  countries?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  exchangeId?: InputMaybe<Scalars['Int']>;
  customStatusFilter?: InputMaybe<Array<CustomUserStatus> | CustomUserStatus>;
  email?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
}>;

export type SendAdminEmailsMutation = {
  __typename?: 'Mutation';
  adminSendEmail: boolean;
};

export type AdminRebalanceUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  exchangeId: Scalars['Int'];
}>;

export type AdminRebalanceUserMutation = {
  __typename?: 'Mutation';
  adminRebalanceUser: {
    __typename?: 'UserResponse';
    id: string;
    nextRebalancingOn?: string | null | undefined;
  };
};

export type AdminRemoveUserKeysMutationVariables = Exact<{
  userId: Scalars['Int'];
  exchangeId: Scalars['Int'];
}>;

export type AdminRemoveUserKeysMutation = {
  __typename?: 'Mutation';
  adminRemoveKeys: {
    __typename?: 'UserResponse';
    id: string;
    status: UserStatus;
    hasActiveSubscription: boolean;
    nextRebalancingOn?: string | null | undefined;
    exchanges: Array<{
      __typename?: 'UserExchangeResponse';
      exchangeID: string;
      exchangeCode: ExchangeCode;
      keysStatus: KeysStatus;
      moneyUnderManagement: number;
    }>;
  };
};

export type AppBarDataQueryVariables = Exact<{ [key: string]: never }>;

export type AppBarDataQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    isAdmin: boolean;
  };
};

export type AppMenuDataQueryVariables = Exact<{ [key: string]: never }>;

export type AppMenuDataQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    isAdmin: boolean;
    controlledByUser?:
      | { __typename?: 'UserResponse'; id: string }
      | null
      | undefined;
  };
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  remember: Scalars['Boolean'];
  captchaToken?: InputMaybe<Scalars['String']>;
  captchaV2Token?: InputMaybe<Scalars['String']>;
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signIn: {
    __typename?: 'SignInResponse';
    token: string;
    totpEnabled: boolean;
    authIdentityID: string;
  };
};

export type SignUpMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  confPassword: Scalars['String'];
  referralCode?: InputMaybe<Scalars['String']>;
  captchaToken?: InputMaybe<Scalars['String']>;
  captchaV2Token?: InputMaybe<Scalars['String']>;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp: {
    __typename?: 'SignUpResponse';
    token: string;
    authIdentityId: string;
  };
};

export type GoogleSignInMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type GoogleSignInMutation = {
  __typename?: 'Mutation';
  googleSignIn: {
    __typename?: 'GoogleSignInResponse';
    token: string;
    totpEnabled: boolean;
    authIdentityID: string;
  };
};

export type GoogleSignUpMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type GoogleSignUpMutation = {
  __typename?: 'Mutation';
  googleSignUp: {
    __typename?: 'GoogleSignUpResponse';
    token: string;
    totpEnabled: boolean;
    authIdentityID: string;
  };
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = {
  __typename?: 'Mutation';
  signOut: { __typename?: 'SignOutResponse'; success: boolean };
};

export type DashboardDataQueryVariables = Exact<{
  exchangeId: Scalars['Int'];
}>;

export type DashboardDataQuery = {
  __typename?: 'Query';
  getPortfolioGraphData: Array<{
    __typename?: 'AggregatedDataRow';
    date: string;
    value: number;
  }>;
  getPortfolioTotals: {
    __typename?: 'PortfolioTotalsResponse';
    eurCostTotal: number;
    eurCostChange24Total: number;
    eurCostChange24Percentage: number;
    usdCostTotal: number;
    usdCostChange24total: number;
    usdCostChange24Percentage: number;
  };
  getDemoPortfolio: Array<{
    __typename?: 'PortfolioRowResponse';
    currency: {
      __typename?: 'Currency';
      id: string;
      symbol: string;
      isFiat: boolean;
    };
  }>;
  getPortfolio: Array<{
    __typename?: 'PortfolioRowResponse';
    change24Amount: number;
    usdChange24Amount: number;
    change24Percentage: number;
    usdChange24Percentage: number;
    cost: number;
    usdCost: number;
    percentage: number;
    amountCoins: number;
    currency: {
      __typename?: 'Currency';
      id: string;
      symbol: string;
      title: string;
      isFiat: boolean;
      piechartColor: string;
      messari: string;
      eurValue: number;
      usdValue: number;
    };
  }>;
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    currenciesLocked: Array<number>;
  };
};

export type PortfolioSummaryQueryVariables = Exact<{
  exchangeId: Scalars['Int'];
}>;

export type PortfolioSummaryQuery = {
  __typename?: 'Query';
  getPortfolioTotals: {
    __typename?: 'PortfolioTotalsResponse';
    nextRebalancingOn: string;
    eurCostTotal: number;
    eurCostChange24Total: number;
    eurCostChange24Percentage: number;
    usdCostTotal: number;
    usdCostChange24total: number;
    usdCostChange24Percentage: number;
  };
};

export type PortfolioCurrencyQueryVariables = Exact<{ [key: string]: never }>;

export type PortfolioCurrencyQuery = {
  __typename?: 'Query';
  portfolioCurrency?: PortfolioCurrency | null | undefined;
};

export type LockCurrencyMutationVariables = Exact<{
  id: Scalars['Int'];
  status: Scalars['Boolean'];
}>;

export type LockCurrencyMutation = {
  __typename?: 'Mutation';
  userLockCurrency: boolean;
};

export type PortfolioLiquidateMutationVariables = Exact<{
  exchangeId: Scalars['Int'];
  currencyId: Scalars['Int'];
  percents: Scalars['Float'];
}>;

export type PortfolioLiquidateMutation = {
  __typename?: 'Mutation';
  portfolioLiquidation: { __typename?: 'UserResponse'; id: string };
};

export type PortfolioDepositMutationVariables = Exact<{
  exchangeId: Scalars['Int'];
}>;

export type PortfolioDepositMutation = {
  __typename?: 'Mutation';
  portfolioDeposit: { __typename?: 'UserResponse'; id: string };
};

export type AllExchangesDataFragment = {
  __typename?: 'UserExchangeResponse';
  exchangeID: string;
  exchangeCode: ExchangeCode;
  keysStatus: KeysStatus;
  wizardStatus: WizardStatusType;
  moneyUnderManagement: number;
  aumBelowMinimum: boolean;
  subscriptionInitiatedDate?: string | null | undefined;
  subscriptionStartDate?: string | null | undefined;
  nextRebalanceDate?: string | null | undefined;
  onlyFiatInPortfolio: boolean;
  aumBelowMinLastShowTime?: string | null | undefined;
  fiatCurrencies: Array<{
    __typename?: 'Currency';
    id: string;
    symbol: string;
  }>;
  targetForSaleCurrencies: Array<{
    __typename?: 'Currency';
    id: string;
    symbol: string;
  }>;
};

export type AumBelowMinLastShowTimeFragment = {
  __typename?: 'UserExchangeResponse';
  aumBelowMinLastShowTime?: string | null | undefined;
};

export type UserExchangeProviderDataQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserExchangeProviderDataQuery = {
  __typename?: 'Query';
  selectedExchange?: ExchangeCode | null | undefined;
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    isAdmin: boolean;
    profileValid: boolean;
  };
  getUserExchanges: Array<{
    __typename?: 'UserExchangeResponse';
    exchangeID: string;
    exchangeCode: ExchangeCode;
    keysStatus: KeysStatus;
    wizardStatus: WizardStatusType;
    moneyUnderManagement: number;
    aumBelowMinimum: boolean;
    subscriptionInitiatedDate?: string | null | undefined;
    subscriptionStartDate?: string | null | undefined;
    nextRebalanceDate?: string | null | undefined;
    onlyFiatInPortfolio: boolean;
    aumBelowMinLastShowTime?: string | null | undefined;
    fiatCurrencies: Array<{
      __typename?: 'Currency';
      id: string;
      symbol: string;
    }>;
    targetForSaleCurrencies: Array<{
      __typename?: 'Currency';
      id: string;
      symbol: string;
    }>;
  }>;
};

export type UserExchangesQueryVariables = Exact<{ [key: string]: never }>;

export type UserExchangesQuery = {
  __typename?: 'Query';
  selectedExchange?: ExchangeCode | null | undefined;
  getUserExchanges: Array<{
    __typename?: 'UserExchangeResponse';
    exchangeID: string;
    exchangeCode: ExchangeCode;
    keysStatus: KeysStatus;
    wizardStatus: WizardStatusType;
    moneyUnderManagement: number;
    aumBelowMinimum: boolean;
    subscriptionInitiatedDate?: string | null | undefined;
    subscriptionStartDate?: string | null | undefined;
    nextRebalanceDate?: string | null | undefined;
    onlyFiatInPortfolio: boolean;
    aumBelowMinLastShowTime?: string | null | undefined;
    fiatCurrencies: Array<{
      __typename?: 'Currency';
      id: string;
      symbol: string;
    }>;
    targetForSaleCurrencies: Array<{
      __typename?: 'Currency';
      id: string;
      symbol: string;
    }>;
  }>;
};

export type UserExchangesStartDatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserExchangesStartDatesQuery = {
  __typename?: 'Query';
  getUserExchanges: Array<{
    __typename?: 'UserExchangeResponse';
    exchangeID: string;
    exchangeCode: ExchangeCode;
    subscriptionStartDate?: string | null | undefined;
  }>;
};

export type SelectedExchangeQueryVariables = Exact<{ [key: string]: never }>;

export type SelectedExchangeQuery = {
  __typename?: 'Query';
  selectedExchange?: ExchangeCode | null | undefined;
};

export type ExchangesSubscriptionInitiatedDatesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ExchangesSubscriptionInitiatedDatesQuery = {
  __typename?: 'Query';
  getUserExchanges: Array<{
    __typename?: 'UserExchangeResponse';
    exchangeID: string;
    subscriptionInitiatedDate?: string | null | undefined;
  }>;
};

export type AddExchangeKeysMutationVariables = Exact<{
  exchangeId: Scalars['Int'];
  key: Scalars['String'];
  secretKey: Scalars['String'];
}>;

export type AddExchangeKeysMutation = {
  __typename?: 'Mutation';
  addKeys: {
    __typename?: 'UserExchangeResponse';
    exchangeID: string;
    aumBelowMinimum: boolean;
    moneyUnderManagement: number;
    nextRebalanceDate?: string | null | undefined;
    wizardStatus: WizardStatusType;
    subscriptionStartDate?: string | null | undefined;
    subscriptionInitiatedDate?: string | null | undefined;
    onlyFiatInPortfolio: boolean;
  };
};

export type StartSubscriptionMutationVariables = Exact<{
  exchangeId: Scalars['Int'];
}>;

export type StartSubscriptionMutation = {
  __typename?: 'Mutation';
  startSubscription: StartTrialStatus;
};

export type DisableSubscriptionMutationVariables = Exact<{
  exchangeId: Scalars['Int'];
}>;

export type DisableSubscriptionMutation = {
  __typename?: 'Mutation';
  cancelSubscription?:
    | { __typename?: 'UserExchangeResponse'; exchangeID: string }
    | null
    | undefined;
};

export type CommonInvoiceDataFragment = {
  __typename?: 'InvoiceResponse';
  id: string;
  dueDate: string;
  cost: number;
  costExVat: number;
  url: string;
  status: InvoicePaymentStatus;
};

export type InvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type InvoicesQuery = {
  __typename?: 'Query';
  getInvoices: Array<{
    __typename?: 'InvoiceResponse';
    id: string;
    dueDate: string;
    url: string;
    status: InvoicePaymentStatus;
  }>;
};

export type InvoiceQueryVariables = Exact<{
  id: Scalars['Int'];
}>;

export type InvoiceQuery = {
  __typename?: 'Query';
  getInvoice: {
    __typename?: 'InvoiceResponse';
    id: string;
    dueDate: string;
    cost: number;
    costExVat: number;
    url: string;
    status: InvoicePaymentStatus;
  };
};

export type MainDataQueryVariables = Exact<{ [key: string]: never }>;

export type MainDataQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    isAdmin: boolean;
    status: UserStatus;
    companyName?: string | null | undefined;
    authProvider: string;
    trialEndDate?: string | null | undefined;
    mollieStatus: MollieStatus;
    hasActiveSubscription: boolean;
    hasUnpaidInvoices: boolean;
    profileValid: boolean;
    controlledByUser?:
      | { __typename?: 'UserResponse'; id: string }
      | null
      | undefined;
  };
  getUserExchanges: Array<{
    __typename?: 'UserExchangeResponse';
    exchangeID: string;
    exchangeCode: ExchangeCode;
    keysStatus: KeysStatus;
    wizardStatus: WizardStatusType;
    moneyUnderManagement: number;
    aumBelowMinimum: boolean;
    subscriptionInitiatedDate?: string | null | undefined;
    subscriptionStartDate?: string | null | undefined;
    nextRebalanceDate?: string | null | undefined;
    onlyFiatInPortfolio: boolean;
    aumBelowMinLastShowTime?: string | null | undefined;
    fiatCurrencies: Array<{
      __typename?: 'Currency';
      id: string;
      symbol: string;
    }>;
    targetForSaleCurrencies: Array<{
      __typename?: 'Currency';
      id: string;
      symbol: string;
    }>;
  }>;
  getMolliePaymentInfo: {
    __typename?: 'MolliePaymentResponse';
    method: string;
    status: string;
    createdAt: string;
    details: string;
  };
};

export type PaymentProviderDataQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentProviderDataQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    isAdmin: boolean;
    trialEndDate?: string | null | undefined;
    mollieStatus: MollieStatus;
    hasActiveSubscription: boolean;
    hasUnpaidInvoices: boolean;
  };
};

export type MolliePaymentInfoQueryVariables = Exact<{ [key: string]: never }>;

export type MolliePaymentInfoQuery = {
  __typename?: 'Query';
  getMolliePaymentInfo: {
    __typename?: 'MolliePaymentResponse';
    method: string;
    status: string;
    createdAt: string;
    details: string;
  };
};

export type GetMollieTransitPaymentLinkQueryVariables = Exact<{
  token: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
}>;

export type GetMollieTransitPaymentLinkQuery = {
  __typename?: 'Query';
  getMollieTransitPaymentLink: {
    __typename?: 'MollieTransitPaymentLinkResponse';
    checkoutURL: string;
  };
};

export type CreateNewMollieClientMutationVariables = Exact<{
  locale?: InputMaybe<Scalars['String']>;
}>;

export type CreateNewMollieClientMutation = {
  __typename?: 'Mutation';
  newMollieClient: {
    __typename?: 'NewMollieClientResponse';
    checkoutURL: string;
  };
};

export type DirectMolliePaymentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DirectMolliePaymentMutation = {
  __typename?: 'Mutation';
  directMolliePayment: {
    __typename?: 'DirectInvoicePaymentResponse';
    checkoutURL: string;
  };
};

export type InitialUserDataFragment = {
  __typename?: 'UserResponse';
  id: string;
  isAdmin: boolean;
  status: UserStatus;
  companyName?: string | null | undefined;
  authProvider: string;
  trialEndDate?: string | null | undefined;
  mollieStatus: MollieStatus;
  hasActiveSubscription: boolean;
  hasUnpaidInvoices: boolean;
  profileValid: boolean;
  controlledByUser?:
    | { __typename?: 'UserResponse'; id: string }
    | null
    | undefined;
};

export type UserIdQueryVariables = Exact<{ [key: string]: never }>;

export type UserIdQuery = {
  __typename?: 'Query';
  getUser: { __typename?: 'UserResponse'; id: string };
};

export type UserCompanyNameQueryVariables = Exact<{ [key: string]: never }>;

export type UserCompanyNameQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    companyName?: string | null | undefined;
  };
};

export type UserAuthIdentitiesQueryVariables = Exact<{ [key: string]: never }>;

export type UserAuthIdentitiesQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    authIdentities: Array<{
      __typename?: 'AuthIdentityResponse';
      id: string;
      provider: AuthIdentityProvider;
      uid: string;
    }>;
  };
};

export type UserHasActiveSubscriptionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserHasActiveSubscriptionQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    hasActiveSubscription: boolean;
  };
};

export type User2FaSectionDataQueryVariables = Exact<{ [key: string]: never }>;

export type User2FaSectionDataQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    totpEnabled: boolean;
    authIdentities: Array<{
      __typename?: 'AuthIdentityResponse';
      id: string;
      provider: AuthIdentityProvider;
    }>;
  };
};

export type GetUserTotpStatusQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserTotpStatusQuery = {
  __typename?: 'Query';
  getUser: { __typename?: 'UserResponse'; id: string; totpEnabled: boolean };
};

export type UserAdminStatusDataFragment = {
  __typename?: 'UserResponse';
  id: string;
  isAdmin: boolean;
};

export type UserAdminStatusQueryVariables = Exact<{ [key: string]: never }>;

export type UserAdminStatusQuery = {
  __typename?: 'Query';
  getUser: { __typename?: 'UserResponse'; id: string; isAdmin: boolean };
};

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type UserProfileQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    companyName?: string | null | undefined;
    vatNumber?: string | null | undefined;
    phone?: string | null | undefined;
    address?: string | null | undefined;
    houseNumber?: string | null | undefined;
    zip?: string | null | undefined;
    city?: string | null | undefined;
    country?: string | null | undefined;
    amountToInvest?: number | null | undefined;
  };
};

export type ControllingUserQueryVariables = Exact<{ [key: string]: never }>;

export type ControllingUserQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    controlledByUser?:
      | { __typename?: 'UserResponse'; id: string }
      | null
      | undefined;
  };
};

export type UserSubscriptionReferralCodeAndDiscountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserSubscriptionReferralCodeAndDiscountQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    personalReferalCode: string;
    pendingDiscount: number;
  };
};

export type UserLockedCurrenciesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserLockedCurrenciesQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    currenciesLocked: Array<number>;
  };
};

export type UserTrialEndDateQueryVariables = Exact<{ [key: string]: never }>;

export type UserTrialEndDateQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    trialEndDate?: string | null | undefined;
  };
};

export type UserValidProfileQueryVariables = Exact<{ [key: string]: never }>;

export type UserValidProfileQuery = {
  __typename?: 'Query';
  getUser: { __typename?: 'UserResponse'; id: string; profileValid: boolean };
};

export type UserChatBoxDataQueryVariables = Exact<{ [key: string]: never }>;

export type UserChatBoxDataQuery = {
  __typename?: 'Query';
  getUser: {
    __typename?: 'UserResponse';
    id: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    phone?: string | null | undefined;
    authIdentities: Array<{
      __typename?: 'AuthIdentityResponse';
      uid: string;
      provider: AuthIdentityProvider;
    }>;
  };
};

export type GenerateQrCodeMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateQrCodeMutation = {
  __typename?: 'Mutation';
  totpGenerate: string;
};

export type TotpVerifyMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type TotpVerifyMutation = {
  __typename?: 'Mutation';
  totpVerify?:
    | { __typename?: 'TotpVerifyResponse'; token: string }
    | null
    | undefined;
};

export type TotpSetupMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type TotpSetupMutation = {
  __typename?: 'Mutation';
  totpSetup?:
    | { __typename?: 'TotpValidateResponse'; backupCodes: Array<string> }
    | null
    | undefined;
};

export type TotpDisableMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type TotpDisableMutation = {
  __typename?: 'Mutation';
  totpDisable: { __typename?: 'TotpDisableResponse'; success: boolean };
};

export type ResendVerificationEmailMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;

export type ResendVerificationEmailMutation = {
  __typename?: 'Mutation';
  resendVerificationEmail: boolean;
};

export type EditUserProfileMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  vatNumber?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  amountToInvest?: InputMaybe<Scalars['Int']>;
  referralCode?: InputMaybe<Scalars['String']>;
  olderThan18AndTermsAccepted?: InputMaybe<Scalars['Boolean']>;
  sendMeNews?: InputMaybe<Scalars['Boolean']>;
  notUsPerson?: InputMaybe<Scalars['Boolean']>;
}>;

export type EditUserProfileMutation = {
  __typename?: 'Mutation';
  editProfile: {
    __typename?: 'UserResponse';
    id: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    companyName?: string | null | undefined;
    vatNumber?: string | null | undefined;
    address?: string | null | undefined;
    city?: string | null | undefined;
    phone?: string | null | undefined;
    houseNumber?: string | null | undefined;
    zip?: string | null | undefined;
    country?: string | null | undefined;
    amountToInvest?: number | null | undefined;
    profileValid: boolean;
  };
};

export type ChangeUserEmailMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  currentPassword: Scalars['String'];
}>;

export type ChangeUserEmailMutation = {
  __typename?: 'Mutation';
  changeCredentials: { __typename?: 'UserResponse'; id: string };
};

export type ChangeUserPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}>;

export type ChangeUserPasswordMutation = {
  __typename?: 'Mutation';
  changeCredentials: { __typename?: 'UserResponse'; id: string };
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = {
  __typename?: 'Mutation';
  resetPassword: { __typename?: 'ResetPasswordResponse'; success: boolean };
};

export type SendRestPasswordEmailMutationVariables = Exact<{
  email: Scalars['String'];
  captchaToken?: InputMaybe<Scalars['String']>;
  captchaV2Token?: InputMaybe<Scalars['String']>;
}>;

export type SendRestPasswordEmailMutation = {
  __typename?: 'Mutation';
  sendResetPasswordEmail: {
    __typename?: 'SendResetPasswordEmailResponse';
    success: boolean;
  };
};

export type RemoveAccountMutationVariables = Exact<{
  currentPassword?: InputMaybe<Scalars['String']>;
}>;

export type RemoveAccountMutation = {
  __typename?: 'Mutation';
  removeUser: { __typename?: 'RemoveUserResponse'; success: boolean };
};

export const AllExchangesDataFragmentDoc = gql`
  fragment allExchangesData on UserExchangeResponse {
    exchangeID
    exchangeCode
    keysStatus
    wizardStatus
    fiatCurrencies {
      id
      symbol
    }
    targetForSaleCurrencies {
      id
      symbol
    }
    moneyUnderManagement
    aumBelowMinimum
    subscriptionInitiatedDate
    subscriptionStartDate
    nextRebalanceDate
    onlyFiatInPortfolio
    aumBelowMinLastShowTime @client
  }
`;
export const AumBelowMinLastShowTimeFragmentDoc = gql`
  fragment aumBelowMinLastShowTime on UserExchangeResponse {
    aumBelowMinLastShowTime @client
  }
`;
export const CommonInvoiceDataFragmentDoc = gql`
  fragment commonInvoiceData on InvoiceResponse {
    id
    dueDate
    cost
    costExVat
    url
    status
  }
`;
export const InitialUserDataFragmentDoc = gql`
  fragment initialUserData on UserResponse {
    id
    isAdmin
    status
    companyName
    authProvider
    trialEndDate
    mollieStatus
    hasActiveSubscription
    hasUnpaidInvoices
    profileValid
    controlledByUser {
      id
    }
  }
`;
export const UserAdminStatusDataFragmentDoc = gql`
  fragment userAdminStatusData on UserResponse {
    id
    isAdmin
  }
`;
export const AdminUsersFiltersDataDocument = gql`
  query adminUsersFiltersData {
    getExchanges {
      id
      exchangeName
    }
    adminGetUsers {
      users {
        id
        country
        amountToInvest
      }
    }
  }
`;

/**
 * __useAdminUsersFiltersDataQuery__
 *
 * To run a query within a React component, call `useAdminUsersFiltersDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersFiltersDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersFiltersDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersFiltersDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminUsersFiltersDataQuery,
    AdminUsersFiltersDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminUsersFiltersDataQuery,
    AdminUsersFiltersDataQueryVariables
  >(AdminUsersFiltersDataDocument, options);
}
export function useAdminUsersFiltersDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUsersFiltersDataQuery,
    AdminUsersFiltersDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminUsersFiltersDataQuery,
    AdminUsersFiltersDataQueryVariables
  >(AdminUsersFiltersDataDocument, options);
}
export type AdminUsersFiltersDataQueryHookResult = ReturnType<
  typeof useAdminUsersFiltersDataQuery
>;
export type AdminUsersFiltersDataLazyQueryHookResult = ReturnType<
  typeof useAdminUsersFiltersDataLazyQuery
>;
export type AdminUsersFiltersDataQueryResult = ApolloReactCommon.QueryResult<
  AdminUsersFiltersDataQuery,
  AdminUsersFiltersDataQueryVariables
>;
export function refetchAdminUsersFiltersDataQuery(
  variables?: AdminUsersFiltersDataQueryVariables
) {
  return { query: AdminUsersFiltersDataDocument, variables: variables };
}
export const AdminAllUsersDocument = gql`
  query adminAllUsers(
    $orderBy: [UsersOrderBy!]
    $atiFilter: Int
    $countries: [String!]
    $customStatusFilter: [CustomUserStatus!]
    $exchangeId: Int
    $email: String
    $username: String
    $userIDs: [Int!]
    $offset: Int
    $limit: Int
  ) {
    adminGetUsers(
      orderBy: $orderBy
      atiFilterLess: $atiFilter
      atiFilterMore: $atiFilter
      countries: $countries
      customStatusFilter: $customStatusFilter
      exchangeId: $exchangeId
      email: $email
      username: $username
      userIds: $userIDs
      offset: $offset
      limit: $limit
    ) {
      total
      totalAum
      totalPaid
      users {
        id
        authIdentities {
          provider
          uid
        }
        dateOfSignup
        firstName
        lastName
        phone
        customStatus
        exchanges {
          exchangeID
          exchangeCode
          moneyUnderManagement
          keysStatus
        }
        amountToInvest
        moneyPaid
        totpEnabled
        hasUnpaidInvoices
        hasActiveSubscription
        mollieStatus
        trialEndDate
      }
    }
    adminGetInvoices {
      activeTotal
    }
  }
`;

/**
 * __useAdminAllUsersQuery__
 *
 * To run a query within a React component, call `useAdminAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminAllUsersQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      atiFilter: // value for 'atiFilter'
 *      countries: // value for 'countries'
 *      customStatusFilter: // value for 'customStatusFilter'
 *      exchangeId: // value for 'exchangeId'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      userIDs: // value for 'userIDs'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAdminAllUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminAllUsersQuery,
    AdminAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminAllUsersQuery,
    AdminAllUsersQueryVariables
  >(AdminAllUsersDocument, options);
}
export function useAdminAllUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminAllUsersQuery,
    AdminAllUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminAllUsersQuery,
    AdminAllUsersQueryVariables
  >(AdminAllUsersDocument, options);
}
export type AdminAllUsersQueryHookResult = ReturnType<
  typeof useAdminAllUsersQuery
>;
export type AdminAllUsersLazyQueryHookResult = ReturnType<
  typeof useAdminAllUsersLazyQuery
>;
export type AdminAllUsersQueryResult = ApolloReactCommon.QueryResult<
  AdminAllUsersQuery,
  AdminAllUsersQueryVariables
>;
export function refetchAdminAllUsersQuery(
  variables?: AdminAllUsersQueryVariables
) {
  return { query: AdminAllUsersDocument, variables: variables };
}
export const AdminUserStatusAndActiveSubscriptionDocument = gql`
  query adminUserStatusAndActiveSubscription($userId: Int!) {
    adminGetUser(userId: $userId) {
      id
      status
      hasActiveSubscription
    }
  }
`;

/**
 * __useAdminUserStatusAndActiveSubscriptionQuery__
 *
 * To run a query within a React component, call `useAdminUserStatusAndActiveSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUserStatusAndActiveSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUserStatusAndActiveSubscriptionQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminUserStatusAndActiveSubscriptionQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AdminUserStatusAndActiveSubscriptionQuery,
    AdminUserStatusAndActiveSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminUserStatusAndActiveSubscriptionQuery,
    AdminUserStatusAndActiveSubscriptionQueryVariables
  >(AdminUserStatusAndActiveSubscriptionDocument, options);
}
export function useAdminUserStatusAndActiveSubscriptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUserStatusAndActiveSubscriptionQuery,
    AdminUserStatusAndActiveSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminUserStatusAndActiveSubscriptionQuery,
    AdminUserStatusAndActiveSubscriptionQueryVariables
  >(AdminUserStatusAndActiveSubscriptionDocument, options);
}
export type AdminUserStatusAndActiveSubscriptionQueryHookResult = ReturnType<
  typeof useAdminUserStatusAndActiveSubscriptionQuery
>;
export type AdminUserStatusAndActiveSubscriptionLazyQueryHookResult =
  ReturnType<typeof useAdminUserStatusAndActiveSubscriptionLazyQuery>;
export type AdminUserStatusAndActiveSubscriptionQueryResult =
  ApolloReactCommon.QueryResult<
    AdminUserStatusAndActiveSubscriptionQuery,
    AdminUserStatusAndActiveSubscriptionQueryVariables
  >;
export function refetchAdminUserStatusAndActiveSubscriptionQuery(
  variables: AdminUserStatusAndActiveSubscriptionQueryVariables
) {
  return {
    query: AdminUserStatusAndActiveSubscriptionDocument,
    variables: variables,
  };
}
export const AdminUsersFirstAndLastNamesDocument = gql`
  query adminUsersFirstAndLastNames {
    adminGetUsers {
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

/**
 * __useAdminUsersFirstAndLastNamesQuery__
 *
 * To run a query within a React component, call `useAdminUsersFirstAndLastNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminUsersFirstAndLastNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminUsersFirstAndLastNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminUsersFirstAndLastNamesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminUsersFirstAndLastNamesQuery,
    AdminUsersFirstAndLastNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminUsersFirstAndLastNamesQuery,
    AdminUsersFirstAndLastNamesQueryVariables
  >(AdminUsersFirstAndLastNamesDocument, options);
}
export function useAdminUsersFirstAndLastNamesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminUsersFirstAndLastNamesQuery,
    AdminUsersFirstAndLastNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminUsersFirstAndLastNamesQuery,
    AdminUsersFirstAndLastNamesQueryVariables
  >(AdminUsersFirstAndLastNamesDocument, options);
}
export type AdminUsersFirstAndLastNamesQueryHookResult = ReturnType<
  typeof useAdminUsersFirstAndLastNamesQuery
>;
export type AdminUsersFirstAndLastNamesLazyQueryHookResult = ReturnType<
  typeof useAdminUsersFirstAndLastNamesLazyQuery
>;
export type AdminUsersFirstAndLastNamesQueryResult =
  ApolloReactCommon.QueryResult<
    AdminUsersFirstAndLastNamesQuery,
    AdminUsersFirstAndLastNamesQueryVariables
  >;
export function refetchAdminUsersFirstAndLastNamesQuery(
  variables?: AdminUsersFirstAndLastNamesQueryVariables
) {
  return { query: AdminUsersFirstAndLastNamesDocument, variables: variables };
}
export const AdminInvoicesDocument = gql`
  query adminInvoices(
    $ids: [Int!]
    $status: InvoicesFilterByStatus
    $orderBy: [InvoicesOrderBy!]
  ) {
    adminGetInvoices(usersIDs: $ids, statusFilter: $status, orderBy: $orderBy) {
      invoices {
        id
        dueDate
        cost
        costExVat
        url
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useAdminInvoicesQuery__
 *
 * To run a query within a React component, call `useAdminInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminInvoicesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      status: // value for 'status'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAdminInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminInvoicesQuery,
    AdminInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminInvoicesQuery,
    AdminInvoicesQueryVariables
  >(AdminInvoicesDocument, options);
}
export function useAdminInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminInvoicesQuery,
    AdminInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminInvoicesQuery,
    AdminInvoicesQueryVariables
  >(AdminInvoicesDocument, options);
}
export type AdminInvoicesQueryHookResult = ReturnType<
  typeof useAdminInvoicesQuery
>;
export type AdminInvoicesLazyQueryHookResult = ReturnType<
  typeof useAdminInvoicesLazyQuery
>;
export type AdminInvoicesQueryResult = ApolloReactCommon.QueryResult<
  AdminInvoicesQuery,
  AdminInvoicesQueryVariables
>;
export function refetchAdminInvoicesQuery(
  variables?: AdminInvoicesQueryVariables
) {
  return { query: AdminInvoicesDocument, variables: variables };
}
export const AdminIndexPerformanceDocument = gql`
  query adminIndexPerformance {
    adminGetPerformance {
      rate24
      ytd
      year
      start
    }
  }
`;

/**
 * __useAdminIndexPerformanceQuery__
 *
 * To run a query within a React component, call `useAdminIndexPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminIndexPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminIndexPerformanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminIndexPerformanceQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminIndexPerformanceQuery,
    AdminIndexPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AdminIndexPerformanceQuery,
    AdminIndexPerformanceQueryVariables
  >(AdminIndexPerformanceDocument, options);
}
export function useAdminIndexPerformanceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminIndexPerformanceQuery,
    AdminIndexPerformanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AdminIndexPerformanceQuery,
    AdminIndexPerformanceQueryVariables
  >(AdminIndexPerformanceDocument, options);
}
export type AdminIndexPerformanceQueryHookResult = ReturnType<
  typeof useAdminIndexPerformanceQuery
>;
export type AdminIndexPerformanceLazyQueryHookResult = ReturnType<
  typeof useAdminIndexPerformanceLazyQuery
>;
export type AdminIndexPerformanceQueryResult = ApolloReactCommon.QueryResult<
  AdminIndexPerformanceQuery,
  AdminIndexPerformanceQueryVariables
>;
export function refetchAdminIndexPerformanceQuery(
  variables?: AdminIndexPerformanceQueryVariables
) {
  return { query: AdminIndexPerformanceDocument, variables: variables };
}
export const AdminDisableUsersTotpDocument = gql`
  mutation adminDisableUsersTOTP($userIDs: [Int!]!) {
    adminManageUsersTotp(enable: false, usersIDs: $userIDs) {
      id
      totpEnabled
    }
  }
`;
export type AdminDisableUsersTotpMutationFn =
  ApolloReactCommon.MutationFunction<
    AdminDisableUsersTotpMutation,
    AdminDisableUsersTotpMutationVariables
  >;

/**
 * __useAdminDisableUsersTotpMutation__
 *
 * To run a mutation, you first call `useAdminDisableUsersTotpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminDisableUsersTotpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminDisableUsersTotpMutation, { data, loading, error }] = useAdminDisableUsersTotpMutation({
 *   variables: {
 *      userIDs: // value for 'userIDs'
 *   },
 * });
 */
export function useAdminDisableUsersTotpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminDisableUsersTotpMutation,
    AdminDisableUsersTotpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminDisableUsersTotpMutation,
    AdminDisableUsersTotpMutationVariables
  >(AdminDisableUsersTotpDocument, options);
}
export type AdminDisableUsersTotpMutationHookResult = ReturnType<
  typeof useAdminDisableUsersTotpMutation
>;
export type AdminDisableUsersTotpMutationResult =
  ApolloReactCommon.MutationResult<AdminDisableUsersTotpMutation>;
export type AdminDisableUsersTotpMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminDisableUsersTotpMutation,
    AdminDisableUsersTotpMutationVariables
  >;
export const AdminCancelUserSubscriptionDocument = gql`
  mutation adminCancelUserSubscription($userId: Int!, $exchangeId: Int!) {
    AdminCancelUserSubscription(userID: $userId, exchangeId: $exchangeId) {
      id
      exchanges {
        exchangeID
        subscriptionStartDate
        wizardStatus
        moneyUnderManagement
        nextRebalanceDate
      }
    }
  }
`;
export type AdminCancelUserSubscriptionMutationFn =
  ApolloReactCommon.MutationFunction<
    AdminCancelUserSubscriptionMutation,
    AdminCancelUserSubscriptionMutationVariables
  >;

/**
 * __useAdminCancelUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useAdminCancelUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCancelUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCancelUserSubscriptionMutation, { data, loading, error }] = useAdminCancelUserSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useAdminCancelUserSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminCancelUserSubscriptionMutation,
    AdminCancelUserSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminCancelUserSubscriptionMutation,
    AdminCancelUserSubscriptionMutationVariables
  >(AdminCancelUserSubscriptionDocument, options);
}
export type AdminCancelUserSubscriptionMutationHookResult = ReturnType<
  typeof useAdminCancelUserSubscriptionMutation
>;
export type AdminCancelUserSubscriptionMutationResult =
  ApolloReactCommon.MutationResult<AdminCancelUserSubscriptionMutation>;
export type AdminCancelUserSubscriptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminCancelUserSubscriptionMutation,
    AdminCancelUserSubscriptionMutationVariables
  >;
export const AdminSignInAsDocument = gql`
  mutation adminSignInAs($userId: Int!) {
    adminSignInAs(userID: $userId) {
      token
      authIdentityID
    }
  }
`;
export type AdminSignInAsMutationFn = ApolloReactCommon.MutationFunction<
  AdminSignInAsMutation,
  AdminSignInAsMutationVariables
>;

/**
 * __useAdminSignInAsMutation__
 *
 * To run a mutation, you first call `useAdminSignInAsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSignInAsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSignInAsMutation, { data, loading, error }] = useAdminSignInAsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminSignInAsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminSignInAsMutation,
    AdminSignInAsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminSignInAsMutation,
    AdminSignInAsMutationVariables
  >(AdminSignInAsDocument, options);
}
export type AdminSignInAsMutationHookResult = ReturnType<
  typeof useAdminSignInAsMutation
>;
export type AdminSignInAsMutationResult =
  ApolloReactCommon.MutationResult<AdminSignInAsMutation>;
export type AdminSignInAsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminSignInAsMutation,
    AdminSignInAsMutationVariables
  >;
export const AdminSignOutAsDocument = gql`
  mutation adminSignOutAs {
    adminSignOutAs {
      token
      authIdentityID
    }
  }
`;
export type AdminSignOutAsMutationFn = ApolloReactCommon.MutationFunction<
  AdminSignOutAsMutation,
  AdminSignOutAsMutationVariables
>;

/**
 * __useAdminSignOutAsMutation__
 *
 * To run a mutation, you first call `useAdminSignOutAsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminSignOutAsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminSignOutAsMutation, { data, loading, error }] = useAdminSignOutAsMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminSignOutAsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminSignOutAsMutation,
    AdminSignOutAsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminSignOutAsMutation,
    AdminSignOutAsMutationVariables
  >(AdminSignOutAsDocument, options);
}
export type AdminSignOutAsMutationHookResult = ReturnType<
  typeof useAdminSignOutAsMutation
>;
export type AdminSignOutAsMutationResult =
  ApolloReactCommon.MutationResult<AdminSignOutAsMutation>;
export type AdminSignOutAsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminSignOutAsMutation,
    AdminSignOutAsMutationVariables
  >;
export const SendAdminEmailsDocument = gql`
  mutation sendAdminEmails(
    $templateId: String!
    $userIDs: [Int!]
    $atiFilter: Int
    $countries: [String!]
    $exchangeId: Int
    $customStatusFilter: [CustomUserStatus!]
    $email: String
    $username: String
  ) {
    adminSendEmail(
      templateId: $templateId
      usersIDs: $userIDs
      atiFilterLess: $atiFilter
      atiFilterMore: $atiFilter
      countries: $countries
      exchangeId: $exchangeId
      customStatusFilter: $customStatusFilter
      email: $email
      username: $username
    )
  }
`;
export type SendAdminEmailsMutationFn = ApolloReactCommon.MutationFunction<
  SendAdminEmailsMutation,
  SendAdminEmailsMutationVariables
>;

/**
 * __useSendAdminEmailsMutation__
 *
 * To run a mutation, you first call `useSendAdminEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAdminEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAdminEmailsMutation, { data, loading, error }] = useSendAdminEmailsMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      userIDs: // value for 'userIDs'
 *      atiFilter: // value for 'atiFilter'
 *      countries: // value for 'countries'
 *      exchangeId: // value for 'exchangeId'
 *      customStatusFilter: // value for 'customStatusFilter'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSendAdminEmailsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendAdminEmailsMutation,
    SendAdminEmailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendAdminEmailsMutation,
    SendAdminEmailsMutationVariables
  >(SendAdminEmailsDocument, options);
}
export type SendAdminEmailsMutationHookResult = ReturnType<
  typeof useSendAdminEmailsMutation
>;
export type SendAdminEmailsMutationResult =
  ApolloReactCommon.MutationResult<SendAdminEmailsMutation>;
export type SendAdminEmailsMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SendAdminEmailsMutation,
    SendAdminEmailsMutationVariables
  >;
export const AdminRebalanceUserDocument = gql`
  mutation adminRebalanceUser($userId: Int!, $exchangeId: Int!) {
    adminRebalanceUser(userID: $userId, exchangeId: $exchangeId) {
      id
      nextRebalancingOn
    }
  }
`;
export type AdminRebalanceUserMutationFn = ApolloReactCommon.MutationFunction<
  AdminRebalanceUserMutation,
  AdminRebalanceUserMutationVariables
>;

/**
 * __useAdminRebalanceUserMutation__
 *
 * To run a mutation, you first call `useAdminRebalanceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRebalanceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRebalanceUserMutation, { data, loading, error }] = useAdminRebalanceUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useAdminRebalanceUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminRebalanceUserMutation,
    AdminRebalanceUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminRebalanceUserMutation,
    AdminRebalanceUserMutationVariables
  >(AdminRebalanceUserDocument, options);
}
export type AdminRebalanceUserMutationHookResult = ReturnType<
  typeof useAdminRebalanceUserMutation
>;
export type AdminRebalanceUserMutationResult =
  ApolloReactCommon.MutationResult<AdminRebalanceUserMutation>;
export type AdminRebalanceUserMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminRebalanceUserMutation,
    AdminRebalanceUserMutationVariables
  >;
export const AdminRemoveUserKeysDocument = gql`
  mutation adminRemoveUserKeys($userId: Int!, $exchangeId: Int!) {
    adminRemoveKeys(userID: $userId, exchangeId: $exchangeId) {
      id
      status
      exchanges {
        exchangeID
        exchangeCode
        keysStatus
        moneyUnderManagement
      }
      hasActiveSubscription
      nextRebalancingOn
    }
  }
`;
export type AdminRemoveUserKeysMutationFn = ApolloReactCommon.MutationFunction<
  AdminRemoveUserKeysMutation,
  AdminRemoveUserKeysMutationVariables
>;

/**
 * __useAdminRemoveUserKeysMutation__
 *
 * To run a mutation, you first call `useAdminRemoveUserKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRemoveUserKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRemoveUserKeysMutation, { data, loading, error }] = useAdminRemoveUserKeysMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useAdminRemoveUserKeysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AdminRemoveUserKeysMutation,
    AdminRemoveUserKeysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AdminRemoveUserKeysMutation,
    AdminRemoveUserKeysMutationVariables
  >(AdminRemoveUserKeysDocument, options);
}
export type AdminRemoveUserKeysMutationHookResult = ReturnType<
  typeof useAdminRemoveUserKeysMutation
>;
export type AdminRemoveUserKeysMutationResult =
  ApolloReactCommon.MutationResult<AdminRemoveUserKeysMutation>;
export type AdminRemoveUserKeysMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AdminRemoveUserKeysMutation,
    AdminRemoveUserKeysMutationVariables
  >;
export const AppBarDataDocument = gql`
  query appBarData {
    getUser {
      id
      firstName
      lastName
      isAdmin
    }
  }
`;

/**
 * __useAppBarDataQuery__
 *
 * To run a query within a React component, call `useAppBarDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppBarDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppBarDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppBarDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AppBarDataQuery,
    AppBarDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AppBarDataQuery, AppBarDataQueryVariables>(
    AppBarDataDocument,
    options
  );
}
export function useAppBarDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppBarDataQuery,
    AppBarDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AppBarDataQuery,
    AppBarDataQueryVariables
  >(AppBarDataDocument, options);
}
export type AppBarDataQueryHookResult = ReturnType<typeof useAppBarDataQuery>;
export type AppBarDataLazyQueryHookResult = ReturnType<
  typeof useAppBarDataLazyQuery
>;
export type AppBarDataQueryResult = ApolloReactCommon.QueryResult<
  AppBarDataQuery,
  AppBarDataQueryVariables
>;
export function refetchAppBarDataQuery(variables?: AppBarDataQueryVariables) {
  return { query: AppBarDataDocument, variables: variables };
}
export const AppMenuDataDocument = gql`
  query appMenuData {
    getUser {
      id
      isAdmin
      controlledByUser {
        id
      }
    }
  }
`;

/**
 * __useAppMenuDataQuery__
 *
 * To run a query within a React component, call `useAppMenuDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppMenuDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppMenuDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppMenuDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AppMenuDataQuery,
    AppMenuDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AppMenuDataQuery, AppMenuDataQueryVariables>(
    AppMenuDataDocument,
    options
  );
}
export function useAppMenuDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AppMenuDataQuery,
    AppMenuDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AppMenuDataQuery,
    AppMenuDataQueryVariables
  >(AppMenuDataDocument, options);
}
export type AppMenuDataQueryHookResult = ReturnType<typeof useAppMenuDataQuery>;
export type AppMenuDataLazyQueryHookResult = ReturnType<
  typeof useAppMenuDataLazyQuery
>;
export type AppMenuDataQueryResult = ApolloReactCommon.QueryResult<
  AppMenuDataQuery,
  AppMenuDataQueryVariables
>;
export function refetchAppMenuDataQuery(variables?: AppMenuDataQueryVariables) {
  return { query: AppMenuDataDocument, variables: variables };
}
export const SignInDocument = gql`
  mutation signIn(
    $email: String!
    $password: String!
    $remember: Boolean!
    $captchaToken: String
    $captchaV2Token: String
  ) {
    signIn(
      email: $email
      password: $password
      remember: $remember
      captchaToken: $captchaToken
      captchaV2Token: $captchaV2Token
    ) {
      token
      totpEnabled
      authIdentityID
    }
  }
`;
export type SignInMutationFn = ApolloReactCommon.MutationFunction<
  SignInMutation,
  SignInMutationVariables
>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      remember: // value for 'remember'
 *      captchaToken: // value for 'captchaToken'
 *      captchaV2Token: // value for 'captchaV2Token'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignInMutation,
    SignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignInMutation, SignInMutationVariables>(
    SignInDocument,
    options
  );
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult =
  ApolloReactCommon.MutationResult<SignInMutation>;
export type SignInMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const SignUpDocument = gql`
  mutation signUp(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $confPassword: String!
    $referralCode: String
    $captchaToken: String
    $captchaV2Token: String
  ) {
    signUp(
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      passwordRepeat: $confPassword
      referralCode: $referralCode
      captchaToken: $captchaToken
      captchaV2Token: $captchaV2Token
    ) {
      token
      authIdentityId
    }
  }
`;
export type SignUpMutationFn = ApolloReactCommon.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      confPassword: // value for 'confPassword'
 *      referralCode: // value for 'referralCode'
 *      captchaToken: // value for 'captchaToken'
 *      captchaV2Token: // value for 'captchaV2Token'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult =
  ApolloReactCommon.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const GoogleSignInDocument = gql`
  mutation googleSignIn($token: String!) {
    googleSignIn(token: $token) {
      token
      totpEnabled
      authIdentityID
    }
  }
`;
export type GoogleSignInMutationFn = ApolloReactCommon.MutationFunction<
  GoogleSignInMutation,
  GoogleSignInMutationVariables
>;

/**
 * __useGoogleSignInMutation__
 *
 * To run a mutation, you first call `useGoogleSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignInMutation, { data, loading, error }] = useGoogleSignInMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGoogleSignInMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GoogleSignInMutation,
    GoogleSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GoogleSignInMutation,
    GoogleSignInMutationVariables
  >(GoogleSignInDocument, options);
}
export type GoogleSignInMutationHookResult = ReturnType<
  typeof useGoogleSignInMutation
>;
export type GoogleSignInMutationResult =
  ApolloReactCommon.MutationResult<GoogleSignInMutation>;
export type GoogleSignInMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GoogleSignInMutation,
  GoogleSignInMutationVariables
>;
export const GoogleSignUpDocument = gql`
  mutation googleSignUp($token: String!) {
    googleSignUp(token: $token) {
      token
      totpEnabled
      authIdentityID
    }
  }
`;
export type GoogleSignUpMutationFn = ApolloReactCommon.MutationFunction<
  GoogleSignUpMutation,
  GoogleSignUpMutationVariables
>;

/**
 * __useGoogleSignUpMutation__
 *
 * To run a mutation, you first call `useGoogleSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignUpMutation, { data, loading, error }] = useGoogleSignUpMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGoogleSignUpMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GoogleSignUpMutation,
    GoogleSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GoogleSignUpMutation,
    GoogleSignUpMutationVariables
  >(GoogleSignUpDocument, options);
}
export type GoogleSignUpMutationHookResult = ReturnType<
  typeof useGoogleSignUpMutation
>;
export type GoogleSignUpMutationResult =
  ApolloReactCommon.MutationResult<GoogleSignUpMutation>;
export type GoogleSignUpMutationOptions = ApolloReactCommon.BaseMutationOptions<
  GoogleSignUpMutation,
  GoogleSignUpMutationVariables
>;
export const SignOutDocument = gql`
  mutation signOut {
    signOut {
      success
    }
  }
`;
export type SignOutMutationFn = ApolloReactCommon.MutationFunction<
  SignOutMutation,
  SignOutMutationVariables
>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignOutMutation,
    SignOutMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SignOutMutation,
    SignOutMutationVariables
  >(SignOutDocument, options);
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult =
  ApolloReactCommon.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>;
export const DashboardDataDocument = gql`
  query dashboardData($exchangeId: Int!) {
    getPortfolioGraphData(exchangeId: $exchangeId) {
      date
      value
    }
    getPortfolioTotals(exchangeId: $exchangeId) {
      eurCostTotal
      eurCostChange24Total
      eurCostChange24Percentage
      usdCostTotal
      usdCostChange24total
      usdCostChange24Percentage
    }
    getDemoPortfolio(exchangeId: $exchangeId) {
      currency {
        id
        symbol
        isFiat
      }
    }
    getPortfolio(exchangeId: $exchangeId) {
      change24Amount
      usdChange24Amount
      change24Percentage
      usdChange24Percentage
      cost
      usdCost
      currency {
        id
        symbol
        title
        isFiat
        piechartColor
        messari
        eurValue
        usdValue
      }
      percentage
      amountCoins
    }
    getUser {
      id
      currenciesLocked
    }
  }
`;

/**
 * __useDashboardDataQuery__
 *
 * To run a query within a React component, call `useDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDataQuery({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useDashboardDataQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    DashboardDataQuery,
    DashboardDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    DashboardDataQuery,
    DashboardDataQueryVariables
  >(DashboardDataDocument, options);
}
export function useDashboardDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DashboardDataQuery,
    DashboardDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    DashboardDataQuery,
    DashboardDataQueryVariables
  >(DashboardDataDocument, options);
}
export type DashboardDataQueryHookResult = ReturnType<
  typeof useDashboardDataQuery
>;
export type DashboardDataLazyQueryHookResult = ReturnType<
  typeof useDashboardDataLazyQuery
>;
export type DashboardDataQueryResult = ApolloReactCommon.QueryResult<
  DashboardDataQuery,
  DashboardDataQueryVariables
>;
export function refetchDashboardDataQuery(
  variables: DashboardDataQueryVariables
) {
  return { query: DashboardDataDocument, variables: variables };
}
export const PortfolioSummaryDocument = gql`
  query portfolioSummary($exchangeId: Int!) {
    getPortfolioTotals(exchangeId: $exchangeId) {
      nextRebalancingOn
      eurCostTotal
      eurCostChange24Total
      eurCostChange24Percentage
      usdCostTotal
      usdCostChange24total
      usdCostChange24Percentage
    }
  }
`;

/**
 * __usePortfolioSummaryQuery__
 *
 * To run a query within a React component, call `usePortfolioSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioSummaryQuery({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function usePortfolioSummaryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PortfolioSummaryQuery,
    PortfolioSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PortfolioSummaryQuery,
    PortfolioSummaryQueryVariables
  >(PortfolioSummaryDocument, options);
}
export function usePortfolioSummaryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PortfolioSummaryQuery,
    PortfolioSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PortfolioSummaryQuery,
    PortfolioSummaryQueryVariables
  >(PortfolioSummaryDocument, options);
}
export type PortfolioSummaryQueryHookResult = ReturnType<
  typeof usePortfolioSummaryQuery
>;
export type PortfolioSummaryLazyQueryHookResult = ReturnType<
  typeof usePortfolioSummaryLazyQuery
>;
export type PortfolioSummaryQueryResult = ApolloReactCommon.QueryResult<
  PortfolioSummaryQuery,
  PortfolioSummaryQueryVariables
>;
export function refetchPortfolioSummaryQuery(
  variables: PortfolioSummaryQueryVariables
) {
  return { query: PortfolioSummaryDocument, variables: variables };
}
export const PortfolioCurrencyDocument = gql`
  query portfolioCurrency {
    portfolioCurrency @client
  }
`;

/**
 * __usePortfolioCurrencyQuery__
 *
 * To run a query within a React component, call `usePortfolioCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfolioCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfolioCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function usePortfolioCurrencyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PortfolioCurrencyQuery,
    PortfolioCurrencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PortfolioCurrencyQuery,
    PortfolioCurrencyQueryVariables
  >(PortfolioCurrencyDocument, options);
}
export function usePortfolioCurrencyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PortfolioCurrencyQuery,
    PortfolioCurrencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PortfolioCurrencyQuery,
    PortfolioCurrencyQueryVariables
  >(PortfolioCurrencyDocument, options);
}
export type PortfolioCurrencyQueryHookResult = ReturnType<
  typeof usePortfolioCurrencyQuery
>;
export type PortfolioCurrencyLazyQueryHookResult = ReturnType<
  typeof usePortfolioCurrencyLazyQuery
>;
export type PortfolioCurrencyQueryResult = ApolloReactCommon.QueryResult<
  PortfolioCurrencyQuery,
  PortfolioCurrencyQueryVariables
>;
export function refetchPortfolioCurrencyQuery(
  variables?: PortfolioCurrencyQueryVariables
) {
  return { query: PortfolioCurrencyDocument, variables: variables };
}
export const LockCurrencyDocument = gql`
  mutation lockCurrency($id: Int!, $status: Boolean!) {
    userLockCurrency(currencyId: $id, status: $status)
  }
`;
export type LockCurrencyMutationFn = ApolloReactCommon.MutationFunction<
  LockCurrencyMutation,
  LockCurrencyMutationVariables
>;

/**
 * __useLockCurrencyMutation__
 *
 * To run a mutation, you first call `useLockCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLockCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lockCurrencyMutation, { data, loading, error }] = useLockCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useLockCurrencyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LockCurrencyMutation,
    LockCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    LockCurrencyMutation,
    LockCurrencyMutationVariables
  >(LockCurrencyDocument, options);
}
export type LockCurrencyMutationHookResult = ReturnType<
  typeof useLockCurrencyMutation
>;
export type LockCurrencyMutationResult =
  ApolloReactCommon.MutationResult<LockCurrencyMutation>;
export type LockCurrencyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  LockCurrencyMutation,
  LockCurrencyMutationVariables
>;
export const PortfolioLiquidateDocument = gql`
  mutation portfolioLiquidate(
    $exchangeId: Int!
    $currencyId: Int!
    $percents: Float!
  ) {
    portfolioLiquidation(
      exchangeId: $exchangeId
      currencyId: $currencyId
      percents: $percents
    ) {
      id
    }
  }
`;
export type PortfolioLiquidateMutationFn = ApolloReactCommon.MutationFunction<
  PortfolioLiquidateMutation,
  PortfolioLiquidateMutationVariables
>;

/**
 * __usePortfolioLiquidateMutation__
 *
 * To run a mutation, you first call `usePortfolioLiquidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePortfolioLiquidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [portfolioLiquidateMutation, { data, loading, error }] = usePortfolioLiquidateMutation({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *      currencyId: // value for 'currencyId'
 *      percents: // value for 'percents'
 *   },
 * });
 */
export function usePortfolioLiquidateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PortfolioLiquidateMutation,
    PortfolioLiquidateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PortfolioLiquidateMutation,
    PortfolioLiquidateMutationVariables
  >(PortfolioLiquidateDocument, options);
}
export type PortfolioLiquidateMutationHookResult = ReturnType<
  typeof usePortfolioLiquidateMutation
>;
export type PortfolioLiquidateMutationResult =
  ApolloReactCommon.MutationResult<PortfolioLiquidateMutation>;
export type PortfolioLiquidateMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PortfolioLiquidateMutation,
    PortfolioLiquidateMutationVariables
  >;
export const PortfolioDepositDocument = gql`
  mutation portfolioDeposit($exchangeId: Int!) {
    portfolioDeposit(exchangeId: $exchangeId) {
      id
    }
  }
`;
export type PortfolioDepositMutationFn = ApolloReactCommon.MutationFunction<
  PortfolioDepositMutation,
  PortfolioDepositMutationVariables
>;

/**
 * __usePortfolioDepositMutation__
 *
 * To run a mutation, you first call `usePortfolioDepositMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePortfolioDepositMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [portfolioDepositMutation, { data, loading, error }] = usePortfolioDepositMutation({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function usePortfolioDepositMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    PortfolioDepositMutation,
    PortfolioDepositMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    PortfolioDepositMutation,
    PortfolioDepositMutationVariables
  >(PortfolioDepositDocument, options);
}
export type PortfolioDepositMutationHookResult = ReturnType<
  typeof usePortfolioDepositMutation
>;
export type PortfolioDepositMutationResult =
  ApolloReactCommon.MutationResult<PortfolioDepositMutation>;
export type PortfolioDepositMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    PortfolioDepositMutation,
    PortfolioDepositMutationVariables
  >;
export const UserExchangeProviderDataDocument = gql`
  query userExchangeProviderData {
    getUser {
      id
      isAdmin
      profileValid
    }
    selectedExchange @client
    getUserExchanges {
      ...allExchangesData
    }
  }
  ${AllExchangesDataFragmentDoc}
`;

/**
 * __useUserExchangeProviderDataQuery__
 *
 * To run a query within a React component, call `useUserExchangeProviderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExchangeProviderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExchangeProviderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserExchangeProviderDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserExchangeProviderDataQuery,
    UserExchangeProviderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserExchangeProviderDataQuery,
    UserExchangeProviderDataQueryVariables
  >(UserExchangeProviderDataDocument, options);
}
export function useUserExchangeProviderDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserExchangeProviderDataQuery,
    UserExchangeProviderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserExchangeProviderDataQuery,
    UserExchangeProviderDataQueryVariables
  >(UserExchangeProviderDataDocument, options);
}
export type UserExchangeProviderDataQueryHookResult = ReturnType<
  typeof useUserExchangeProviderDataQuery
>;
export type UserExchangeProviderDataLazyQueryHookResult = ReturnType<
  typeof useUserExchangeProviderDataLazyQuery
>;
export type UserExchangeProviderDataQueryResult = ApolloReactCommon.QueryResult<
  UserExchangeProviderDataQuery,
  UserExchangeProviderDataQueryVariables
>;
export function refetchUserExchangeProviderDataQuery(
  variables?: UserExchangeProviderDataQueryVariables
) {
  return { query: UserExchangeProviderDataDocument, variables: variables };
}
export const UserExchangesDocument = gql`
  query userExchanges {
    selectedExchange @client
    getUserExchanges {
      ...allExchangesData
    }
  }
  ${AllExchangesDataFragmentDoc}
`;

/**
 * __useUserExchangesQuery__
 *
 * To run a query within a React component, call `useUserExchangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExchangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExchangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserExchangesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserExchangesQuery,
    UserExchangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserExchangesQuery,
    UserExchangesQueryVariables
  >(UserExchangesDocument, options);
}
export function useUserExchangesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserExchangesQuery,
    UserExchangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserExchangesQuery,
    UserExchangesQueryVariables
  >(UserExchangesDocument, options);
}
export type UserExchangesQueryHookResult = ReturnType<
  typeof useUserExchangesQuery
>;
export type UserExchangesLazyQueryHookResult = ReturnType<
  typeof useUserExchangesLazyQuery
>;
export type UserExchangesQueryResult = ApolloReactCommon.QueryResult<
  UserExchangesQuery,
  UserExchangesQueryVariables
>;
export function refetchUserExchangesQuery(
  variables?: UserExchangesQueryVariables
) {
  return { query: UserExchangesDocument, variables: variables };
}
export const UserExchangesStartDatesDocument = gql`
  query userExchangesStartDates {
    getUserExchanges {
      exchangeID
      exchangeCode
      subscriptionStartDate
    }
  }
`;

/**
 * __useUserExchangesStartDatesQuery__
 *
 * To run a query within a React component, call `useUserExchangesStartDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserExchangesStartDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserExchangesStartDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserExchangesStartDatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserExchangesStartDatesQuery,
    UserExchangesStartDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserExchangesStartDatesQuery,
    UserExchangesStartDatesQueryVariables
  >(UserExchangesStartDatesDocument, options);
}
export function useUserExchangesStartDatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserExchangesStartDatesQuery,
    UserExchangesStartDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserExchangesStartDatesQuery,
    UserExchangesStartDatesQueryVariables
  >(UserExchangesStartDatesDocument, options);
}
export type UserExchangesStartDatesQueryHookResult = ReturnType<
  typeof useUserExchangesStartDatesQuery
>;
export type UserExchangesStartDatesLazyQueryHookResult = ReturnType<
  typeof useUserExchangesStartDatesLazyQuery
>;
export type UserExchangesStartDatesQueryResult = ApolloReactCommon.QueryResult<
  UserExchangesStartDatesQuery,
  UserExchangesStartDatesQueryVariables
>;
export function refetchUserExchangesStartDatesQuery(
  variables?: UserExchangesStartDatesQueryVariables
) {
  return { query: UserExchangesStartDatesDocument, variables: variables };
}
export const SelectedExchangeDocument = gql`
  query selectedExchange {
    selectedExchange @client
  }
`;

/**
 * __useSelectedExchangeQuery__
 *
 * To run a query within a React component, call `useSelectedExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedExchangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectedExchangeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectedExchangeQuery,
    SelectedExchangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SelectedExchangeQuery,
    SelectedExchangeQueryVariables
  >(SelectedExchangeDocument, options);
}
export function useSelectedExchangeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectedExchangeQuery,
    SelectedExchangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SelectedExchangeQuery,
    SelectedExchangeQueryVariables
  >(SelectedExchangeDocument, options);
}
export type SelectedExchangeQueryHookResult = ReturnType<
  typeof useSelectedExchangeQuery
>;
export type SelectedExchangeLazyQueryHookResult = ReturnType<
  typeof useSelectedExchangeLazyQuery
>;
export type SelectedExchangeQueryResult = ApolloReactCommon.QueryResult<
  SelectedExchangeQuery,
  SelectedExchangeQueryVariables
>;
export function refetchSelectedExchangeQuery(
  variables?: SelectedExchangeQueryVariables
) {
  return { query: SelectedExchangeDocument, variables: variables };
}
export const ExchangesSubscriptionInitiatedDatesDocument = gql`
  query exchangesSubscriptionInitiatedDates {
    getUserExchanges {
      exchangeID
      subscriptionInitiatedDate
    }
  }
`;

/**
 * __useExchangesSubscriptionInitiatedDatesQuery__
 *
 * To run a query within a React component, call `useExchangesSubscriptionInitiatedDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangesSubscriptionInitiatedDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangesSubscriptionInitiatedDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangesSubscriptionInitiatedDatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ExchangesSubscriptionInitiatedDatesQuery,
    ExchangesSubscriptionInitiatedDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ExchangesSubscriptionInitiatedDatesQuery,
    ExchangesSubscriptionInitiatedDatesQueryVariables
  >(ExchangesSubscriptionInitiatedDatesDocument, options);
}
export function useExchangesSubscriptionInitiatedDatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExchangesSubscriptionInitiatedDatesQuery,
    ExchangesSubscriptionInitiatedDatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ExchangesSubscriptionInitiatedDatesQuery,
    ExchangesSubscriptionInitiatedDatesQueryVariables
  >(ExchangesSubscriptionInitiatedDatesDocument, options);
}
export type ExchangesSubscriptionInitiatedDatesQueryHookResult = ReturnType<
  typeof useExchangesSubscriptionInitiatedDatesQuery
>;
export type ExchangesSubscriptionInitiatedDatesLazyQueryHookResult = ReturnType<
  typeof useExchangesSubscriptionInitiatedDatesLazyQuery
>;
export type ExchangesSubscriptionInitiatedDatesQueryResult =
  ApolloReactCommon.QueryResult<
    ExchangesSubscriptionInitiatedDatesQuery,
    ExchangesSubscriptionInitiatedDatesQueryVariables
  >;
export function refetchExchangesSubscriptionInitiatedDatesQuery(
  variables?: ExchangesSubscriptionInitiatedDatesQueryVariables
) {
  return {
    query: ExchangesSubscriptionInitiatedDatesDocument,
    variables: variables,
  };
}
export const AddExchangeKeysDocument = gql`
  mutation addExchangeKeys(
    $exchangeId: Int!
    $key: String!
    $secretKey: String!
  ) {
    addKeys(exchangeId: $exchangeId, keys: { key: $key, secret: $secretKey }) {
      exchangeID
      aumBelowMinimum
      moneyUnderManagement
      nextRebalanceDate
      wizardStatus
      subscriptionStartDate
      subscriptionInitiatedDate
      onlyFiatInPortfolio
    }
  }
`;
export type AddExchangeKeysMutationFn = ApolloReactCommon.MutationFunction<
  AddExchangeKeysMutation,
  AddExchangeKeysMutationVariables
>;

/**
 * __useAddExchangeKeysMutation__
 *
 * To run a mutation, you first call `useAddExchangeKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExchangeKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExchangeKeysMutation, { data, loading, error }] = useAddExchangeKeysMutation({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *      key: // value for 'key'
 *      secretKey: // value for 'secretKey'
 *   },
 * });
 */
export function useAddExchangeKeysMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddExchangeKeysMutation,
    AddExchangeKeysMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddExchangeKeysMutation,
    AddExchangeKeysMutationVariables
  >(AddExchangeKeysDocument, options);
}
export type AddExchangeKeysMutationHookResult = ReturnType<
  typeof useAddExchangeKeysMutation
>;
export type AddExchangeKeysMutationResult =
  ApolloReactCommon.MutationResult<AddExchangeKeysMutation>;
export type AddExchangeKeysMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    AddExchangeKeysMutation,
    AddExchangeKeysMutationVariables
  >;
export const StartSubscriptionDocument = gql`
  mutation startSubscription($exchangeId: Int!) {
    startSubscription(exchangeId: $exchangeId)
  }
`;
export type StartSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  StartSubscriptionMutation,
  StartSubscriptionMutationVariables
>;

/**
 * __useStartSubscriptionMutation__
 *
 * To run a mutation, you first call `useStartSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSubscriptionMutation, { data, loading, error }] = useStartSubscriptionMutation({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useStartSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartSubscriptionMutation,
    StartSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    StartSubscriptionMutation,
    StartSubscriptionMutationVariables
  >(StartSubscriptionDocument, options);
}
export type StartSubscriptionMutationHookResult = ReturnType<
  typeof useStartSubscriptionMutation
>;
export type StartSubscriptionMutationResult =
  ApolloReactCommon.MutationResult<StartSubscriptionMutation>;
export type StartSubscriptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    StartSubscriptionMutation,
    StartSubscriptionMutationVariables
  >;
export const DisableSubscriptionDocument = gql`
  mutation disableSubscription($exchangeId: Int!) {
    cancelSubscription(exchangeId: $exchangeId) {
      exchangeID
    }
  }
`;
export type DisableSubscriptionMutationFn = ApolloReactCommon.MutationFunction<
  DisableSubscriptionMutation,
  DisableSubscriptionMutationVariables
>;

/**
 * __useDisableSubscriptionMutation__
 *
 * To run a mutation, you first call `useDisableSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableSubscriptionMutation, { data, loading, error }] = useDisableSubscriptionMutation({
 *   variables: {
 *      exchangeId: // value for 'exchangeId'
 *   },
 * });
 */
export function useDisableSubscriptionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DisableSubscriptionMutation,
    DisableSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DisableSubscriptionMutation,
    DisableSubscriptionMutationVariables
  >(DisableSubscriptionDocument, options);
}
export type DisableSubscriptionMutationHookResult = ReturnType<
  typeof useDisableSubscriptionMutation
>;
export type DisableSubscriptionMutationResult =
  ApolloReactCommon.MutationResult<DisableSubscriptionMutation>;
export type DisableSubscriptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DisableSubscriptionMutation,
    DisableSubscriptionMutationVariables
  >;
export const InvoicesDocument = gql`
  query invoices {
    getInvoices {
      id
      dueDate
      url
      status
    }
  }
`;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options
  );
}
export function useInvoicesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvoicesQuery,
    InvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(
    InvoicesDocument,
    options
  );
}
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<
  typeof useInvoicesLazyQuery
>;
export type InvoicesQueryResult = ApolloReactCommon.QueryResult<
  InvoicesQuery,
  InvoicesQueryVariables
>;
export function refetchInvoicesQuery(variables?: InvoicesQueryVariables) {
  return { query: InvoicesDocument, variables: variables };
}
export const InvoiceDocument = gql`
  query invoice($id: Int!) {
    getInvoice(invoiceID: $id) {
      id
      dueDate
      cost
      costExVat
      url
      status
    }
  }
`;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    InvoiceQuery,
    InvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options
  );
}
export function useInvoiceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvoiceQuery,
    InvoiceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(
    InvoiceDocument,
    options
  );
}
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = ApolloReactCommon.QueryResult<
  InvoiceQuery,
  InvoiceQueryVariables
>;
export function refetchInvoiceQuery(variables: InvoiceQueryVariables) {
  return { query: InvoiceDocument, variables: variables };
}
export const MainDataDocument = gql`
  query mainData {
    getUser {
      ...initialUserData
    }
    getUserExchanges {
      ...allExchangesData
    }
    getMolliePaymentInfo {
      method
      status
      createdAt
      details
    }
  }
  ${InitialUserDataFragmentDoc}
  ${AllExchangesDataFragmentDoc}
`;

/**
 * __useMainDataQuery__
 *
 * To run a query within a React component, call `useMainDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useMainDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MainDataQuery,
    MainDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<MainDataQuery, MainDataQueryVariables>(
    MainDataDocument,
    options
  );
}
export function useMainDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MainDataQuery,
    MainDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<MainDataQuery, MainDataQueryVariables>(
    MainDataDocument,
    options
  );
}
export type MainDataQueryHookResult = ReturnType<typeof useMainDataQuery>;
export type MainDataLazyQueryHookResult = ReturnType<
  typeof useMainDataLazyQuery
>;
export type MainDataQueryResult = ApolloReactCommon.QueryResult<
  MainDataQuery,
  MainDataQueryVariables
>;
export function refetchMainDataQuery(variables?: MainDataQueryVariables) {
  return { query: MainDataDocument, variables: variables };
}
export const PaymentProviderDataDocument = gql`
  query paymentProviderData {
    getUser {
      id
      isAdmin
      trialEndDate
      mollieStatus
      hasActiveSubscription
      hasUnpaidInvoices
    }
  }
`;

/**
 * __usePaymentProviderDataQuery__
 *
 * To run a query within a React component, call `usePaymentProviderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentProviderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentProviderDataQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentProviderDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PaymentProviderDataQuery,
    PaymentProviderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PaymentProviderDataQuery,
    PaymentProviderDataQueryVariables
  >(PaymentProviderDataDocument, options);
}
export function usePaymentProviderDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaymentProviderDataQuery,
    PaymentProviderDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PaymentProviderDataQuery,
    PaymentProviderDataQueryVariables
  >(PaymentProviderDataDocument, options);
}
export type PaymentProviderDataQueryHookResult = ReturnType<
  typeof usePaymentProviderDataQuery
>;
export type PaymentProviderDataLazyQueryHookResult = ReturnType<
  typeof usePaymentProviderDataLazyQuery
>;
export type PaymentProviderDataQueryResult = ApolloReactCommon.QueryResult<
  PaymentProviderDataQuery,
  PaymentProviderDataQueryVariables
>;
export function refetchPaymentProviderDataQuery(
  variables?: PaymentProviderDataQueryVariables
) {
  return { query: PaymentProviderDataDocument, variables: variables };
}
export const MolliePaymentInfoDocument = gql`
  query molliePaymentInfo {
    getMolliePaymentInfo {
      method
      status
      createdAt
      details
    }
  }
`;

/**
 * __useMolliePaymentInfoQuery__
 *
 * To run a query within a React component, call `useMolliePaymentInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMolliePaymentInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMolliePaymentInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useMolliePaymentInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MolliePaymentInfoQuery,
    MolliePaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    MolliePaymentInfoQuery,
    MolliePaymentInfoQueryVariables
  >(MolliePaymentInfoDocument, options);
}
export function useMolliePaymentInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MolliePaymentInfoQuery,
    MolliePaymentInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    MolliePaymentInfoQuery,
    MolliePaymentInfoQueryVariables
  >(MolliePaymentInfoDocument, options);
}
export type MolliePaymentInfoQueryHookResult = ReturnType<
  typeof useMolliePaymentInfoQuery
>;
export type MolliePaymentInfoLazyQueryHookResult = ReturnType<
  typeof useMolliePaymentInfoLazyQuery
>;
export type MolliePaymentInfoQueryResult = ApolloReactCommon.QueryResult<
  MolliePaymentInfoQuery,
  MolliePaymentInfoQueryVariables
>;
export function refetchMolliePaymentInfoQuery(
  variables?: MolliePaymentInfoQueryVariables
) {
  return { query: MolliePaymentInfoDocument, variables: variables };
}
export const GetMollieTransitPaymentLinkDocument = gql`
  query getMollieTransitPaymentLink($token: String!, $locale: String) {
    getMollieTransitPaymentLink(token: $token, locale: $locale) {
      checkoutURL
    }
  }
`;

/**
 * __useGetMollieTransitPaymentLinkQuery__
 *
 * To run a query within a React component, call `useGetMollieTransitPaymentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMollieTransitPaymentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMollieTransitPaymentLinkQuery({
 *   variables: {
 *      token: // value for 'token'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetMollieTransitPaymentLinkQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetMollieTransitPaymentLinkQuery,
    GetMollieTransitPaymentLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetMollieTransitPaymentLinkQuery,
    GetMollieTransitPaymentLinkQueryVariables
  >(GetMollieTransitPaymentLinkDocument, options);
}
export function useGetMollieTransitPaymentLinkLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMollieTransitPaymentLinkQuery,
    GetMollieTransitPaymentLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetMollieTransitPaymentLinkQuery,
    GetMollieTransitPaymentLinkQueryVariables
  >(GetMollieTransitPaymentLinkDocument, options);
}
export type GetMollieTransitPaymentLinkQueryHookResult = ReturnType<
  typeof useGetMollieTransitPaymentLinkQuery
>;
export type GetMollieTransitPaymentLinkLazyQueryHookResult = ReturnType<
  typeof useGetMollieTransitPaymentLinkLazyQuery
>;
export type GetMollieTransitPaymentLinkQueryResult =
  ApolloReactCommon.QueryResult<
    GetMollieTransitPaymentLinkQuery,
    GetMollieTransitPaymentLinkQueryVariables
  >;
export function refetchGetMollieTransitPaymentLinkQuery(
  variables: GetMollieTransitPaymentLinkQueryVariables
) {
  return { query: GetMollieTransitPaymentLinkDocument, variables: variables };
}
export const CreateNewMollieClientDocument = gql`
  mutation createNewMollieClient($locale: String) {
    newMollieClient(locale: $locale) {
      checkoutURL
    }
  }
`;
export type CreateNewMollieClientMutationFn =
  ApolloReactCommon.MutationFunction<
    CreateNewMollieClientMutation,
    CreateNewMollieClientMutationVariables
  >;

/**
 * __useCreateNewMollieClientMutation__
 *
 * To run a mutation, you first call `useCreateNewMollieClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewMollieClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewMollieClientMutation, { data, loading, error }] = useCreateNewMollieClientMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCreateNewMollieClientMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateNewMollieClientMutation,
    CreateNewMollieClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateNewMollieClientMutation,
    CreateNewMollieClientMutationVariables
  >(CreateNewMollieClientDocument, options);
}
export type CreateNewMollieClientMutationHookResult = ReturnType<
  typeof useCreateNewMollieClientMutation
>;
export type CreateNewMollieClientMutationResult =
  ApolloReactCommon.MutationResult<CreateNewMollieClientMutation>;
export type CreateNewMollieClientMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    CreateNewMollieClientMutation,
    CreateNewMollieClientMutationVariables
  >;
export const DirectMolliePaymentDocument = gql`
  mutation directMolliePayment($id: Int!) {
    directMolliePayment(invoiceId: $id) {
      checkoutURL
    }
  }
`;
export type DirectMolliePaymentMutationFn = ApolloReactCommon.MutationFunction<
  DirectMolliePaymentMutation,
  DirectMolliePaymentMutationVariables
>;

/**
 * __useDirectMolliePaymentMutation__
 *
 * To run a mutation, you first call `useDirectMolliePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDirectMolliePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [directMolliePaymentMutation, { data, loading, error }] = useDirectMolliePaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDirectMolliePaymentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DirectMolliePaymentMutation,
    DirectMolliePaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DirectMolliePaymentMutation,
    DirectMolliePaymentMutationVariables
  >(DirectMolliePaymentDocument, options);
}
export type DirectMolliePaymentMutationHookResult = ReturnType<
  typeof useDirectMolliePaymentMutation
>;
export type DirectMolliePaymentMutationResult =
  ApolloReactCommon.MutationResult<DirectMolliePaymentMutation>;
export type DirectMolliePaymentMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    DirectMolliePaymentMutation,
    DirectMolliePaymentMutationVariables
  >;
export const UserIdDocument = gql`
  query userId {
    getUser {
      id
    }
  }
`;

/**
 * __useUserIdQuery__
 *
 * To run a query within a React component, call `useUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserIdQuery,
    UserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserIdQuery, UserIdQueryVariables>(
    UserIdDocument,
    options
  );
}
export function useUserIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserIdQuery,
    UserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<UserIdQuery, UserIdQueryVariables>(
    UserIdDocument,
    options
  );
}
export type UserIdQueryHookResult = ReturnType<typeof useUserIdQuery>;
export type UserIdLazyQueryHookResult = ReturnType<typeof useUserIdLazyQuery>;
export type UserIdQueryResult = ApolloReactCommon.QueryResult<
  UserIdQuery,
  UserIdQueryVariables
>;
export function refetchUserIdQuery(variables?: UserIdQueryVariables) {
  return { query: UserIdDocument, variables: variables };
}
export const UserCompanyNameDocument = gql`
  query userCompanyName {
    getUser {
      id
      companyName
    }
  }
`;

/**
 * __useUserCompanyNameQuery__
 *
 * To run a query within a React component, call `useUserCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserCompanyNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserCompanyNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserCompanyNameQuery,
    UserCompanyNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserCompanyNameQuery,
    UserCompanyNameQueryVariables
  >(UserCompanyNameDocument, options);
}
export function useUserCompanyNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserCompanyNameQuery,
    UserCompanyNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserCompanyNameQuery,
    UserCompanyNameQueryVariables
  >(UserCompanyNameDocument, options);
}
export type UserCompanyNameQueryHookResult = ReturnType<
  typeof useUserCompanyNameQuery
>;
export type UserCompanyNameLazyQueryHookResult = ReturnType<
  typeof useUserCompanyNameLazyQuery
>;
export type UserCompanyNameQueryResult = ApolloReactCommon.QueryResult<
  UserCompanyNameQuery,
  UserCompanyNameQueryVariables
>;
export function refetchUserCompanyNameQuery(
  variables?: UserCompanyNameQueryVariables
) {
  return { query: UserCompanyNameDocument, variables: variables };
}
export const UserAuthIdentitiesDocument = gql`
  query userAuthIdentities {
    getUser {
      id
      authIdentities {
        id
        provider
        uid
      }
    }
  }
`;

/**
 * __useUserAuthIdentitiesQuery__
 *
 * To run a query within a React component, call `useUserAuthIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAuthIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAuthIdentitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAuthIdentitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserAuthIdentitiesQuery,
    UserAuthIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserAuthIdentitiesQuery,
    UserAuthIdentitiesQueryVariables
  >(UserAuthIdentitiesDocument, options);
}
export function useUserAuthIdentitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserAuthIdentitiesQuery,
    UserAuthIdentitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserAuthIdentitiesQuery,
    UserAuthIdentitiesQueryVariables
  >(UserAuthIdentitiesDocument, options);
}
export type UserAuthIdentitiesQueryHookResult = ReturnType<
  typeof useUserAuthIdentitiesQuery
>;
export type UserAuthIdentitiesLazyQueryHookResult = ReturnType<
  typeof useUserAuthIdentitiesLazyQuery
>;
export type UserAuthIdentitiesQueryResult = ApolloReactCommon.QueryResult<
  UserAuthIdentitiesQuery,
  UserAuthIdentitiesQueryVariables
>;
export function refetchUserAuthIdentitiesQuery(
  variables?: UserAuthIdentitiesQueryVariables
) {
  return { query: UserAuthIdentitiesDocument, variables: variables };
}
export const UserHasActiveSubscriptionDocument = gql`
  query userHasActiveSubscription {
    getUser {
      id
      hasActiveSubscription
    }
  }
`;

/**
 * __useUserHasActiveSubscriptionQuery__
 *
 * To run a query within a React component, call `useUserHasActiveSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasActiveSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasActiveSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHasActiveSubscriptionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserHasActiveSubscriptionQuery,
    UserHasActiveSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserHasActiveSubscriptionQuery,
    UserHasActiveSubscriptionQueryVariables
  >(UserHasActiveSubscriptionDocument, options);
}
export function useUserHasActiveSubscriptionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserHasActiveSubscriptionQuery,
    UserHasActiveSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserHasActiveSubscriptionQuery,
    UserHasActiveSubscriptionQueryVariables
  >(UserHasActiveSubscriptionDocument, options);
}
export type UserHasActiveSubscriptionQueryHookResult = ReturnType<
  typeof useUserHasActiveSubscriptionQuery
>;
export type UserHasActiveSubscriptionLazyQueryHookResult = ReturnType<
  typeof useUserHasActiveSubscriptionLazyQuery
>;
export type UserHasActiveSubscriptionQueryResult =
  ApolloReactCommon.QueryResult<
    UserHasActiveSubscriptionQuery,
    UserHasActiveSubscriptionQueryVariables
  >;
export function refetchUserHasActiveSubscriptionQuery(
  variables?: UserHasActiveSubscriptionQueryVariables
) {
  return { query: UserHasActiveSubscriptionDocument, variables: variables };
}
export const User2FaSectionDataDocument = gql`
  query user2FASectionData {
    getUser {
      id
      authIdentities {
        id
        provider
      }
      totpEnabled
    }
  }
`;

/**
 * __useUser2FaSectionDataQuery__
 *
 * To run a query within a React component, call `useUser2FaSectionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUser2FaSectionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUser2FaSectionDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUser2FaSectionDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    User2FaSectionDataQuery,
    User2FaSectionDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    User2FaSectionDataQuery,
    User2FaSectionDataQueryVariables
  >(User2FaSectionDataDocument, options);
}
export function useUser2FaSectionDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    User2FaSectionDataQuery,
    User2FaSectionDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    User2FaSectionDataQuery,
    User2FaSectionDataQueryVariables
  >(User2FaSectionDataDocument, options);
}
export type User2FaSectionDataQueryHookResult = ReturnType<
  typeof useUser2FaSectionDataQuery
>;
export type User2FaSectionDataLazyQueryHookResult = ReturnType<
  typeof useUser2FaSectionDataLazyQuery
>;
export type User2FaSectionDataQueryResult = ApolloReactCommon.QueryResult<
  User2FaSectionDataQuery,
  User2FaSectionDataQueryVariables
>;
export function refetchUser2FaSectionDataQuery(
  variables?: User2FaSectionDataQueryVariables
) {
  return { query: User2FaSectionDataDocument, variables: variables };
}
export const GetUserTotpStatusDocument = gql`
  query getUserTOTPStatus {
    getUser {
      id
      totpEnabled
    }
  }
`;

/**
 * __useGetUserTotpStatusQuery__
 *
 * To run a query within a React component, call `useGetUserTotpStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTotpStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTotpStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserTotpStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserTotpStatusQuery,
    GetUserTotpStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetUserTotpStatusQuery,
    GetUserTotpStatusQueryVariables
  >(GetUserTotpStatusDocument, options);
}
export function useGetUserTotpStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserTotpStatusQuery,
    GetUserTotpStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetUserTotpStatusQuery,
    GetUserTotpStatusQueryVariables
  >(GetUserTotpStatusDocument, options);
}
export type GetUserTotpStatusQueryHookResult = ReturnType<
  typeof useGetUserTotpStatusQuery
>;
export type GetUserTotpStatusLazyQueryHookResult = ReturnType<
  typeof useGetUserTotpStatusLazyQuery
>;
export type GetUserTotpStatusQueryResult = ApolloReactCommon.QueryResult<
  GetUserTotpStatusQuery,
  GetUserTotpStatusQueryVariables
>;
export function refetchGetUserTotpStatusQuery(
  variables?: GetUserTotpStatusQueryVariables
) {
  return { query: GetUserTotpStatusDocument, variables: variables };
}
export const UserAdminStatusDocument = gql`
  query userAdminStatus {
    getUser {
      ...userAdminStatusData
    }
  }
  ${UserAdminStatusDataFragmentDoc}
`;

/**
 * __useUserAdminStatusQuery__
 *
 * To run a query within a React component, call `useUserAdminStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAdminStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAdminStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAdminStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserAdminStatusQuery,
    UserAdminStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserAdminStatusQuery,
    UserAdminStatusQueryVariables
  >(UserAdminStatusDocument, options);
}
export function useUserAdminStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserAdminStatusQuery,
    UserAdminStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserAdminStatusQuery,
    UserAdminStatusQueryVariables
  >(UserAdminStatusDocument, options);
}
export type UserAdminStatusQueryHookResult = ReturnType<
  typeof useUserAdminStatusQuery
>;
export type UserAdminStatusLazyQueryHookResult = ReturnType<
  typeof useUserAdminStatusLazyQuery
>;
export type UserAdminStatusQueryResult = ApolloReactCommon.QueryResult<
  UserAdminStatusQuery,
  UserAdminStatusQueryVariables
>;
export function refetchUserAdminStatusQuery(
  variables?: UserAdminStatusQueryVariables
) {
  return { query: UserAdminStatusDocument, variables: variables };
}
export const UserProfileDocument = gql`
  query userProfile {
    getUser {
      id
      firstName
      lastName
      companyName
      vatNumber
      phone
      address
      houseNumber
      zip
      city
      country
      amountToInvest
    }
  }
`;

/**
 * __useUserProfileQuery__
 *
 * To run a query within a React component, call `useUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<UserProfileQuery, UserProfileQueryVariables>(
    UserProfileDocument,
    options
  );
}
export function useUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserProfileQuery,
    UserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserProfileQuery,
    UserProfileQueryVariables
  >(UserProfileDocument, options);
}
export type UserProfileQueryHookResult = ReturnType<typeof useUserProfileQuery>;
export type UserProfileLazyQueryHookResult = ReturnType<
  typeof useUserProfileLazyQuery
>;
export type UserProfileQueryResult = ApolloReactCommon.QueryResult<
  UserProfileQuery,
  UserProfileQueryVariables
>;
export function refetchUserProfileQuery(variables?: UserProfileQueryVariables) {
  return { query: UserProfileDocument, variables: variables };
}
export const ControllingUserDocument = gql`
  query controllingUser {
    getUser {
      id
      controlledByUser {
        id
      }
    }
  }
`;

/**
 * __useControllingUserQuery__
 *
 * To run a query within a React component, call `useControllingUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useControllingUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControllingUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useControllingUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ControllingUserQuery,
    ControllingUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ControllingUserQuery,
    ControllingUserQueryVariables
  >(ControllingUserDocument, options);
}
export function useControllingUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ControllingUserQuery,
    ControllingUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ControllingUserQuery,
    ControllingUserQueryVariables
  >(ControllingUserDocument, options);
}
export type ControllingUserQueryHookResult = ReturnType<
  typeof useControllingUserQuery
>;
export type ControllingUserLazyQueryHookResult = ReturnType<
  typeof useControllingUserLazyQuery
>;
export type ControllingUserQueryResult = ApolloReactCommon.QueryResult<
  ControllingUserQuery,
  ControllingUserQueryVariables
>;
export function refetchControllingUserQuery(
  variables?: ControllingUserQueryVariables
) {
  return { query: ControllingUserDocument, variables: variables };
}
export const UserSubscriptionReferralCodeAndDiscountDocument = gql`
  query userSubscriptionReferralCodeAndDiscount {
    getUser {
      id
      personalReferalCode
      pendingDiscount
    }
  }
`;

/**
 * __useUserSubscriptionReferralCodeAndDiscountQuery__
 *
 * To run a query within a React component, call `useUserSubscriptionReferralCodeAndDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSubscriptionReferralCodeAndDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSubscriptionReferralCodeAndDiscountQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSubscriptionReferralCodeAndDiscountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserSubscriptionReferralCodeAndDiscountQuery,
    UserSubscriptionReferralCodeAndDiscountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserSubscriptionReferralCodeAndDiscountQuery,
    UserSubscriptionReferralCodeAndDiscountQueryVariables
  >(UserSubscriptionReferralCodeAndDiscountDocument, options);
}
export function useUserSubscriptionReferralCodeAndDiscountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserSubscriptionReferralCodeAndDiscountQuery,
    UserSubscriptionReferralCodeAndDiscountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserSubscriptionReferralCodeAndDiscountQuery,
    UserSubscriptionReferralCodeAndDiscountQueryVariables
  >(UserSubscriptionReferralCodeAndDiscountDocument, options);
}
export type UserSubscriptionReferralCodeAndDiscountQueryHookResult = ReturnType<
  typeof useUserSubscriptionReferralCodeAndDiscountQuery
>;
export type UserSubscriptionReferralCodeAndDiscountLazyQueryHookResult =
  ReturnType<typeof useUserSubscriptionReferralCodeAndDiscountLazyQuery>;
export type UserSubscriptionReferralCodeAndDiscountQueryResult =
  ApolloReactCommon.QueryResult<
    UserSubscriptionReferralCodeAndDiscountQuery,
    UserSubscriptionReferralCodeAndDiscountQueryVariables
  >;
export function refetchUserSubscriptionReferralCodeAndDiscountQuery(
  variables?: UserSubscriptionReferralCodeAndDiscountQueryVariables
) {
  return {
    query: UserSubscriptionReferralCodeAndDiscountDocument,
    variables: variables,
  };
}
export const UserLockedCurrenciesDocument = gql`
  query userLockedCurrencies {
    getUser {
      id
      currenciesLocked
    }
  }
`;

/**
 * __useUserLockedCurrenciesQuery__
 *
 * To run a query within a React component, call `useUserLockedCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLockedCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLockedCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLockedCurrenciesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserLockedCurrenciesQuery,
    UserLockedCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserLockedCurrenciesQuery,
    UserLockedCurrenciesQueryVariables
  >(UserLockedCurrenciesDocument, options);
}
export function useUserLockedCurrenciesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserLockedCurrenciesQuery,
    UserLockedCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserLockedCurrenciesQuery,
    UserLockedCurrenciesQueryVariables
  >(UserLockedCurrenciesDocument, options);
}
export type UserLockedCurrenciesQueryHookResult = ReturnType<
  typeof useUserLockedCurrenciesQuery
>;
export type UserLockedCurrenciesLazyQueryHookResult = ReturnType<
  typeof useUserLockedCurrenciesLazyQuery
>;
export type UserLockedCurrenciesQueryResult = ApolloReactCommon.QueryResult<
  UserLockedCurrenciesQuery,
  UserLockedCurrenciesQueryVariables
>;
export function refetchUserLockedCurrenciesQuery(
  variables?: UserLockedCurrenciesQueryVariables
) {
  return { query: UserLockedCurrenciesDocument, variables: variables };
}
export const UserTrialEndDateDocument = gql`
  query userTrialEndDate {
    getUser {
      id
      trialEndDate
    }
  }
`;

/**
 * __useUserTrialEndDateQuery__
 *
 * To run a query within a React component, call `useUserTrialEndDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserTrialEndDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTrialEndDateQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserTrialEndDateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserTrialEndDateQuery,
    UserTrialEndDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserTrialEndDateQuery,
    UserTrialEndDateQueryVariables
  >(UserTrialEndDateDocument, options);
}
export function useUserTrialEndDateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserTrialEndDateQuery,
    UserTrialEndDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserTrialEndDateQuery,
    UserTrialEndDateQueryVariables
  >(UserTrialEndDateDocument, options);
}
export type UserTrialEndDateQueryHookResult = ReturnType<
  typeof useUserTrialEndDateQuery
>;
export type UserTrialEndDateLazyQueryHookResult = ReturnType<
  typeof useUserTrialEndDateLazyQuery
>;
export type UserTrialEndDateQueryResult = ApolloReactCommon.QueryResult<
  UserTrialEndDateQuery,
  UserTrialEndDateQueryVariables
>;
export function refetchUserTrialEndDateQuery(
  variables?: UserTrialEndDateQueryVariables
) {
  return { query: UserTrialEndDateDocument, variables: variables };
}
export const UserValidProfileDocument = gql`
  query userValidProfile {
    getUser {
      id
      profileValid
    }
  }
`;

/**
 * __useUserValidProfileQuery__
 *
 * To run a query within a React component, call `useUserValidProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserValidProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserValidProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserValidProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserValidProfileQuery,
    UserValidProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserValidProfileQuery,
    UserValidProfileQueryVariables
  >(UserValidProfileDocument, options);
}
export function useUserValidProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserValidProfileQuery,
    UserValidProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserValidProfileQuery,
    UserValidProfileQueryVariables
  >(UserValidProfileDocument, options);
}
export type UserValidProfileQueryHookResult = ReturnType<
  typeof useUserValidProfileQuery
>;
export type UserValidProfileLazyQueryHookResult = ReturnType<
  typeof useUserValidProfileLazyQuery
>;
export type UserValidProfileQueryResult = ApolloReactCommon.QueryResult<
  UserValidProfileQuery,
  UserValidProfileQueryVariables
>;
export function refetchUserValidProfileQuery(
  variables?: UserValidProfileQueryVariables
) {
  return { query: UserValidProfileDocument, variables: variables };
}
export const UserChatBoxDataDocument = gql`
  query userChatBoxData {
    getUser {
      id
      authIdentities {
        uid
        provider
      }
      firstName
      lastName
      phone
    }
  }
`;

/**
 * __useUserChatBoxDataQuery__
 *
 * To run a query within a React component, call `useUserChatBoxDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserChatBoxDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserChatBoxDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserChatBoxDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserChatBoxDataQuery,
    UserChatBoxDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    UserChatBoxDataQuery,
    UserChatBoxDataQueryVariables
  >(UserChatBoxDataDocument, options);
}
export function useUserChatBoxDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserChatBoxDataQuery,
    UserChatBoxDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    UserChatBoxDataQuery,
    UserChatBoxDataQueryVariables
  >(UserChatBoxDataDocument, options);
}
export type UserChatBoxDataQueryHookResult = ReturnType<
  typeof useUserChatBoxDataQuery
>;
export type UserChatBoxDataLazyQueryHookResult = ReturnType<
  typeof useUserChatBoxDataLazyQuery
>;
export type UserChatBoxDataQueryResult = ApolloReactCommon.QueryResult<
  UserChatBoxDataQuery,
  UserChatBoxDataQueryVariables
>;
export function refetchUserChatBoxDataQuery(
  variables?: UserChatBoxDataQueryVariables
) {
  return { query: UserChatBoxDataDocument, variables: variables };
}
export const GenerateQrCodeDocument = gql`
  mutation generateQRCode {
    totpGenerate
  }
`;
export type GenerateQrCodeMutationFn = ApolloReactCommon.MutationFunction<
  GenerateQrCodeMutation,
  GenerateQrCodeMutationVariables
>;

/**
 * __useGenerateQrCodeMutation__
 *
 * To run a mutation, you first call `useGenerateQrCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateQrCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateQrCodeMutation, { data, loading, error }] = useGenerateQrCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateQrCodeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    GenerateQrCodeMutation,
    GenerateQrCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    GenerateQrCodeMutation,
    GenerateQrCodeMutationVariables
  >(GenerateQrCodeDocument, options);
}
export type GenerateQrCodeMutationHookResult = ReturnType<
  typeof useGenerateQrCodeMutation
>;
export type GenerateQrCodeMutationResult =
  ApolloReactCommon.MutationResult<GenerateQrCodeMutation>;
export type GenerateQrCodeMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    GenerateQrCodeMutation,
    GenerateQrCodeMutationVariables
  >;
export const TotpVerifyDocument = gql`
  mutation totpVerify($code: String!) {
    totpVerify(code: $code) {
      token
    }
  }
`;
export type TotpVerifyMutationFn = ApolloReactCommon.MutationFunction<
  TotpVerifyMutation,
  TotpVerifyMutationVariables
>;

/**
 * __useTotpVerifyMutation__
 *
 * To run a mutation, you first call `useTotpVerifyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTotpVerifyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [totpVerifyMutation, { data, loading, error }] = useTotpVerifyMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTotpVerifyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TotpVerifyMutation,
    TotpVerifyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TotpVerifyMutation,
    TotpVerifyMutationVariables
  >(TotpVerifyDocument, options);
}
export type TotpVerifyMutationHookResult = ReturnType<
  typeof useTotpVerifyMutation
>;
export type TotpVerifyMutationResult =
  ApolloReactCommon.MutationResult<TotpVerifyMutation>;
export type TotpVerifyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TotpVerifyMutation,
  TotpVerifyMutationVariables
>;
export const TotpSetupDocument = gql`
  mutation totpSetup($code: String!) {
    totpSetup(code: $code) {
      backupCodes
    }
  }
`;
export type TotpSetupMutationFn = ApolloReactCommon.MutationFunction<
  TotpSetupMutation,
  TotpSetupMutationVariables
>;

/**
 * __useTotpSetupMutation__
 *
 * To run a mutation, you first call `useTotpSetupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTotpSetupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [totpSetupMutation, { data, loading, error }] = useTotpSetupMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTotpSetupMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TotpSetupMutation,
    TotpSetupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TotpSetupMutation,
    TotpSetupMutationVariables
  >(TotpSetupDocument, options);
}
export type TotpSetupMutationHookResult = ReturnType<
  typeof useTotpSetupMutation
>;
export type TotpSetupMutationResult =
  ApolloReactCommon.MutationResult<TotpSetupMutation>;
export type TotpSetupMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TotpSetupMutation,
  TotpSetupMutationVariables
>;
export const TotpDisableDocument = gql`
  mutation totpDisable($code: String!) {
    totpDisable(code: $code) {
      success
    }
  }
`;
export type TotpDisableMutationFn = ApolloReactCommon.MutationFunction<
  TotpDisableMutation,
  TotpDisableMutationVariables
>;

/**
 * __useTotpDisableMutation__
 *
 * To run a mutation, you first call `useTotpDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTotpDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [totpDisableMutation, { data, loading, error }] = useTotpDisableMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTotpDisableMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TotpDisableMutation,
    TotpDisableMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TotpDisableMutation,
    TotpDisableMutationVariables
  >(TotpDisableDocument, options);
}
export type TotpDisableMutationHookResult = ReturnType<
  typeof useTotpDisableMutation
>;
export type TotpDisableMutationResult =
  ApolloReactCommon.MutationResult<TotpDisableMutation>;
export type TotpDisableMutationOptions = ApolloReactCommon.BaseMutationOptions<
  TotpDisableMutation,
  TotpDisableMutationVariables
>;
export const ResendVerificationEmailDocument = gql`
  mutation resendVerificationEmail($id: Int) {
    resendVerificationEmail(authIdentityId: $id)
  }
`;
export type ResendVerificationEmailMutationFn =
  ApolloReactCommon.MutationFunction<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >;

/**
 * __useResendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useResendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerificationEmailMutation, { data, loading, error }] = useResendVerificationEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendVerificationEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >(ResendVerificationEmailDocument, options);
}
export type ResendVerificationEmailMutationHookResult = ReturnType<
  typeof useResendVerificationEmailMutation
>;
export type ResendVerificationEmailMutationResult =
  ApolloReactCommon.MutationResult<ResendVerificationEmailMutation>;
export type ResendVerificationEmailMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ResendVerificationEmailMutation,
    ResendVerificationEmailMutationVariables
  >;
export const EditUserProfileDocument = gql`
  mutation editUserProfile(
    $firstName: String
    $lastName: String
    $companyName: String
    $vatNumber: String
    $address: String
    $city: String
    $phone: String
    $houseNumber: String
    $zip: String
    $country: String
    $amountToInvest: Int
    $referralCode: String
    $olderThan18AndTermsAccepted: Boolean
    $sendMeNews: Boolean
    $notUsPerson: Boolean
  ) {
    editProfile(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      vatNumber: $vatNumber
      address: $address
      city: $city
      phone: $phone
      houseNumber: $houseNumber
      zip: $zip
      country: $country
      amountToInvest: $amountToInvest
      sharedReferralCode: $referralCode
      olderThan18AndTermsAccepted: $olderThan18AndTermsAccepted
      sendMeNews: $sendMeNews
      notUsPerson: $notUsPerson
    ) {
      id
      firstName
      lastName
      companyName
      vatNumber
      address
      city
      phone
      houseNumber
      zip
      country
      amountToInvest
      profileValid
    }
  }
`;
export type EditUserProfileMutationFn = ApolloReactCommon.MutationFunction<
  EditUserProfileMutation,
  EditUserProfileMutationVariables
>;

/**
 * __useEditUserProfileMutation__
 *
 * To run a mutation, you first call `useEditUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserProfileMutation, { data, loading, error }] = useEditUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      companyName: // value for 'companyName'
 *      vatNumber: // value for 'vatNumber'
 *      address: // value for 'address'
 *      city: // value for 'city'
 *      phone: // value for 'phone'
 *      houseNumber: // value for 'houseNumber'
 *      zip: // value for 'zip'
 *      country: // value for 'country'
 *      amountToInvest: // value for 'amountToInvest'
 *      referralCode: // value for 'referralCode'
 *      olderThan18AndTermsAccepted: // value for 'olderThan18AndTermsAccepted'
 *      sendMeNews: // value for 'sendMeNews'
 *      notUsPerson: // value for 'notUsPerson'
 *   },
 * });
 */
export function useEditUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditUserProfileMutation,
    EditUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditUserProfileMutation,
    EditUserProfileMutationVariables
  >(EditUserProfileDocument, options);
}
export type EditUserProfileMutationHookResult = ReturnType<
  typeof useEditUserProfileMutation
>;
export type EditUserProfileMutationResult =
  ApolloReactCommon.MutationResult<EditUserProfileMutation>;
export type EditUserProfileMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    EditUserProfileMutation,
    EditUserProfileMutationVariables
  >;
export const ChangeUserEmailDocument = gql`
  mutation changeUserEmail($email: String, $currentPassword: String!) {
    changeCredentials(email: $email, currentPassword: $currentPassword) {
      id
    }
  }
`;
export type ChangeUserEmailMutationFn = ApolloReactCommon.MutationFunction<
  ChangeUserEmailMutation,
  ChangeUserEmailMutationVariables
>;

/**
 * __useChangeUserEmailMutation__
 *
 * To run a mutation, you first call `useChangeUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserEmailMutation, { data, loading, error }] = useChangeUserEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangeUserEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeUserEmailMutation,
    ChangeUserEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeUserEmailMutation,
    ChangeUserEmailMutationVariables
  >(ChangeUserEmailDocument, options);
}
export type ChangeUserEmailMutationHookResult = ReturnType<
  typeof useChangeUserEmailMutation
>;
export type ChangeUserEmailMutationResult =
  ApolloReactCommon.MutationResult<ChangeUserEmailMutation>;
export type ChangeUserEmailMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ChangeUserEmailMutation,
    ChangeUserEmailMutationVariables
  >;
export const ChangeUserPasswordDocument = gql`
  mutation changeUserPassword($currentPassword: String!, $password: String) {
    changeCredentials(currentPassword: $currentPassword, password: $password) {
      id
    }
  }
`;
export type ChangeUserPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >(ChangeUserPasswordDocument, options);
}
export type ChangeUserPasswordMutationHookResult = ReturnType<
  typeof useChangeUserPasswordMutation
>;
export type ChangeUserPasswordMutationResult =
  ApolloReactCommon.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ChangeUserPasswordMutation,
    ChangeUserPasswordMutationVariables
  >;
export const ResetPasswordDocument = gql`
  mutation resetPassword($password: String!) {
    resetPassword(password: $password) {
      success
    }
  }
`;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >;
export const SendRestPasswordEmailDocument = gql`
  mutation sendRestPasswordEmail(
    $email: String!
    $captchaToken: String
    $captchaV2Token: String
  ) {
    sendResetPasswordEmail(
      email: $email
      captchaToken: $captchaToken
      captchaV2Token: $captchaV2Token
    ) {
      success
    }
  }
`;
export type SendRestPasswordEmailMutationFn =
  ApolloReactCommon.MutationFunction<
    SendRestPasswordEmailMutation,
    SendRestPasswordEmailMutationVariables
  >;

/**
 * __useSendRestPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendRestPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRestPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRestPasswordEmailMutation, { data, loading, error }] = useSendRestPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      captchaToken: // value for 'captchaToken'
 *      captchaV2Token: // value for 'captchaV2Token'
 *   },
 * });
 */
export function useSendRestPasswordEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendRestPasswordEmailMutation,
    SendRestPasswordEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SendRestPasswordEmailMutation,
    SendRestPasswordEmailMutationVariables
  >(SendRestPasswordEmailDocument, options);
}
export type SendRestPasswordEmailMutationHookResult = ReturnType<
  typeof useSendRestPasswordEmailMutation
>;
export type SendRestPasswordEmailMutationResult =
  ApolloReactCommon.MutationResult<SendRestPasswordEmailMutation>;
export type SendRestPasswordEmailMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    SendRestPasswordEmailMutation,
    SendRestPasswordEmailMutationVariables
  >;
export const RemoveAccountDocument = gql`
  mutation removeAccount($currentPassword: String) {
    removeUser(password: $currentPassword) {
      success
    }
  }
`;
export type RemoveAccountMutationFn = ApolloReactCommon.MutationFunction<
  RemoveAccountMutation,
  RemoveAccountMutationVariables
>;

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useRemoveAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveAccountMutation,
    RemoveAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveAccountMutation,
    RemoveAccountMutationVariables
  >(RemoveAccountDocument, options);
}
export type RemoveAccountMutationHookResult = ReturnType<
  typeof useRemoveAccountMutation
>;
export type RemoveAccountMutationResult =
  ApolloReactCommon.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    RemoveAccountMutation,
    RemoveAccountMutationVariables
  >;
export type AddKeysResponseKeySpecifier = (
  | 'balance'
  | 'keyStatus'
  | 'user'
  | AddKeysResponseKeySpecifier
)[];
export type AddKeysResponseFieldPolicy = {
  balance?: FieldPolicy<any> | FieldReadFunction<any>;
  keyStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminPerformanceResponseKeySpecifier = (
  | 'rate24'
  | 'start'
  | 'year'
  | 'ytd'
  | AdminPerformanceResponseKeySpecifier
)[];
export type AdminPerformanceResponseFieldPolicy = {
  rate24?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  year?: FieldPolicy<any> | FieldReadFunction<any>;
  ytd?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminUserResponseKeySpecifier = (
  | 'address'
  | 'amountToInvest'
  | 'authIdentities'
  | 'authProvider'
  | 'city'
  | 'companyName'
  | 'controlledByUser'
  | 'country'
  | 'currenciesLocked'
  | 'customStatus'
  | 'dateOfBirth'
  | 'dateOfSignup'
  | 'deletedAt'
  | 'exchanges'
  | 'firstName'
  | 'gender'
  | 'hadCreditCard'
  | 'hasActiveSubscription'
  | 'hasUnpaidInvoices'
  | 'houseNumber'
  | 'id'
  | 'isAdmin'
  | 'lastName'
  | 'mollieStatus'
  | 'moneyPaid'
  | 'nextRebalancingOn'
  | 'paymentSources'
  | 'pendingDiscount'
  | 'personalReferalCode'
  | 'phone'
  | 'profileValid'
  | 'referralCode'
  | 'state'
  | 'status'
  | 'stripeStatus'
  | 'totpEnabled'
  | 'trialEndDate'
  | 'vatNumber'
  | 'zip'
  | AdminUserResponseKeySpecifier
)[];
export type AdminUserResponseFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToInvest?: FieldPolicy<any> | FieldReadFunction<any>;
  authIdentities?: FieldPolicy<any> | FieldReadFunction<any>;
  authProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  controlledByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesLocked?: FieldPolicy<any> | FieldReadFunction<any>;
  customStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfBirth?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfSignup?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  exchanges?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  hadCreditCard?: FieldPolicy<any> | FieldReadFunction<any>;
  hasActiveSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasUnpaidInvoices?: FieldPolicy<any> | FieldReadFunction<any>;
  houseNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mollieStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  moneyPaid?: FieldPolicy<any> | FieldReadFunction<any>;
  nextRebalancingOn?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSources?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingDiscount?: FieldPolicy<any> | FieldReadFunction<any>;
  personalReferalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  profileValid?: FieldPolicy<any> | FieldReadFunction<any>;
  referralCode?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  totpEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  trialEndDate?: FieldPolicy<any> | FieldReadFunction<any>;
  vatNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  zip?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdminUsersResponseKeySpecifier = (
  | 'limit'
  | 'offset'
  | 'total'
  | 'totalAum'
  | 'totalPaid'
  | 'users'
  | AdminUsersResponseKeySpecifier
)[];
export type AdminUsersResponseFieldPolicy = {
  limit?: FieldPolicy<any> | FieldReadFunction<any>;
  offset?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  totalAum?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPaid?: FieldPolicy<any> | FieldReadFunction<any>;
  users?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AggregatedDataRowKeySpecifier = (
  | 'date'
  | 'value'
  | AggregatedDataRowKeySpecifier
)[];
export type AggregatedDataRowFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AuthIdentityResponseKeySpecifier = (
  | 'confirmedAt'
  | 'createdAt'
  | 'deletedAt'
  | 'id'
  | 'provider'
  | 'uid'
  | 'updatedAt'
  | AuthIdentityResponseKeySpecifier
)[];
export type AuthIdentityResponseFieldPolicy = {
  confirmedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  provider?: FieldPolicy<any> | FieldReadFunction<any>;
  uid?: FieldPolicy<any> | FieldReadFunction<any>;
  updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvailableAuthenticationKeySpecifier = (
  | 'link'
  | 'title'
  | AvailableAuthenticationKeySpecifier
)[];
export type AvailableAuthenticationFieldPolicy = {
  link?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChangePasswordResponseKeySpecifier = (
  | 'success'
  | ChangePasswordResponseKeySpecifier
)[];
export type ChangePasswordResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CurrencyKeySpecifier = (
  | 'btc24RateValue'
  | 'btcValue'
  | 'eur24RateValue'
  | 'eurValue'
  | 'icon'
  | 'id'
  | 'isFiat'
  | 'messari'
  | 'piechartColor'
  | 'symbol'
  | 'title'
  | 'usd24RateValue'
  | 'usdValue'
  | CurrencyKeySpecifier
)[];
export type CurrencyFieldPolicy = {
  btc24RateValue?: FieldPolicy<any> | FieldReadFunction<any>;
  btcValue?: FieldPolicy<any> | FieldReadFunction<any>;
  eur24RateValue?: FieldPolicy<any> | FieldReadFunction<any>;
  eurValue?: FieldPolicy<any> | FieldReadFunction<any>;
  icon?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isFiat?: FieldPolicy<any> | FieldReadFunction<any>;
  messari?: FieldPolicy<any> | FieldReadFunction<any>;
  piechartColor?: FieldPolicy<any> | FieldReadFunction<any>;
  symbol?: FieldPolicy<any> | FieldReadFunction<any>;
  title?: FieldPolicy<any> | FieldReadFunction<any>;
  usd24RateValue?: FieldPolicy<any> | FieldReadFunction<any>;
  usdValue?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DirectInvoicePaymentResponseKeySpecifier = (
  | 'checkoutURL'
  | DirectInvoicePaymentResponseKeySpecifier
)[];
export type DirectInvoicePaymentResponseFieldPolicy = {
  checkoutURL?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExchangeResponseKeySpecifier = (
  | 'exchangeCode'
  | 'exchangeName'
  | 'id'
  | ExchangeResponseKeySpecifier
)[];
export type ExchangeResponseFieldPolicy = {
  exchangeCode?: FieldPolicy<any> | FieldReadFunction<any>;
  exchangeName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GetCurrencyGraphDataResponseKeySpecifier = (
  | 'currency'
  | 'data'
  | GetCurrencyGraphDataResponseKeySpecifier
)[];
export type GetCurrencyGraphDataResponseFieldPolicy = {
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  data?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GoogleSignInResponseKeySpecifier = (
  | 'authIdentityID'
  | 'profileValid'
  | 'token'
  | 'totpEnabled'
  | GoogleSignInResponseKeySpecifier
)[];
export type GoogleSignInResponseFieldPolicy = {
  authIdentityID?: FieldPolicy<any> | FieldReadFunction<any>;
  profileValid?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
  totpEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GoogleSignUpResponseKeySpecifier = (
  | 'authIdentityID'
  | 'profileValid'
  | 'token'
  | 'totpEnabled'
  | GoogleSignUpResponseKeySpecifier
)[];
export type GoogleSignUpResponseFieldPolicy = {
  authIdentityID?: FieldPolicy<any> | FieldReadFunction<any>;
  profileValid?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
  totpEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceListResponseKeySpecifier = (
  | 'activeTotal'
  | 'invoices'
  | InvoiceListResponseKeySpecifier
)[];
export type InvoiceListResponseFieldPolicy = {
  activeTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  invoices?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceResponseKeySpecifier = (
  | 'amount'
  | 'cost'
  | 'costExVat'
  | 'dueDate'
  | 'fileName'
  | 'id'
  | 'price'
  | 'status'
  | 'strategy'
  | 'total'
  | 'url'
  | 'user'
  | 'vat'
  | 'vatAmount'
  | InvoiceResponseKeySpecifier
)[];
export type InvoiceResponseFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  cost?: FieldPolicy<any> | FieldReadFunction<any>;
  costExVat?: FieldPolicy<any> | FieldReadFunction<any>;
  dueDate?: FieldPolicy<any> | FieldReadFunction<any>;
  fileName?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  strategy?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  vat?: FieldPolicy<any> | FieldReadFunction<any>;
  vatAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MolliePaymentResponseKeySpecifier = (
  | 'createdAt'
  | 'details'
  | 'method'
  | 'status'
  | MolliePaymentResponseKeySpecifier
)[];
export type MolliePaymentResponseFieldPolicy = {
  createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  method?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MollieTransitPaymentLinkResponseKeySpecifier = (
  | 'checkoutURL'
  | MollieTransitPaymentLinkResponseKeySpecifier
)[];
export type MollieTransitPaymentLinkResponseFieldPolicy = {
  checkoutURL?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'AdminCancelUserSubscription'
  | 'addKeys'
  | 'adminManageUsersTotp'
  | 'adminRebalanceUser'
  | 'adminRemoveKeys'
  | 'adminSendEmail'
  | 'adminSignInAs'
  | 'adminSignOutAs'
  | 'cancelSubscription'
  | 'changeCredentials'
  | 'directMolliePayment'
  | 'editProfile'
  | 'googleSignIn'
  | 'googleSignUp'
  | 'newMollieClient'
  | 'portfolioDeposit'
  | 'portfolioLiquidation'
  | 'removeKeys'
  | 'removeUser'
  | 'resendVerificationEmail'
  | 'resetPassword'
  | 'sendResetPasswordEmail'
  | 'signIn'
  | 'signOut'
  | 'signUp'
  | 'startSubscription'
  | 'stripeSetPaymentMethod'
  | 'stripeSetupIntent'
  | 'totpDisable'
  | 'totpGenerate'
  | 'totpSetup'
  | 'totpVerify'
  | 'userLockCurrency'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  AdminCancelUserSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  addKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  adminManageUsersTotp?: FieldPolicy<any> | FieldReadFunction<any>;
  adminRebalanceUser?: FieldPolicy<any> | FieldReadFunction<any>;
  adminRemoveKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  adminSendEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  adminSignInAs?: FieldPolicy<any> | FieldReadFunction<any>;
  adminSignOutAs?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  changeCredentials?: FieldPolicy<any> | FieldReadFunction<any>;
  directMolliePayment?: FieldPolicy<any> | FieldReadFunction<any>;
  editProfile?: FieldPolicy<any> | FieldReadFunction<any>;
  googleSignIn?: FieldPolicy<any> | FieldReadFunction<any>;
  googleSignUp?: FieldPolicy<any> | FieldReadFunction<any>;
  newMollieClient?: FieldPolicy<any> | FieldReadFunction<any>;
  portfolioDeposit?: FieldPolicy<any> | FieldReadFunction<any>;
  portfolioLiquidation?: FieldPolicy<any> | FieldReadFunction<any>;
  removeKeys?: FieldPolicy<any> | FieldReadFunction<any>;
  removeUser?: FieldPolicy<any> | FieldReadFunction<any>;
  resendVerificationEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  resetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  sendResetPasswordEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  signIn?: FieldPolicy<any> | FieldReadFunction<any>;
  signOut?: FieldPolicy<any> | FieldReadFunction<any>;
  signUp?: FieldPolicy<any> | FieldReadFunction<any>;
  startSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeSetPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeSetupIntent?: FieldPolicy<any> | FieldReadFunction<any>;
  totpDisable?: FieldPolicy<any> | FieldReadFunction<any>;
  totpGenerate?: FieldPolicy<any> | FieldReadFunction<any>;
  totpSetup?: FieldPolicy<any> | FieldReadFunction<any>;
  totpVerify?: FieldPolicy<any> | FieldReadFunction<any>;
  userLockCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NewMollieClientResponseKeySpecifier = (
  | 'checkoutURL'
  | NewMollieClientResponseKeySpecifier
)[];
export type NewMollieClientResponseFieldPolicy = {
  checkoutURL?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentSourceKeySpecifier = (
  | 'brand'
  | 'expMonth'
  | 'expYear'
  | 'id'
  | 'lastFour'
  | PaymentSourceKeySpecifier
)[];
export type PaymentSourceFieldPolicy = {
  brand?: FieldPolicy<any> | FieldReadFunction<any>;
  expMonth?: FieldPolicy<any> | FieldReadFunction<any>;
  expYear?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  lastFour?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PortfolioRowResponseKeySpecifier = (
  | 'amountCoins'
  | 'change24'
  | 'change24Amount'
  | 'change24Percentage'
  | 'cost'
  | 'currency'
  | 'effectiveDate'
  | 'percentage'
  | 'usdChange24Amount'
  | 'usdChange24Percentage'
  | 'usdCost'
  | PortfolioRowResponseKeySpecifier
)[];
export type PortfolioRowResponseFieldPolicy = {
  amountCoins?: FieldPolicy<any> | FieldReadFunction<any>;
  change24?: FieldPolicy<any> | FieldReadFunction<any>;
  change24Amount?: FieldPolicy<any> | FieldReadFunction<any>;
  change24Percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  cost?: FieldPolicy<any> | FieldReadFunction<any>;
  currency?: FieldPolicy<any> | FieldReadFunction<any>;
  effectiveDate?: FieldPolicy<any> | FieldReadFunction<any>;
  percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  usdChange24Amount?: FieldPolicy<any> | FieldReadFunction<any>;
  usdChange24Percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  usdCost?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PortfolioTotalsResponseKeySpecifier = (
  | 'eurCostChange24Percentage'
  | 'eurCostChange24Total'
  | 'eurCostTotal'
  | 'nextRebalancingOn'
  | 'usdCostChange24Percentage'
  | 'usdCostChange24total'
  | 'usdCostTotal'
  | PortfolioTotalsResponseKeySpecifier
)[];
export type PortfolioTotalsResponseFieldPolicy = {
  eurCostChange24Percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  eurCostChange24Total?: FieldPolicy<any> | FieldReadFunction<any>;
  eurCostTotal?: FieldPolicy<any> | FieldReadFunction<any>;
  nextRebalancingOn?: FieldPolicy<any> | FieldReadFunction<any>;
  usdCostChange24Percentage?: FieldPolicy<any> | FieldReadFunction<any>;
  usdCostChange24total?: FieldPolicy<any> | FieldReadFunction<any>;
  usdCostTotal?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'adminGetInvoices'
  | 'adminGetPerformance'
  | 'adminGetUser'
  | 'adminGetUsers'
  | 'availableAuthentications'
  | 'getCurrencyGraphData'
  | 'getDemoCurrencyGraphData'
  | 'getDemoPortfolio'
  | 'getDemoPortfolioGraphData'
  | 'getDemoUser'
  | 'getExchanges'
  | 'getInvoice'
  | 'getInvoices'
  | 'getMenu'
  | 'getMolliePaymentInfo'
  | 'getMollieTransitPaymentLink'
  | 'getPortfolio'
  | 'getPortfolioGraphData'
  | 'getPortfolioTotals'
  | 'getUser'
  | 'getUserExchanges'
  | 'portfolioCurrency'
  | 'selectedExchange'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  adminGetInvoices?: FieldPolicy<any> | FieldReadFunction<any>;
  adminGetPerformance?: FieldPolicy<any> | FieldReadFunction<any>;
  adminGetUser?: FieldPolicy<any> | FieldReadFunction<any>;
  adminGetUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  availableAuthentications?: FieldPolicy<any> | FieldReadFunction<any>;
  getCurrencyGraphData?: FieldPolicy<any> | FieldReadFunction<any>;
  getDemoCurrencyGraphData?: FieldPolicy<any> | FieldReadFunction<any>;
  getDemoPortfolio?: FieldPolicy<any> | FieldReadFunction<any>;
  getDemoPortfolioGraphData?: FieldPolicy<any> | FieldReadFunction<any>;
  getDemoUser?: FieldPolicy<any> | FieldReadFunction<any>;
  getExchanges?: FieldPolicy<any> | FieldReadFunction<any>;
  getInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  getInvoices?: FieldPolicy<any> | FieldReadFunction<any>;
  getMenu?: FieldPolicy<any> | FieldReadFunction<any>;
  getMolliePaymentInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  getMollieTransitPaymentLink?: FieldPolicy<any> | FieldReadFunction<any>;
  getPortfolio?: FieldPolicy<any> | FieldReadFunction<any>;
  getPortfolioGraphData?: FieldPolicy<any> | FieldReadFunction<any>;
  getPortfolioTotals?: FieldPolicy<any> | FieldReadFunction<any>;
  getUser?: FieldPolicy<any> | FieldReadFunction<any>;
  getUserExchanges?: FieldPolicy<any> | FieldReadFunction<any>;
  portfolioCurrency?: FieldPolicy<any> | FieldReadFunction<any>;
  selectedExchange?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RemoveUserResponseKeySpecifier = (
  | 'invoiceToPayId'
  | 'success'
  | RemoveUserResponseKeySpecifier
)[];
export type RemoveUserResponseFieldPolicy = {
  invoiceToPayId?: FieldPolicy<any> | FieldReadFunction<any>;
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ResetPasswordResponseKeySpecifier = (
  | 'success'
  | ResetPasswordResponseKeySpecifier
)[];
export type ResetPasswordResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SendResetPasswordEmailResponseKeySpecifier = (
  | 'success'
  | SendResetPasswordEmailResponseKeySpecifier
)[];
export type SendResetPasswordEmailResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignInResponseKeySpecifier = (
  | 'authIdentityID'
  | 'token'
  | 'totpEnabled'
  | SignInResponseKeySpecifier
)[];
export type SignInResponseFieldPolicy = {
  authIdentityID?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
  totpEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignOutResponseKeySpecifier = (
  | 'success'
  | SignOutResponseKeySpecifier
)[];
export type SignOutResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SignUpResponseKeySpecifier = (
  | 'authIdentityId'
  | 'token'
  | SignUpResponseKeySpecifier
)[];
export type SignUpResponseFieldPolicy = {
  authIdentityId?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StripeSetPaymentMethodResponseKeySpecifier = (
  | 'success'
  | StripeSetPaymentMethodResponseKeySpecifier
)[];
export type StripeSetPaymentMethodResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotpDisableResponseKeySpecifier = (
  | 'success'
  | TotpDisableResponseKeySpecifier
)[];
export type TotpDisableResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotpValidateResponseKeySpecifier = (
  | 'backupCodes'
  | 'token'
  | TotpValidateResponseKeySpecifier
)[];
export type TotpValidateResponseFieldPolicy = {
  backupCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TotpVerifyResponseKeySpecifier = (
  | 'token'
  | TotpVerifyResponseKeySpecifier
)[];
export type TotpVerifyResponseFieldPolicy = {
  token?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserExchangeResponseKeySpecifier = (
  | 'aumBelowMinLastShowTime'
  | 'aumBelowMinimum'
  | 'exchangeCode'
  | 'exchangeID'
  | 'fiatCurrencies'
  | 'keysCreatedAt'
  | 'keysStatus'
  | 'moneyUnderManagement'
  | 'nextRebalanceDate'
  | 'onlyFiatInPortfolio'
  | 'subscriptionInitiatedDate'
  | 'subscriptionStartDate'
  | 'targetForSaleCurrencies'
  | 'wizardStatus'
  | UserExchangeResponseKeySpecifier
)[];
export type UserExchangeResponseFieldPolicy = {
  aumBelowMinLastShowTime?: FieldPolicy<any> | FieldReadFunction<any>;
  aumBelowMinimum?: FieldPolicy<any> | FieldReadFunction<any>;
  exchangeCode?: FieldPolicy<any> | FieldReadFunction<any>;
  exchangeID?: FieldPolicy<any> | FieldReadFunction<any>;
  fiatCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  keysCreatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  keysStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  moneyUnderManagement?: FieldPolicy<any> | FieldReadFunction<any>;
  nextRebalanceDate?: FieldPolicy<any> | FieldReadFunction<any>;
  onlyFiatInPortfolio?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionInitiatedDate?: FieldPolicy<any> | FieldReadFunction<any>;
  subscriptionStartDate?: FieldPolicy<any> | FieldReadFunction<any>;
  targetForSaleCurrencies?: FieldPolicy<any> | FieldReadFunction<any>;
  wizardStatus?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserResponseKeySpecifier = (
  | 'address'
  | 'amountToInvest'
  | 'authIdentities'
  | 'authProvider'
  | 'city'
  | 'companyName'
  | 'controlledByUser'
  | 'country'
  | 'currenciesLocked'
  | 'dateOfBirth'
  | 'dateOfSignup'
  | 'deletedAt'
  | 'exchanges'
  | 'firstName'
  | 'gender'
  | 'hadCreditCard'
  | 'hasActiveSubscription'
  | 'hasUnpaidInvoices'
  | 'houseNumber'
  | 'id'
  | 'isAdmin'
  | 'lastName'
  | 'mollieStatus'
  | 'moneyPaid'
  | 'nextRebalancingOn'
  | 'paymentSources'
  | 'pendingDiscount'
  | 'personalReferalCode'
  | 'phone'
  | 'profileValid'
  | 'referralCode'
  | 'state'
  | 'status'
  | 'stripeStatus'
  | 'totpEnabled'
  | 'trialEndDate'
  | 'vatNumber'
  | 'zip'
  | UserResponseKeySpecifier
)[];
export type UserResponseFieldPolicy = {
  address?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToInvest?: FieldPolicy<any> | FieldReadFunction<any>;
  authIdentities?: FieldPolicy<any> | FieldReadFunction<any>;
  authProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  controlledByUser?: FieldPolicy<any> | FieldReadFunction<any>;
  country?: FieldPolicy<any> | FieldReadFunction<any>;
  currenciesLocked?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfBirth?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfSignup?: FieldPolicy<any> | FieldReadFunction<any>;
  deletedAt?: FieldPolicy<any> | FieldReadFunction<any>;
  exchanges?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  hadCreditCard?: FieldPolicy<any> | FieldReadFunction<any>;
  hasActiveSubscription?: FieldPolicy<any> | FieldReadFunction<any>;
  hasUnpaidInvoices?: FieldPolicy<any> | FieldReadFunction<any>;
  houseNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mollieStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  moneyPaid?: FieldPolicy<any> | FieldReadFunction<any>;
  nextRebalancingOn?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentSources?: FieldPolicy<any> | FieldReadFunction<any>;
  pendingDiscount?: FieldPolicy<any> | FieldReadFunction<any>;
  personalReferalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  phone?: FieldPolicy<any> | FieldReadFunction<any>;
  profileValid?: FieldPolicy<any> | FieldReadFunction<any>;
  referralCode?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  stripeStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  totpEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
  trialEndDate?: FieldPolicy<any> | FieldReadFunction<any>;
  vatNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  zip?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  AddKeysResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AddKeysResponseKeySpecifier
      | (() => undefined | AddKeysResponseKeySpecifier);
    fields?: AddKeysResponseFieldPolicy;
  };
  AdminPerformanceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminPerformanceResponseKeySpecifier
      | (() => undefined | AdminPerformanceResponseKeySpecifier);
    fields?: AdminPerformanceResponseFieldPolicy;
  };
  AdminUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUserResponseKeySpecifier
      | (() => undefined | AdminUserResponseKeySpecifier);
    fields?: AdminUserResponseFieldPolicy;
  };
  AdminUsersResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdminUsersResponseKeySpecifier
      | (() => undefined | AdminUsersResponseKeySpecifier);
    fields?: AdminUsersResponseFieldPolicy;
  };
  AggregatedDataRow?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AggregatedDataRowKeySpecifier
      | (() => undefined | AggregatedDataRowKeySpecifier);
    fields?: AggregatedDataRowFieldPolicy;
  };
  AuthIdentityResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AuthIdentityResponseKeySpecifier
      | (() => undefined | AuthIdentityResponseKeySpecifier);
    fields?: AuthIdentityResponseFieldPolicy;
  };
  AvailableAuthentication?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AvailableAuthenticationKeySpecifier
      | (() => undefined | AvailableAuthenticationKeySpecifier);
    fields?: AvailableAuthenticationFieldPolicy;
  };
  ChangePasswordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChangePasswordResponseKeySpecifier
      | (() => undefined | ChangePasswordResponseKeySpecifier);
    fields?: ChangePasswordResponseFieldPolicy;
  };
  Currency?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CurrencyKeySpecifier
      | (() => undefined | CurrencyKeySpecifier);
    fields?: CurrencyFieldPolicy;
  };
  DirectInvoicePaymentResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DirectInvoicePaymentResponseKeySpecifier
      | (() => undefined | DirectInvoicePaymentResponseKeySpecifier);
    fields?: DirectInvoicePaymentResponseFieldPolicy;
  };
  ExchangeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ExchangeResponseKeySpecifier
      | (() => undefined | ExchangeResponseKeySpecifier);
    fields?: ExchangeResponseFieldPolicy;
  };
  GetCurrencyGraphDataResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GetCurrencyGraphDataResponseKeySpecifier
      | (() => undefined | GetCurrencyGraphDataResponseKeySpecifier);
    fields?: GetCurrencyGraphDataResponseFieldPolicy;
  };
  GoogleSignInResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GoogleSignInResponseKeySpecifier
      | (() => undefined | GoogleSignInResponseKeySpecifier);
    fields?: GoogleSignInResponseFieldPolicy;
  };
  GoogleSignUpResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GoogleSignUpResponseKeySpecifier
      | (() => undefined | GoogleSignUpResponseKeySpecifier);
    fields?: GoogleSignUpResponseFieldPolicy;
  };
  InvoiceListResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceListResponseKeySpecifier
      | (() => undefined | InvoiceListResponseKeySpecifier);
    fields?: InvoiceListResponseFieldPolicy;
  };
  InvoiceResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceResponseKeySpecifier
      | (() => undefined | InvoiceResponseKeySpecifier);
    fields?: InvoiceResponseFieldPolicy;
  };
  MolliePaymentResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MolliePaymentResponseKeySpecifier
      | (() => undefined | MolliePaymentResponseKeySpecifier);
    fields?: MolliePaymentResponseFieldPolicy;
  };
  MollieTransitPaymentLinkResponse?: Omit<
    TypePolicy,
    'fields' | 'keyFields'
  > & {
    keyFields?:
      | false
      | MollieTransitPaymentLinkResponseKeySpecifier
      | (() => undefined | MollieTransitPaymentLinkResponseKeySpecifier);
    fields?: MollieTransitPaymentLinkResponseFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MutationKeySpecifier
      | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  NewMollieClientResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | NewMollieClientResponseKeySpecifier
      | (() => undefined | NewMollieClientResponseKeySpecifier);
    fields?: NewMollieClientResponseFieldPolicy;
  };
  PaymentSource?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentSourceKeySpecifier
      | (() => undefined | PaymentSourceKeySpecifier);
    fields?: PaymentSourceFieldPolicy;
  };
  PortfolioRowResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PortfolioRowResponseKeySpecifier
      | (() => undefined | PortfolioRowResponseKeySpecifier);
    fields?: PortfolioRowResponseFieldPolicy;
  };
  PortfolioTotalsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PortfolioTotalsResponseKeySpecifier
      | (() => undefined | PortfolioTotalsResponseKeySpecifier);
    fields?: PortfolioTotalsResponseFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | QueryKeySpecifier
      | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  RemoveUserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RemoveUserResponseKeySpecifier
      | (() => undefined | RemoveUserResponseKeySpecifier);
    fields?: RemoveUserResponseFieldPolicy;
  };
  ResetPasswordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ResetPasswordResponseKeySpecifier
      | (() => undefined | ResetPasswordResponseKeySpecifier);
    fields?: ResetPasswordResponseFieldPolicy;
  };
  SendResetPasswordEmailResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SendResetPasswordEmailResponseKeySpecifier
      | (() => undefined | SendResetPasswordEmailResponseKeySpecifier);
    fields?: SendResetPasswordEmailResponseFieldPolicy;
  };
  SignInResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SignInResponseKeySpecifier
      | (() => undefined | SignInResponseKeySpecifier);
    fields?: SignInResponseFieldPolicy;
  };
  SignOutResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SignOutResponseKeySpecifier
      | (() => undefined | SignOutResponseKeySpecifier);
    fields?: SignOutResponseFieldPolicy;
  };
  SignUpResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SignUpResponseKeySpecifier
      | (() => undefined | SignUpResponseKeySpecifier);
    fields?: SignUpResponseFieldPolicy;
  };
  StripeSetPaymentMethodResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StripeSetPaymentMethodResponseKeySpecifier
      | (() => undefined | StripeSetPaymentMethodResponseKeySpecifier);
    fields?: StripeSetPaymentMethodResponseFieldPolicy;
  };
  TotpDisableResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TotpDisableResponseKeySpecifier
      | (() => undefined | TotpDisableResponseKeySpecifier);
    fields?: TotpDisableResponseFieldPolicy;
  };
  TotpValidateResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TotpValidateResponseKeySpecifier
      | (() => undefined | TotpValidateResponseKeySpecifier);
    fields?: TotpValidateResponseFieldPolicy;
  };
  TotpVerifyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TotpVerifyResponseKeySpecifier
      | (() => undefined | TotpVerifyResponseKeySpecifier);
    fields?: TotpVerifyResponseFieldPolicy;
  };
  UserExchangeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserExchangeResponseKeySpecifier
      | (() => undefined | UserExchangeResponseKeySpecifier);
    fields?: UserExchangeResponseFieldPolicy;
  };
  UserResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UserResponseKeySpecifier
      | (() => undefined | UserResponseKeySpecifier);
    fields?: UserResponseFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {}
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {}
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddKeyStatus: AddKeyStatus;
  AddKeysResponse: ResolverTypeWrapper<AddKeysResponse>;
  AdminPerformanceResponse: ResolverTypeWrapper<AdminPerformanceResponse>;
  AdminUserResponse: ResolverTypeWrapper<AdminUserResponse>;
  AdminUsersResponse: ResolverTypeWrapper<AdminUsersResponse>;
  AggregatedDataRow: ResolverTypeWrapper<AggregatedDataRow>;
  AuthIdentityProvider: AuthIdentityProvider;
  AuthIdentityResponse: ResolverTypeWrapper<AuthIdentityResponse>;
  AvailableAuthentication: ResolverTypeWrapper<AvailableAuthentication>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  ChangePasswordResponse: ResolverTypeWrapper<ChangePasswordResponse>;
  Currency: ResolverTypeWrapper<Currency>;
  CustomUserStatus: CustomUserStatus;
  DeleteUserArgs: DeleteUserArgs;
  DirectInvoicePaymentResponse: ResolverTypeWrapper<DirectInvoicePaymentResponse>;
  ExchangeCode: ExchangeCode;
  ExchangeResponse: ResolverTypeWrapper<ExchangeResponse>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GetCurrencyGraphDataResponse: ResolverTypeWrapper<GetCurrencyGraphDataResponse>;
  GoogleSignInResponse: ResolverTypeWrapper<GoogleSignInResponse>;
  GoogleSignUpResponse: ResolverTypeWrapper<GoogleSignUpResponse>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InvoiceActiveFilter: InvoiceActiveFilter;
  InvoiceListResponse: ResolverTypeWrapper<InvoiceListResponse>;
  InvoicePaymentStatus: InvoicePaymentStatus;
  InvoiceResponse: ResolverTypeWrapper<InvoiceResponse>;
  InvoicesFilterByStatus: InvoicesFilterByStatus;
  InvoicesOrderBy: InvoicesOrderBy;
  InvoicesOrderByField: InvoicesOrderByField;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  KeyInput: KeyInput;
  KeysStatus: KeysStatus;
  MolliePaymentResponse: ResolverTypeWrapper<MolliePaymentResponse>;
  MollieStatus: MollieStatus;
  MollieTransitPaymentLinkResponse: ResolverTypeWrapper<MollieTransitPaymentLinkResponse>;
  Mutation: ResolverTypeWrapper<{}>;
  NewMollieClientResponse: ResolverTypeWrapper<NewMollieClientResponse>;
  OrderBy: OrderBy;
  OrderByDirection: OrderByDirection;
  PaymentSource: ResolverTypeWrapper<PaymentSource>;
  PortfolioCurrency: PortfolioCurrency;
  PortfolioRowResponse: ResolverTypeWrapper<PortfolioRowResponse>;
  PortfolioTotalsResponse: ResolverTypeWrapper<PortfolioTotalsResponse>;
  Query: ResolverTypeWrapper<{}>;
  RemoveUserResponse: ResolverTypeWrapper<RemoveUserResponse>;
  ResetPasswordResponse: ResolverTypeWrapper<ResetPasswordResponse>;
  SendResetPasswordEmailResponse: ResolverTypeWrapper<SendResetPasswordEmailResponse>;
  SignInResponse: ResolverTypeWrapper<SignInResponse>;
  SignOutResponse: ResolverTypeWrapper<SignOutResponse>;
  SignUpResponse: ResolverTypeWrapper<SignUpResponse>;
  StartTrialStatus: StartTrialStatus;
  String: ResolverTypeWrapper<Scalars['String']>;
  StripeSetPaymentMethodResponse: ResolverTypeWrapper<StripeSetPaymentMethodResponse>;
  StripeStatus: StripeStatus;
  Time: ResolverTypeWrapper<Scalars['Time']>;
  TotpDisableResponse: ResolverTypeWrapper<TotpDisableResponse>;
  TotpValidateResponse: ResolverTypeWrapper<TotpValidateResponse>;
  TotpVerifyResponse: ResolverTypeWrapper<TotpVerifyResponse>;
  UserExchangeResponse: ResolverTypeWrapper<UserExchangeResponse>;
  UserResponse: ResolverTypeWrapper<UserResponse>;
  UserStatus: UserStatus;
  UserStatusInput: UserStatusInput;
  UsersOrderBy: UsersOrderBy;
  UsersOrderByField: UsersOrderByField;
  WizardStatusType: WizardStatusType;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddKeysResponse: AddKeysResponse;
  AdminPerformanceResponse: AdminPerformanceResponse;
  AdminUserResponse: AdminUserResponse;
  AdminUsersResponse: AdminUsersResponse;
  AggregatedDataRow: AggregatedDataRow;
  AuthIdentityResponse: AuthIdentityResponse;
  AvailableAuthentication: AvailableAuthentication;
  Boolean: Scalars['Boolean'];
  ChangePasswordResponse: ChangePasswordResponse;
  Currency: Currency;
  DeleteUserArgs: DeleteUserArgs;
  DirectInvoicePaymentResponse: DirectInvoicePaymentResponse;
  ExchangeResponse: ExchangeResponse;
  Float: Scalars['Float'];
  GetCurrencyGraphDataResponse: GetCurrencyGraphDataResponse;
  GoogleSignInResponse: GoogleSignInResponse;
  GoogleSignUpResponse: GoogleSignUpResponse;
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  InvoiceListResponse: InvoiceListResponse;
  InvoiceResponse: InvoiceResponse;
  InvoicesOrderBy: InvoicesOrderBy;
  JSON: Scalars['JSON'];
  KeyInput: KeyInput;
  MolliePaymentResponse: MolliePaymentResponse;
  MollieTransitPaymentLinkResponse: MollieTransitPaymentLinkResponse;
  Mutation: {};
  NewMollieClientResponse: NewMollieClientResponse;
  OrderBy: OrderBy;
  PaymentSource: PaymentSource;
  PortfolioRowResponse: PortfolioRowResponse;
  PortfolioTotalsResponse: PortfolioTotalsResponse;
  Query: {};
  RemoveUserResponse: RemoveUserResponse;
  ResetPasswordResponse: ResetPasswordResponse;
  SendResetPasswordEmailResponse: SendResetPasswordEmailResponse;
  SignInResponse: SignInResponse;
  SignOutResponse: SignOutResponse;
  SignUpResponse: SignUpResponse;
  String: Scalars['String'];
  StripeSetPaymentMethodResponse: StripeSetPaymentMethodResponse;
  Time: Scalars['Time'];
  TotpDisableResponse: TotpDisableResponse;
  TotpValidateResponse: TotpValidateResponse;
  TotpVerifyResponse: TotpVerifyResponse;
  UserExchangeResponse: UserExchangeResponse;
  UserResponse: UserResponse;
  UsersOrderBy: UsersOrderBy;
};

export type ClientDirectiveArgs = {
  always?: Maybe<Scalars['Boolean']>;
};

export type ClientDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ClientDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ExportDirectiveArgs = {
  as: Scalars['String'];
};

export type ExportDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = ExportDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddKeysResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AddKeysResponse'] = ResolversParentTypes['AddKeysResponse']
> = {
  balance?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  keyStatus?: Resolver<ResolversTypes['AddKeyStatus'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserResponse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminPerformanceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminPerformanceResponse'] = ResolversParentTypes['AdminPerformanceResponse']
> = {
  rate24?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  start?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  year?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ytd?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminUserResponse'] = ResolversParentTypes['AdminUserResponse']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amountToInvest?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  authIdentities?: Resolver<
    Array<ResolversTypes['AuthIdentityResponse']>,
    ParentType,
    ContextType,
    Partial<AdminUserResponseAuthIdentitiesArgs>
  >;
  authProvider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  controlledByUser?: Resolver<
    Maybe<ResolversTypes['UserResponse']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currenciesLocked?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  customStatus?: Resolver<
    ResolversTypes['CustomUserStatus'],
    ParentType,
    ContextType
  >;
  dateOfBirth?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  dateOfSignup?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  exchanges?: Resolver<
    Array<ResolversTypes['UserExchangeResponse']>,
    ParentType,
    ContextType
  >;
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hadCreditCard?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasActiveSubscription?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasUnpaidInvoices?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  houseNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mollieStatus?: Resolver<
    ResolversTypes['MollieStatus'],
    ParentType,
    ContextType
  >;
  moneyPaid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nextRebalancingOn?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  paymentSources?: Resolver<
    Array<ResolversTypes['PaymentSource']>,
    ParentType,
    ContextType
  >;
  pendingDiscount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  personalReferalCode?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  referralCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  stripeStatus?: Resolver<
    ResolversTypes['StripeStatus'],
    ParentType,
    ContextType
  >;
  totpEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  trialEndDate?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  vatNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdminUsersResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AdminUsersResponse'] = ResolversParentTypes['AdminUsersResponse']
> = {
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPaid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  users?: Resolver<
    Array<ResolversTypes['AdminUserResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AggregatedDataRowResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AggregatedDataRow'] = ResolversParentTypes['AggregatedDataRow']
> = {
  date?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthIdentityResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AuthIdentityResponse'] = ResolversParentTypes['AuthIdentityResponse']
> = {
  confirmedAt?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  provider?: Resolver<
    ResolversTypes['AuthIdentityProvider'],
    ParentType,
    ContextType
  >;
  uid?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailableAuthenticationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AvailableAuthentication'] = ResolversParentTypes['AvailableAuthentication']
> = {
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChangePasswordResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ChangePasswordResponse'] = ResolversParentTypes['ChangePasswordResponse']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Currency'] = ResolversParentTypes['Currency']
> = {
  btc24RateValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  btcValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eur24RateValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  eurValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  icon?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isFiat?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  messari?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  piechartColor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usd24RateValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  usdValue?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DirectInvoicePaymentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DirectInvoicePaymentResponse'] = ResolversParentTypes['DirectInvoicePaymentResponse']
> = {
  checkoutURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExchangeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ExchangeResponse'] = ResolversParentTypes['ExchangeResponse']
> = {
  exchangeCode?: Resolver<
    ResolversTypes['ExchangeCode'],
    ParentType,
    ContextType
  >;
  exchangeName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetCurrencyGraphDataResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GetCurrencyGraphDataResponse'] = ResolversParentTypes['GetCurrencyGraphDataResponse']
> = {
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  data?: Resolver<
    Array<ResolversTypes['AggregatedDataRow']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleSignInResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GoogleSignInResponse'] = ResolversParentTypes['GoogleSignInResponse']
> = {
  authIdentityID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  profileValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totpEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleSignUpResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GoogleSignUpResponse'] = ResolversParentTypes['GoogleSignUpResponse']
> = {
  authIdentityID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  profileValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totpEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceListResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvoiceListResponse'] = ResolversParentTypes['InvoiceListResponse']
> = {
  activeTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  invoices?: Resolver<
    Array<ResolversTypes['InvoiceResponse']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvoiceResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InvoiceResponse'] = ResolversParentTypes['InvoiceResponse']
> = {
  amount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costExVat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dueDate?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  fileName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<
    ResolversTypes['InvoicePaymentStatus'],
    ParentType,
    ContextType
  >;
  strategy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserResponse'], ParentType, ContextType>;
  vat?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  vatAmount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MolliePaymentResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MolliePaymentResponse'] = ResolversParentTypes['MolliePaymentResponse']
> = {
  createdAt?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  details?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  method?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MollieTransitPaymentLinkResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MollieTransitPaymentLinkResponse'] = ResolversParentTypes['MollieTransitPaymentLinkResponse']
> = {
  checkoutURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
  AdminCancelUserSubscription?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationAdminCancelUserSubscriptionArgs,
      'exchangeId' | 'userID'
    >
  >;
  addKeys?: Resolver<
    ResolversTypes['UserExchangeResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddKeysArgs, 'exchangeId' | 'keys'>
  >;
  adminManageUsersTotp?: Resolver<
    Array<ResolversTypes['UserResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationAdminManageUsersTotpArgs, 'enable' | 'usersIDs'>
  >;
  adminRebalanceUser?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAdminRebalanceUserArgs, 'exchangeId' | 'userID'>
  >;
  adminRemoveKeys?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAdminRemoveKeysArgs, 'exchangeId' | 'userID'>
  >;
  adminSendEmail?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationAdminSendEmailArgs, 'templateId'>
  >;
  adminSignInAs?: Resolver<
    ResolversTypes['SignInResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAdminSignInAsArgs, 'userID'>
  >;
  adminSignOutAs?: Resolver<
    ResolversTypes['SignInResponse'],
    ParentType,
    ContextType
  >;
  cancelSubscription?: Resolver<
    Maybe<ResolversTypes['UserExchangeResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationCancelSubscriptionArgs, 'exchangeId'>
  >;
  changeCredentials?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationChangeCredentialsArgs, 'currentPassword'>
  >;
  directMolliePayment?: Resolver<
    ResolversTypes['DirectInvoicePaymentResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDirectMolliePaymentArgs, 'invoiceId'>
  >;
  editProfile?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    Partial<MutationEditProfileArgs>
  >;
  googleSignIn?: Resolver<
    ResolversTypes['GoogleSignInResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationGoogleSignInArgs, 'token'>
  >;
  googleSignUp?: Resolver<
    ResolversTypes['GoogleSignUpResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationGoogleSignUpArgs, 'token'>
  >;
  newMollieClient?: Resolver<
    ResolversTypes['NewMollieClientResponse'],
    ParentType,
    ContextType,
    Partial<MutationNewMollieClientArgs>
  >;
  portfolioDeposit?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationPortfolioDepositArgs, 'exchangeId'>
  >;
  portfolioLiquidation?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationPortfolioLiquidationArgs,
      'currencyId' | 'exchangeId' | 'percents'
    >
  >;
  removeKeys?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveKeysArgs, 'exchangeId'>
  >;
  removeUser?: Resolver<
    ResolversTypes['RemoveUserResponse'],
    ParentType,
    ContextType,
    Partial<MutationRemoveUserArgs>
  >;
  resendVerificationEmail?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    Partial<MutationResendVerificationEmailArgs>
  >;
  resetPassword?: Resolver<
    ResolversTypes['ResetPasswordResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResetPasswordArgs, 'password'>
  >;
  sendResetPasswordEmail?: Resolver<
    ResolversTypes['SendResetPasswordEmailResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSendResetPasswordEmailArgs, 'email'>
  >;
  signIn?: Resolver<
    ResolversTypes['SignInResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSignInArgs, 'email' | 'password' | 'remember'>
  >;
  signOut?: Resolver<
    ResolversTypes['SignOutResponse'],
    ParentType,
    ContextType
  >;
  signUp?: Resolver<
    ResolversTypes['SignUpResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationSignUpArgs,
      'email' | 'firstName' | 'lastName' | 'password' | 'passwordRepeat'
    >
  >;
  startSubscription?: Resolver<
    ResolversTypes['StartTrialStatus'],
    ParentType,
    ContextType,
    RequireFields<MutationStartSubscriptionArgs, 'exchangeId'>
  >;
  stripeSetPaymentMethod?: Resolver<
    ResolversTypes['StripeSetPaymentMethodResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationStripeSetPaymentMethodArgs, 'paymentMethodID'>
  >;
  stripeSetupIntent?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  totpDisable?: Resolver<
    ResolversTypes['TotpDisableResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationTotpDisableArgs, 'code'>
  >;
  totpGenerate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totpSetup?: Resolver<
    Maybe<ResolversTypes['TotpValidateResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationTotpSetupArgs, 'code'>
  >;
  totpVerify?: Resolver<
    Maybe<ResolversTypes['TotpVerifyResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationTotpVerifyArgs, 'code'>
  >;
  userLockCurrency?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationUserLockCurrencyArgs, 'currencyId' | 'status'>
  >;
};

export type NewMollieClientResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NewMollieClientResponse'] = ResolversParentTypes['NewMollieClientResponse']
> = {
  checkoutURL?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentSourceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PaymentSource'] = ResolversParentTypes['PaymentSource']
> = {
  brand?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expMonth?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expYear?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastFour?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortfolioRowResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PortfolioRowResponse'] = ResolversParentTypes['PortfolioRowResponse']
> = {
  amountCoins?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  change24?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  change24Amount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  change24Percentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['Currency'], ParentType, ContextType>;
  effectiveDate?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  percentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  usdChange24Amount?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  usdChange24Percentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  usdCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortfolioTotalsResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PortfolioTotalsResponse'] = ResolversParentTypes['PortfolioTotalsResponse']
> = {
  eurCostChange24Percentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  eurCostChange24Total?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  eurCostTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nextRebalancingOn?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  usdCostChange24Percentage?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  usdCostChange24total?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  usdCostTotal?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
  adminGetInvoices?: Resolver<
    ResolversTypes['InvoiceListResponse'],
    ParentType,
    ContextType,
    Partial<QueryAdminGetInvoicesArgs>
  >;
  adminGetPerformance?: Resolver<
    ResolversTypes['AdminPerformanceResponse'],
    ParentType,
    ContextType
  >;
  adminGetUser?: Resolver<
    Maybe<ResolversTypes['UserResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryAdminGetUserArgs, 'userId'>
  >;
  adminGetUsers?: Resolver<
    ResolversTypes['AdminUsersResponse'],
    ParentType,
    ContextType,
    Partial<QueryAdminGetUsersArgs>
  >;
  availableAuthentications?: Resolver<
    Array<ResolversTypes['AvailableAuthentication']>,
    ParentType,
    ContextType,
    RequireFields<QueryAvailableAuthenticationsArgs, 'next'>
  >;
  getCurrencyGraphData?: Resolver<
    ResolversTypes['GetCurrencyGraphDataResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetCurrencyGraphDataArgs, 'currencyID' | 'exchangeId'>
  >;
  getDemoCurrencyGraphData?: Resolver<
    ResolversTypes['GetCurrencyGraphDataResponse'],
    ParentType,
    ContextType,
    RequireFields<
      QueryGetDemoCurrencyGraphDataArgs,
      'currencyID' | 'exchangeId'
    >
  >;
  getDemoPortfolio?: Resolver<
    Array<ResolversTypes['PortfolioRowResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDemoPortfolioArgs, 'exchangeId'>
  >;
  getDemoPortfolioGraphData?: Resolver<
    Array<ResolversTypes['AggregatedDataRow']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetDemoPortfolioGraphDataArgs, 'exchangeId'>
  >;
  getDemoUser?: Resolver<
    ResolversTypes['UserResponse'],
    ParentType,
    ContextType
  >;
  getExchanges?: Resolver<
    Array<ResolversTypes['ExchangeResponse']>,
    ParentType,
    ContextType
  >;
  getInvoice?: Resolver<
    ResolversTypes['InvoiceResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetInvoiceArgs, 'invoiceID'>
  >;
  getInvoices?: Resolver<
    Array<ResolversTypes['InvoiceResponse']>,
    ParentType,
    ContextType
  >;
  getMenu?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  getMolliePaymentInfo?: Resolver<
    ResolversTypes['MolliePaymentResponse'],
    ParentType,
    ContextType
  >;
  getMollieTransitPaymentLink?: Resolver<
    ResolversTypes['MollieTransitPaymentLinkResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetMollieTransitPaymentLinkArgs, 'token'>
  >;
  getPortfolio?: Resolver<
    Array<ResolversTypes['PortfolioRowResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPortfolioArgs, 'exchangeId'>
  >;
  getPortfolioGraphData?: Resolver<
    Array<ResolversTypes['AggregatedDataRow']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetPortfolioGraphDataArgs, 'exchangeId'>
  >;
  getPortfolioTotals?: Resolver<
    ResolversTypes['PortfolioTotalsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetPortfolioTotalsArgs, 'exchangeId'>
  >;
  getUser?: Resolver<ResolversTypes['UserResponse'], ParentType, ContextType>;
  getUserExchanges?: Resolver<
    Array<ResolversTypes['UserExchangeResponse']>,
    ParentType,
    ContextType
  >;
  portfolioCurrency?: Resolver<
    Maybe<ResolversTypes['PortfolioCurrency']>,
    ParentType,
    ContextType
  >;
  selectedExchange?: Resolver<
    Maybe<ResolversTypes['ExchangeCode']>,
    ParentType,
    ContextType
  >;
};

export type RemoveUserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RemoveUserResponse'] = ResolversParentTypes['RemoveUserResponse']
> = {
  invoiceToPayId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResetPasswordResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ResetPasswordResponse'] = ResolversParentTypes['ResetPasswordResponse']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendResetPasswordEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SendResetPasswordEmailResponse'] = ResolversParentTypes['SendResetPasswordEmailResponse']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignInResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignInResponse'] = ResolversParentTypes['SignInResponse']
> = {
  authIdentityID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totpEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignOutResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignOutResponse'] = ResolversParentTypes['SignOutResponse']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignUpResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SignUpResponse'] = ResolversParentTypes['SignUpResponse']
> = {
  authIdentityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StripeSetPaymentMethodResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StripeSetPaymentMethodResponse'] = ResolversParentTypes['StripeSetPaymentMethodResponse']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Time'], any> {
  name: 'Time';
}

export type TotpDisableResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotpDisableResponse'] = ResolversParentTypes['TotpDisableResponse']
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotpValidateResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotpValidateResponse'] = ResolversParentTypes['TotpValidateResponse']
> = {
  backupCodes?: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TotpVerifyResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TotpVerifyResponse'] = ResolversParentTypes['TotpVerifyResponse']
> = {
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserExchangeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserExchangeResponse'] = ResolversParentTypes['UserExchangeResponse']
> = {
  aumBelowMinLastShowTime?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  aumBelowMinimum?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  exchangeCode?: Resolver<
    ResolversTypes['ExchangeCode'],
    ParentType,
    ContextType
  >;
  exchangeID?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  fiatCurrencies?: Resolver<
    Array<ResolversTypes['Currency']>,
    ParentType,
    ContextType
  >;
  keysCreatedAt?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  keysStatus?: Resolver<ResolversTypes['KeysStatus'], ParentType, ContextType>;
  moneyUnderManagement?: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  nextRebalanceDate?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  onlyFiatInPortfolio?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  subscriptionInitiatedDate?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  subscriptionStartDate?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  targetForSaleCurrencies?: Resolver<
    Array<ResolversTypes['Currency']>,
    ParentType,
    ContextType
  >;
  wizardStatus?: Resolver<
    ResolversTypes['WizardStatusType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserResponse'] = ResolversParentTypes['UserResponse']
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  amountToInvest?: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  authIdentities?: Resolver<
    Array<ResolversTypes['AuthIdentityResponse']>,
    ParentType,
    ContextType,
    Partial<UserResponseAuthIdentitiesArgs>
  >;
  authProvider?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  controlledByUser?: Resolver<
    Maybe<ResolversTypes['UserResponse']>,
    ParentType,
    ContextType
  >;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  currenciesLocked?: Resolver<
    Array<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  dateOfBirth?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  dateOfSignup?: Resolver<ResolversTypes['Time'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['Time']>, ParentType, ContextType>;
  exchanges?: Resolver<
    Array<ResolversTypes['UserExchangeResponse']>,
    ParentType,
    ContextType
  >;
  firstName?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hadCreditCard?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasActiveSubscription?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  hasUnpaidInvoices?: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  houseNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mollieStatus?: Resolver<
    ResolversTypes['MollieStatus'],
    ParentType,
    ContextType
  >;
  moneyPaid?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  nextRebalancingOn?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  paymentSources?: Resolver<
    Array<ResolversTypes['PaymentSource']>,
    ParentType,
    ContextType
  >;
  pendingDiscount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  personalReferalCode?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  profileValid?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  referralCode?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  stripeStatus?: Resolver<
    ResolversTypes['StripeStatus'],
    ParentType,
    ContextType
  >;
  totpEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  trialEndDate?: Resolver<
    Maybe<ResolversTypes['Time']>,
    ParentType,
    ContextType
  >;
  vatNumber?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AddKeysResponse?: AddKeysResponseResolvers<ContextType>;
  AdminPerformanceResponse?: AdminPerformanceResponseResolvers<ContextType>;
  AdminUserResponse?: AdminUserResponseResolvers<ContextType>;
  AdminUsersResponse?: AdminUsersResponseResolvers<ContextType>;
  AggregatedDataRow?: AggregatedDataRowResolvers<ContextType>;
  AuthIdentityResponse?: AuthIdentityResponseResolvers<ContextType>;
  AvailableAuthentication?: AvailableAuthenticationResolvers<ContextType>;
  ChangePasswordResponse?: ChangePasswordResponseResolvers<ContextType>;
  Currency?: CurrencyResolvers<ContextType>;
  DirectInvoicePaymentResponse?: DirectInvoicePaymentResponseResolvers<ContextType>;
  ExchangeResponse?: ExchangeResponseResolvers<ContextType>;
  GetCurrencyGraphDataResponse?: GetCurrencyGraphDataResponseResolvers<ContextType>;
  GoogleSignInResponse?: GoogleSignInResponseResolvers<ContextType>;
  GoogleSignUpResponse?: GoogleSignUpResponseResolvers<ContextType>;
  InvoiceListResponse?: InvoiceListResponseResolvers<ContextType>;
  InvoiceResponse?: InvoiceResponseResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  MolliePaymentResponse?: MolliePaymentResponseResolvers<ContextType>;
  MollieTransitPaymentLinkResponse?: MollieTransitPaymentLinkResponseResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NewMollieClientResponse?: NewMollieClientResponseResolvers<ContextType>;
  PaymentSource?: PaymentSourceResolvers<ContextType>;
  PortfolioRowResponse?: PortfolioRowResponseResolvers<ContextType>;
  PortfolioTotalsResponse?: PortfolioTotalsResponseResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RemoveUserResponse?: RemoveUserResponseResolvers<ContextType>;
  ResetPasswordResponse?: ResetPasswordResponseResolvers<ContextType>;
  SendResetPasswordEmailResponse?: SendResetPasswordEmailResponseResolvers<ContextType>;
  SignInResponse?: SignInResponseResolvers<ContextType>;
  SignOutResponse?: SignOutResponseResolvers<ContextType>;
  SignUpResponse?: SignUpResponseResolvers<ContextType>;
  StripeSetPaymentMethodResponse?: StripeSetPaymentMethodResponseResolvers<ContextType>;
  Time?: GraphQLScalarType;
  TotpDisableResponse?: TotpDisableResponseResolvers<ContextType>;
  TotpValidateResponse?: TotpValidateResponseResolvers<ContextType>;
  TotpVerifyResponse?: TotpVerifyResponseResolvers<ContextType>;
  UserExchangeResponse?: UserExchangeResponseResolvers<ContextType>;
  UserResponse?: UserResponseResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  client?: ClientDirectiveResolver<any, any, ContextType>;
  export?: ExportDirectiveResolver<any, any, ContextType>;
};
