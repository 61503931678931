/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  HTMLAttributes,
} from 'react';
import styled from 'styled-components';
import { get, isArray, isEmpty, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  ChangeCell,
  NameCell,
  OrderCell,
  PercentageCell,
  PriceCell,
  TableCell,
  TokenAmountCell,
  ValueCell,
} from 'styled/portfolio';
import {
  Grid,
  Table as DefTable,
  TableBody as DefTableBody,
  TableFooter,
  TableHead as DefTableHead,
  TableRow as DefTableRow,
} from '@mui/material';
import { animated } from '@react-spring/web';
import { colors } from '../../utils/colors';
import {
  highstockCreditsLink,
  minPortfolioItemCostToRender,
} from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { semiBoldText } from '../../utils/fonts';
import { getPercentages } from '../../utils/percentages';
import { getCryptoIconPath } from '../../utils/portfolio-helpers';
import { useHorizontalDemoScroll, useIsMobile } from '../../hooks';
import { DashboardDataQuery, PortfolioCurrency } from '../../apollo';
import { getDownMedia, getUpMedia } from '../../styles';
import { usePortfolioCurrency } from '../../providers';
import { PortfolioCurrencySwitcher } from '../PortfolioCurrencySwitcher';
import { Text } from '../texts';
import { PortfolioTableRow, PortfolioTableRowData } from './PortfolioTableRow';

type PortfolioProps = {
  data: {
    portfolio: DashboardDataQuery['getPortfolio'];
    demoPortfolio: DashboardDataQuery['getDemoPortfolio'];
    lockedCurrencies: DashboardDataQuery['getUser']['currenciesLocked'];
  };
} & HTMLAttributes<HTMLUListElement>;

const Portfolio = ({ data, ...props }: PortfolioProps) => {
  const { t } = useTranslation();
  const { currency } = usePortfolioCurrency();
  const mobile = useIsMobile();
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const scroll = useHorizontalDemoScroll(tableWrapperRef);
  const chartRef = useRef<{
    chart: Highcharts.Chart;
    container: React.RefObject<HTMLDivElement>;
  }>(null);
  const [tableData, setTableData] = useState<TableData>([]);
  const [hoveredPiePieceName, setHoveredPiePieceName] = useState('');

  // Redraw pie on window resize event
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const redrawPie = useCallback(() => {
    requestAnimationFrame(() => {
      chartRef.current?.chart.reflow();
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', redrawPie);

    return () => {
      window.removeEventListener('resize', redrawPie);
    };
  }, [redrawPie]);

  // const updateTableData = useCallback<Highcharts.ChartRedrawCallbackFunction>(
  const updateTableData = useCallback(async function (
    event: Highcharts.ChartAddSeriesEventObject
  ) {
    // @ts-ignore
    const data = event.options?.data;

    if (isEmpty(data)) return;

    let tableData = data.map(
      // @ts-ignore
      ({ color, tableData }) => ({
        color,
        ...tableData,
      })
    );

    tableData = await Promise.all(
      // @ts-ignore
      tableData.map(async ({ symbol, ...rest }) => {
        const icon = await getCryptoIconPath(symbol);

        return {
          ...rest,
          symbol,
          icon,
        };
      })
    );

    const fiatCurrencies = tableData.filter(
      ({ isFiat }: TableData[0]) => isFiat
    );

    if (!isEmpty(fiatCurrencies)) {
      fiatCurrencies.forEach((currency: any) => {
        tableData.unshift(tableData.splice(tableData.indexOf(currency), 1)[0]);
      });
    }

    setTableData(tableData);
    // Required to recalculate pie chart size
    window.dispatchEvent(new Event('resize'));
  },
  []);

  const onPieMouseOver = useCallback<Highcharts.PointMouseOverCallbackFunction>(
    (event) => {
      if (mobile) return;
      const name = get(event, 'target.name', '');
      setHoveredPiePieceName(name);
    },
    [mobile]
  );

  const onPieMouseOut =
    useCallback<Highcharts.PointMouseOutCallbackFunction>(() => {
      setHoveredPiePieceName('');
    }, []);

  const pieOptions = useMemo<Highcharts.Options>(
    () =>
      getPieOptions({
        currency,
        addSeries: updateTableData,
        // redraw: updateTableData,
        mouseOver: onPieMouseOver,
        mouseOut: onPieMouseOut,
      }),
    [currency, updateTableData, onPieMouseOver, onPieMouseOut]
  );

  const onTableRowMouseOver = useCallback((name: string) => {
    const chart = chartRef.current?.chart;

    if (!chart) return;

    const points = get(chart, 'series[0].points', []) as Highcharts.Point[];

    const point = points.find((point) => point.name === name);

    if (!(!isEmpty(points) && point)) return;

    points.forEach((point) => point.setState('inactive'));

    point.setState('hover');

    chart.tooltip.refresh(point);
  }, []);

  const onTableRowMouseOut = useCallback(() => {
    const chart = chartRef.current?.chart;

    if (!chart) return;

    const points = chart.series[0].points;

    points.forEach((point) => {
      point.setState('');
    });

    chart.tooltip.hide();
  }, []);

  const portfolioTotalCost = useMemo(() => {
    if (!isArray(data.portfolio)) return 0;

    return data.portfolio
      .map(({ cost, usdCost }) =>
        currency === PortfolioCurrency.Eur ? cost : usdCost
      )
      .reduce((res, cost) => res + cost, 0);
  }, [data, currency]);

  const descTextVisible = useMemo(() => {
    if (!(data.demoPortfolio && data.portfolio)) return false;

    const demoCurrencies = data.demoPortfolio.map(
      ({ currency: { symbol } }) => symbol
    );

    const portfolioCurrencies = data.portfolio.map(
      ({ currency: { symbol } }) => symbol
    );

    if (demoCurrencies.length >= portfolioCurrencies.length) {
      return false;
    }

    const missedCurrencies = demoCurrencies.filter(
      (demoCurrency) => !portfolioCurrencies.includes(demoCurrency)
    );

    return missedCurrencies.length > 0;
  }, [data]);

  // Update pie data
  useEffect(() => {
    if (!isArray(data.portfolio)) return;

    const portfolioGraphData = data.portfolio
      .filter(({ cost }) => minPortfolioItemCostToRender <= cost)
      .map(
        ({
          cost,
          usdCost,
          currency: { id, title, piechartColor, isFiat, ...othersCurrency },
          ...others
        }) => ({
          y: currency === PortfolioCurrency.Eur ? cost : usdCost,
          name: title,
          color: piechartColor,
          tableData: {
            id,
            title,
            cost,
            usdCost,
            isFiat,
            locked:
              isFiat &&
              data.lockedCurrencies &&
              data.lockedCurrencies.includes(toNumber(id)),
            ...othersCurrency,
            ...others,
          } as Omit<PortfolioTableRowData, 'color' | 'icon'>,
        })
      );

    if (!chartRef.current) return;

    chartRef.current.chart.addSeries({
      name: 'Portfolio',
      type: 'pie',
      data: portfolioGraphData,
      innerSize: '52%',
      showInLegend: true,
      dataLabels: {
        enabled: false,
      },
    });
  }, [data, currency]);

  const [fiats, cryptos] = useMemo(
    () =>
      tableData.reduce<[TableData, TableData]>(
        (acc, data) =>
          data.isFiat
            ? [[...acc[0], data], acc[1]]
            : [acc[0], [...acc[1], data]],
        [[], []]
      ),
    [tableData]
  );

  const smallTokensData = useMemo(() => {
    const rawData = data.portfolio
      .filter(({ cost }) => cost < minPortfolioItemCostToRender)
      .reduce(
        (acc, { cost, usdCost, percentage }) => ({
          amount:
            acc.amount + (currency === PortfolioCurrency.Eur ? cost : usdCost),
          percent: acc.percent + percentage,
        }),
        { amount: 0, percent: 0 }
      );

    return rawData.amount >= 0.01
      ? {
          amount: getPrice(
            rawData.amount,
            currency === PortfolioCurrency.Usd
              ? {
                  currency: 'USD',
                }
              : undefined
          ),
          percent: getPercentages(rawData.percent),
        }
      : null;
  }, [currency, data.portfolio]);

  const renderTableRow = useCallback(
    (data: PortfolioTableRowData, index: number) => (
      <PortfolioTableRow
        key={index}
        data={data}
        index={index}
        hoveredPiePieceName={hoveredPiePieceName}
        onPointerOver={() => onTableRowMouseOver(data.title)}
        onPointerOut={() => onTableRowMouseOut()}
      />
    ),
    [hoveredPiePieceName, onTableRowMouseOut, onTableRowMouseOver]
  );

  return (
    // @ts-ignore
    <Wrapper container alignItems={'flex-start'} {...props}>
      <>
        <PieBlock
          container
          item
          flexDirection={'column'}
          xs={12}
          md={12}
          lg={4}
        >
          <CurrencySwitcher />
          <TotalBlock>
            <TotalBlockDesc component={'p'} variant={'h1'}>
              {t('DASHBOARD_PAGE__portfolioTotal')}
            </TotalBlockDesc>
            <TotalBlockValue component={'p'} variant={'h1'}>
              {getPrice(
                portfolioTotalCost,
                currency !== PortfolioCurrency.Eur
                  ? {
                      currency: 'USD',
                    }
                  : undefined
              )}
            </TotalBlockValue>
          </TotalBlock>
          <PieWrapper>
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              options={pieOptions}
              updateArgs={[true, true, true]}
            />
          </PieWrapper>
          {descTextVisible && (
            <PieBlockDesc>
              <PieBlockDescTitle component={'h3'} variant={'h3'}>
                {t('DASHBOARD_PAGE__portfolioDescTitle')}
              </PieBlockDescTitle>
              <PieBlockDescText>
                {t('DASHBOARD_PAGE__portfolioDescText')}
              </PieBlockDescText>
            </PieBlockDesc>
          )}
        </PieBlock>
        <TableBlock
          ref={tableWrapperRef}
          item
          xs={12}
          md={12}
          lg={8}
          scrollLeft={scroll}
        >
          <Table>
            <TableHead>
              <DefTableRow>
                <OrderCell />
                <NameCell>{t('DASHBOARD_PAGE__portfolioNameColumn')}</NameCell>
                <PriceCell>
                  {t('DASHBOARD_PAGE__portfolioPriceColumn')}
                </PriceCell>
                <ChangeCell>
                  {t('DASHBOARD_PAGE__portfolioChangeColumn')}
                </ChangeCell>
                <TokenAmountCell>
                  {t('DASHBOARD_PAGE__portfolioCoinsColumn')}
                </TokenAmountCell>
                <ValueCell>
                  {t('DASHBOARD_PAGE__portfolioValueColumn')}
                </ValueCell>
                <PercentageCell>
                  {t('DASHBOARD_PAGE__portfolioPercentageColumn')}
                </PercentageCell>
              </DefTableRow>
            </TableHead>
            <TableBody>
              {fiats.map(renderTableRow)}
              {cryptos.map(renderTableRow)}
              {!!smallTokensData && (
                <DefTableRow>
                  <OrderCell />
                  <NameCell colSpan={4}>
                    {t('DASHBOARD_PAGE__portfolioSmallTokensSumTitle')}
                  </NameCell>
                  <ValueCell>{smallTokensData.amount}</ValueCell>
                  <PercentageCell>{smallTokensData.percent}</PercentageCell>
                </DefTableRow>
              )}
            </TableBody>
            <TableFooter>
              <DefTableRow>
                <OrderCell />
                <NameCell>{t('DASHBOARD_PAGE__portfolioTotal')}</NameCell>
                <TableCell colSpan={3} />
                <ValueCell>
                  {getPrice(
                    portfolioTotalCost,
                    currency !== PortfolioCurrency.Eur
                      ? {
                          currency: 'USD',
                        }
                      : undefined
                  )}
                </ValueCell>
                <PercentageCell>
                  {getPercentages(
                    tableData.reduce(
                      (res, { percentage }) => res + percentage,
                      0
                    ),
                    {
                      round: true,
                    }
                  )}
                </PercentageCell>
              </DefTableRow>
            </TableFooter>
          </Table>
        </TableBlock>
      </>
    </Wrapper>
  );
};

type TableData = Array<PortfolioTableRowData>;

const Wrapper = styled(Grid)`
  padding: 24px 36px 25px;
  background-color: ${colors.white};
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const TotalBlock = styled.div`
  margin-top: 6px;
`;

const TotalBlockDesc = styled(Text)`
  margin-bottom: 6px;
  line-height: 1;
  ${semiBoldText}
`;

const TotalBlockValue = styled(Text)`
  ${semiBoldText};
  font-size: ${({ theme: { typography } }) => typography.pxToRem(32)};
  line-height: 1;
  color: ${colors.blue};
`;

const CurrencySwitcher = styled(PortfolioCurrencySwitcher)`
  position: absolute;
  top: 32px;
  right: 35px;

  ${getUpMedia('md')} {
    display: none;
  }

  ${getDownMedia('md')} {
    right: 19px;
  }
`;

const PieBlock = styled(Grid)`
  padding-right: 5%;

  ${getUpMedia('md')} {
    align-self: stretch;
  }

  ${getDownMedia('lg')} {
    padding-right: 0;
    margin-bottom: 40px;
  }

  ${getDownMedia('sm')} {
    margin-bottom: 20px;
  }
`;

const PieWrapper = styled.div`
  align-self: center;
  max-width: 80%;
  margin: 80px -10px;

  ${getDownMedia('sm')} {
    max-width: 90%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const PieBlockDesc = styled.div`
  ${getUpMedia('lg')} {
    max-width: 385px;
  }
`;

const PieBlockDescTitle = styled(Text)`
  margin-bottom: 10px;
`;

const PieBlockDescText = styled(Text)`
  font-size: ${({
    theme: {
      typography: { pxToRem },
    },
  }) => pxToRem(15)};
`;

const TableBlock = styled(animated(Grid))`
  display: flex;
  padding-left: 1px;
  ${getDownMedia(1350)} {
    overflow-x: auto;
  }

  ${getDownMedia('sm')} {
    overflow-x: scroll;
  }
`;

const Table = styled(DefTable)`
  min-width: 100%;
  width: auto;
  flex: 1 1 auto;
  table-layout: fixed;

  @media (min-width: 1400px) {
    margin-right: 10px;
  }

  @media (min-width: 1500px) {
    margin-right: 20px;
  }

  @media (min-width: 1600px) {
    margin-right: 35px;
  }

  @media (min-width: 1700px) {
    margin-right: 45px;
  }

  @media (min-width: 1800px) {
    margin-right: 50px;
  }

  .MuiTableCell-root {
    border-bottom: none;
  }

  .MuiTableCell-footer {
    ${semiBoldText};
  }
`;

const TableHead = styled(DefTableHead)`
  .MuiTableCell-head {
    padding-top: 14px;
    padding-bottom: 14px;
    border-bottom: none;
    ${semiBoldText};
  }
`;

const TableBody = styled(DefTableBody)``;

const getPieOptions = ({
  addSeries,
  currency,
  mouseOver,
  mouseOut,
}: {
  addSeries: Highcharts.ChartAddSeriesCallbackFunction;
  mouseOver: Highcharts.PointMouseOverCallbackFunction;
  mouseOut: Highcharts.PointMouseOutCallbackFunction;
  currency: PortfolioCurrency;
}): Highcharts.Options => ({
  chart: {
    height: '100%',
    margin: 0,
    backgroundColor: 'transparent',
    events: {
      addSeries,
      load: function () {
        this.credits.element.onclick = function () {
          window.open(highstockCreditsLink, '_blank', 'noreferrer');
        };
      },
    },
  },
  title: {
    text: '',
    style: {
      display: 'none',
    },
  },
  subtitle: {
    text: '',
    style: {
      display: 'none',
    },
  },
  plotOptions: {
    pie: {
      shadow: false,
      states: {
        hover: {
          brightness: 0,
        },
      },
      point: {
        events: {
          mouseOver,
          mouseOut,
        },
      },
    },
  },
  tooltip: {
    formatter: function () {
      // @ts-ignore
      return `<b>${this.point.name}</b>: ${getPrice(
        this.y,
        currency !== PortfolioCurrency.Eur
          ? {
              currency: 'USD',
            }
          : undefined
      )}; ${getPercentages(
        // @ts-ignore
        this.point?.tableData.percentage
      )}`;
    },
  },
  legend: {
    enabled: false,
  },
});

export { Portfolio };
