/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { ExchangeCode } from '../apollo';
import bitvavoLogoIcon from '../assets/images/bitvavo-logo-icon.svg';
import krakenLogoIcon from '../assets/images/kraken-logo-icon.svg';

export const developmentMode = process.env.NODE_ENV !== 'production';

export const withSentry =
  !developmentMode && !!process.env.REACT_APP_SENTRY_RELEASE;

export const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

// PHONE = '+31 (0)20 239 6159',

export enum SiteData {
  EMAIL = 'contact@mercuryredstone.com',
  ROOT_LINK = 'https://mercuryredstone.com',
  // KRAKEN_CREATE_ACCOUNT = 'https://r.kraken.com/Vyb93O',
  // KRAKEN_ABOUT = 'https://mercuryredstone.com/beveiliging',
  KRAKEN_SIGNUP = 'https://www.kraken.com/sign-up',
  KRAKEN_ADD_MONEY_LINK = 'https://support.kraken.com/hc/en-us/articles/360049073651-How-do-I-deposit-my-local-currency-to-Kraken',
  BITVAVO_SIGNUP = 'https://bitvavo.com/?a=481BD3AD5C',
  BITVAVO_TERMS_LINK = 'https://mercuryredstone.com/api-koppeling-bitvavo/',
  BITVAVO_ADD_MONEY_LINK = 'https://support.bitvavo.com/hc/nl/articles/4405187872657-Euro-stortmogelijkheden-bij-Bitvavo',
  TERMS_LINK = 'https://mercuryredstone.com/algemene-voorwaarden/',
  DISCLAIMER_LINK = 'https://mercuryredstone.com/risicos/',
  PRIVACY_LINK = 'https://mercuryredstone.com/cookie-en-privacyverklaring/',
  NOT_US_PERSON_LINK = 'https://www.taxesforexpats.com/expat-tax-advice/definition-us-person-for-irs.html',
  CONTACTS_LINK = 'https://mercuryredstone.com/contact/',
  FAQ_LINK = 'https://mercuryredstone.com/faq/',
  // FAQ_KRAKEN_LINK = 'https://mercuryredstone.com/faq/#kraken',
  KRAKEN_API_LINK = 'https://mercuryredstone.com/api-koppeling-kraken',
  BITVAVO_API_LINK = 'https://mercuryredstone.com/api-koppeling-bitvavo',
  SECURITY_LINK = 'https://mercuryredstone.com/handelsplatformen/#beveiligingstips',
  LINKED_IN_LINK = 'https://linkedin.com/company/mercuryredstone',
  FACEBOOK_LINK = 'https://www.facebook.com/Mercury-Redstone-2287358764680953',
  TWITTER_LINK = 'https://twitter.com/mercuryredstone',
  YOUTUBE_LINK = 'https://youtube.com/@mercuryredstone',
  HOW_IT_WORKS_LINK = 'https://mercuryredstone.com/hoe-het-werkt',
  GOOGLE_AUTHENTICATOR = 'https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=nl',
  CHATBOX_LINK = '//code.tidio.co/erzidcunoctdnrvxqsptbrlqkugoprmp.js',
}

export const minKrakenAccountAmount = process.env
  .REACT_APP_MIN_KRAKEN_ACCOUNT_AMOUNT
  ? Number(process.env.REACT_APP_MIN_KRAKEN_ACCOUNT_AMOUNT)
  : 2500;

export const minutesAmountToConsiderInitialRebalanceFailed = 30;

const defaultTablesData = {
  initialRows: 100,
  rowsPerPageOptions: [100, 200, 500, { value: -1, label: 'All' }],
};

export const tablesData = {
  invoices: {
    initialRows: 50,
  },
  adminInvoices: defaultTablesData,
  adminUsers: {
    initialRows: 50,
    rowsPerPageOptions: [50, 100, 500, 700, 1000, { value: -1, label: 'All' }],
  },
};

export const minPortfolioItemCostToRender = 1;

export const externalLinkProps = {
  rel: 'noreferrer',
  target: '_blank',
};

export const defaultLanguageLocale = 'nl';

export const highstockCreditsLink = 'https://www.everviz.com/';

export const generalDataPollInterval = 30 * 1000; // each 30 seconds
export const portfolioDataPollInterval = 30 * 1000;

export const investOptions = [
  // Was €1.000 initially, thus value 1 left, since it doesn't matter
  // on server
  { label: '€2.500 - €10.000', value: 1 },
  { label: '€10.000 - €50.000', value: 10 },
  { label: '€50.000 - €250.000', value: 50 },
  { label: '€250.000 +', value: 250 },
];

export enum serverResponseErrorsCodes {
  NOT_ENOUGH_MONEY = 'not-enough-money',
  NOT_ENOUGH_MONEY_TO_PURCHASE = 'not-enough-money-for-purchase',
  NOT_ENOUGH_MONEY_TO_SELL = 'not-enough-money-for-selling',
  RESET_PASSWORD_TIMEOUT = 'reset-password-timeout',
  WRONG_PASSWORD = 'wrong-password',
  CAPTCHA_V3_ERROR = 'captcha-error',
  CAPTCHA_V2_ERROR = 'captcha-v2-error',
  GOOGLE_EMAIL = 'google-email',
  GOOGLE_USER_NOT_FOUND = 'google-user-not-found',
  REFERAL_NOT_FOUND = 'referal-not-found',
}

export enum serverResponseErrorMessages {
  FAILED_TO_FETCH = 'Failed to fetch',
}

export enum LocalStorage {
  drawerDesktopShrinked = 'drawer_desktop_shrinked',
  adminDrawerDesktopShrinked = 'admin_drawer_desktop_shrinked',
  adminUsersTableFilters = 'admin_users_table_filters',
  adminUsersTableSortings = 'admin_users_table_sortings',
  last2FAReminderPopupShownTime = 'last_2fa-reminder_popup_show_time',
}

export enum AuthProviders {
  EMAIL = 'email',
  GOOGLE = 'google',
}

/*export const isInDev = process.env.NODE_ENV === 'development';*/

export const exchangesCodesImages: {
  [key in ExchangeCode]: string;
} = {
  [ExchangeCode.Bitvavo]: bitvavoLogoIcon,
  [ExchangeCode.Kraken]: krakenLogoIcon,
};
