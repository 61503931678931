/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useAlertModal, usePortfolioDeposit } from 'hooks';
import { useExchange } from '../providers';
import { bottomButtonStyles } from '../styles/dashboard-page';
import { Button as DefButton } from './buttons';
import { BottomBlockTitle, BottomBlockText } from './dashboard-page';

const DepositDesc = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();
  const { exchange } = useExchange();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const [deposit] = usePortfolioDeposit({
    onCompleted: () => {
      dispatchAlertModal(['setModalContent', 'pendingExecRebalance']);
    },
  });

  return (
    <Wrapper {...props}>
      <BottomBlockTitle>{t('DEPOSIT_DESC__title')}</BottomBlockTitle>
      <Paragraph>{t('DEPOSIT_DESC__text1')}</Paragraph>
      <Paragraph>{t('DEPOSIT_DESC__text2')}</Paragraph>
      <Button
        onClick={() => {
          exchange?.exchangeID &&
            deposit({
              variables: {
                exchangeId: toNumber(exchange.exchangeID),
              },
            });
        }}
      >
        {t('DEPOSIT_DESC__buttonText')}
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Paragraph = styled(BottomBlockText)`
  &:last-of-type {
    margin-bottom: 20px;
  }
`;

const Button = styled(DefButton)`
  ${bottomButtonStyles};
` as typeof DefButton;

export { DepositDesc };
