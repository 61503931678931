/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString, isNil } from 'lodash-es';
import { BigNumber } from 'bignumber.js';
import { Maybe } from '../apollo';
import { countDecimals } from './number-helpers';

export const getPrice = (
  price: Maybe<number | string>,
  options: Intl.NumberFormatOptions = {},
  rounding = true
) => {
  if (isNil(price)) return '';

  const initPrice = isString(price) ? parseFloat(price) : price;

  if (isNaN(initPrice)) return '';

  const absPrice = Math.abs(initPrice);
  let minimumFractionDigits;

  if (absPrice < 0.01 && !rounding) {
    const num = new BigNumber(absPrice);
    const rounded = num.precision(2).toFixed();
    minimumFractionDigits = countDecimals(rounded);
  }

  const priceString = absPrice
    .toLocaleString('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits,
      ...options,
    })
    .replace(/\s+/g, '')
    .replace(/^US/g, '');

  return 0 <= initPrice ? priceString : `- ${priceString}`;
};

export const getPriceDifference = (
  price: number,
  options?: Parameters<typeof getPrice>[1]
) => {
  const positive = price === 0 || price > 0;
  const priceString = getPrice(Math.abs(price), options);

  return price === 0 ? priceString : `${positive ? '+' : '-'} ${priceString}`;
};
