/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material';
import {
  AppBar as DefAppBar,
  Toolbar as DefToolbar,
  IconButton,
  Menu as DefMenu,
  MenuItem as DefMenuItem,
} from '@mui/material';
import { AppBarProps as DefAppBarProps } from '@mui/material/AppBar';
import { useServerErrorVar } from '../utils/apollo-vars';
import { semiBoldText } from '../utils/fonts';
import { useIsTablet, useIsDashboardPage } from '../hooks';
import { useUserIdQuery, useUserAdminStatusQuery } from 'apollo';
import { getColor } from '../styles';
import { useDrawerContext, useAppTitle, useExchange } from '../providers';
import logoImg from '../assets/images/logo-with-white-text.svg';
import { paths } from '../pages';
import { PortfolioCurrencySwitcher as DefPortfolioCurrencySwitcher } from './PortfolioCurrencySwitcher';
import { ExchangesMenu } from './dashboard-page/ExchangesMenu';
import { UserInCircleIcon as DefUserInCircleIcon } from './icons';
import { Text } from './texts';

export type AppBarProps = DefAppBarProps;

const AppBar = (props: AppBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isTablet = useIsTablet();
  const { title } = useAppTitle();
  const dashboardPage = useIsDashboardPage();
  const { exchange, exchangeWizardActive } = useExchange();
  const { mobileOpened, dispatch } = useDrawerContext();
  const { data: userIdData } = useUserIdQuery();
  const { data: userAdminData } = useUserAdminStatusQuery();
  const serverError = useServerErrorVar();
  const userId = userIdData?.getUser.id ?? 0;
  const isAdmin = userAdminData?.getUser.isAdmin ?? false;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    handleClose();
  }, [pathname, handleClose]);

  useEffect(() => {
    if (anchorEl) {
      document.addEventListener('wheel', handleClose);
      document.addEventListener('touchmove', handleClose);
    } else {
      document.removeEventListener('wheel', handleClose);
      document.removeEventListener('touchmove', handleClose);
    }

    return () => {
      document.removeEventListener('wheel', handleClose);
      document.removeEventListener('touchmove', handleClose);
    };
  }, [anchorEl, handleClose]);

  const withExchangesMenu = !serverError && dashboardPage && !!exchange;

  return (
    <DefAppBar color={'inherit'} {...props}>
      <Toolbar>
        {!isTablet ? (
          <>
            {withExchangesMenu ? (
              <>
                <PortfolioCurrencySwitcher hidden={exchangeWizardActive} />
                <ExchangesMenu />
              </>
            ) : (
              <PageTitle component={'h1'}>{title}</PageTitle>
            )}
            <AccountButton
              aria-controls="user-menu"
              aria-haspopup="true"
              onClick={handleClick}
              onMouseOver={handleClick}
            >
              <UserInCircleIcon />
            </AccountButton>
            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              MenuListProps={{ onMouseLeave: handleClose }}
              onClose={handleClose}
            >
              <MenuItemText>ID: {userId}</MenuItemText>
              {[
                {
                  title: 'HEADER__accountMenuItemText',
                  page: isAdmin ? paths.adminAccount : paths.account,
                  disabled: serverError,
                },
                {
                  title: 'HEADER__logoutMenuItemText',
                  page: paths.logout,
                },
              ].map(({ title, page, ...props }) => (
                <MenuItemLink
                  key={title}
                  onClick={() => navigate(page)}
                  {...props}
                >
                  {t(title)}
                </MenuItemLink>
              ))}
            </Menu>
          </>
        ) : (
          <>
            <LogoImg src={logoImg} alt={'Logo'} />
            <MobileMenuButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => dispatch({ type: 'toggleMobile' })}
            >
              {mobileOpened ? (
                <CloseIcon style={{ color: '#fff' }} />
              ) : (
                <MenuIcon style={{ color: '#fff' }} />
              )}
            </MobileMenuButton>
          </>
        )}
      </Toolbar>
    </DefAppBar>
  );
};

const Toolbar = styled(DefToolbar)`
  justify-content: space-between;

  background-color: ${getColor('blue')};

  ${({ theme }) => theme.breakpoints.down('md')} {
    background-color: ${getColor('darkBlue')};
  }
`;

const MobileMenuButton = styled(IconButton)`
  margin-right: -1px;
  position: relative;
  padding: 7px;

  .MuiSvgIcon-root {
    font-size: 32px;
  }
`;

const PageTitle = styled(Text)`
  font-size: 1rem;
  color: #fff;
`;

const PortfolioCurrencySwitcher = styled(DefPortfolioCurrencySwitcher)`
  margin: 0 20px 0 0;
  position: relative;
  bottom: -1px;
`;

const LogoImg = styled.img`
  width: 230px;
  margin-left: 5px;
`;

const UserInCircleIcon = styled(DefUserInCircleIcon)`
  width: 24px;
  height: 24px;
`;

const AccountButton = styled(IconButton)`
  margin-right: -8px;
`;

const Menu = styled(DefMenu)``;

const menuItemTextStyles = css`
  padding: 6px 16px;
  color: ${getColor('blue')};
`;

const MenuItemText = styled(Text)`
  ${menuItemTextStyles};
  ${semiBoldText};
  color: ${getColor('darkBlue')};
`;

const MenuItemLink = styled(DefMenuItem)`
  ${menuItemTextStyles};
  transition: color 0.2s ease-in-out;

  &:hover {
    background-color: transparent;
    color: ${getColor('darkBlue')};
  }
`;

export { AppBar };
