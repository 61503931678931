/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Grid, Link as DefLink, Paper } from '@mui/material';
import DefContainer, { ContainerProps } from '@mui/material/Container';
import { colors } from '../utils/colors';
import { SiteData } from '../utils/consts';
import { ipadCssScrollFix, getColor, getDownMedia } from '../styles';
import logo from '../assets/images/round-white-logo.svg';
import { ScrollToTop } from '../components';
import { AppFooter } from '../components/AppFooter';
import { Text } from '../components/texts';
import { paths } from '../pages';

export type AuthLayoutProps = {
  withTopRightLogo?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  withTopRightLogo = false,
  ...props
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const withGoogleCaptchaTerms = useMemo(
    () => [paths.login, paths.signup].some((p) => p === pathname),
    [pathname]
  );

  return (
    <Wrapper>
      <ScrollToTop />
      <ContentWrapper>
        <HomepageText>
          {t('AUTH_LAYOUT__homepageLink1')}{' '}
          <Link href={SiteData.ROOT_LINK}>
            {t('AUTH_LAYOUT__homepageLink2')}
          </Link>
        </HomepageText>
        <ContentContainer component={'main'}>
          <Content
            // @ts-ignore
            component={Grid}
            square={false}
            container
            item
            direction={'column'}
            xs={12}
            md={7}
            lg={7}
            {...props}
          >
            {withTopRightLogo && <TopRightLogo src={logo} />}
            {children}
          </Content>
        </ContentContainer>
      </ContentWrapper>
      <AppFooter withGoogleCaptchaTerms={withGoogleCaptchaTerms} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.lightBlue};
  ${ipadCssScrollFix};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  flex-grow: 1;

  ${getDownMedia('sm')} {
    margin-top: 0;
  }
`;

const Link = styled(DefLink)``;

const HomepageText = styled(Text)`
  margin-left: 26px;
  position: relative;
  top: 4px;
  font-size: 15px;
  color: ${getColor('shuttleGray')};

  ${getDownMedia('sm')} {
    display: none;
  }

  ${Link} {
    font-weight: 400;
  }
`;

const ContentContainer = styled((props: ContainerProps) => (
  <DefContainer maxWidth={'md'} fixed {...props} />
))`
  flex-grow: 1;
  display: flex;
  padding: 40px 0;

  ${getDownMedia('md')} {
    padding: 20px;
  }

  ${getDownMedia('sm')} {
    padding: 0;
  }
` as typeof DefContainer;

const Content = styled(Paper)`
  margin: auto;
  padding: 44px 42px 44px;
  //border-radius: 3px;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 11px 0 rgba(63, 73, 105, 0.05);

  ${getDownMedia('sm')} {
    padding-top: 30px;
    padding-left: 37px;
    padding-right: 37px;
    margin: 0;
    box-shadow: none;
  }

  ${getDownMedia(376)} {
    padding-left: 30px;
    padding-right: 30px;
  }

  ${getDownMedia(362)} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${getDownMedia(342)} {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${getDownMedia(327)} {
    padding-left: 9px;
    padding-right: 9px;
  }
`;

const TopRightLogo = styled.img`
  width: 54px;
  height: 54px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

export { AuthLayout };
